import React from 'react';
import ApplePayMark from "../../assets/icons/ApplePayMark.svg";
import CheckBox from "../CheckBox/CheckBox";
import CreditCardMark from "../../assets/icons/CreditCardMark.svg";
import ScssVariables from "../../styles/_export.module.scss";
import { FaExclamationCircle } from "react-icons/fa";
import { connect } from "react-redux";
import {
  PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD,
  PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY,
  SET_SUB_PAYMENT_TYPE
} from "../../middleware/constants/payment.constants";
import useApplePay from "../../hooks/useApplePay";

function ReserveNow({ balance, subPaymentOption, setSubPaymentOption, clickStatus }) {
  let isApplePayAllowed = useApplePay();
  return (
    <>
    <div className={`peak-div${clickStatus === 'false' ? "-validation" : ""
                }`}>
      <div className={`ReserveNow${clickStatus === 'false' ? "-validation ReserveNow" : ""
        }`}>
          <div className="header">
            <p className="title">Reserve this flight for $1,000</p>
            <p className="sub-title">
              <FaExclamationCircle size={"25"} color={ScssVariables.blue} /> Wire
          transfer must be completed within 24 hours to secure this flight
          <p class="confirm-msg">
           All transactions executed upon confirmation of aircraft availability.</p>
        </p>
          </div>
          {isApplePayAllowed && (
            <div className="options-select">
              <img src={ApplePayMark} alt="AppleIconMark" className="option-icon" />
              <div className="label">
                <p className="title">Apple Pay</p>
              </div>
              <CheckBox
                uncheck={subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD}
                checked={subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY}
                onClick={(status) => {
                  if (status)
                    setSubPaymentOption(PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY);
                  else setSubPaymentOption("");
                }}
              />
            </div>
          )}
          <div className="options-select">
            <img
              src={CreditCardMark}
              alt="CreditCardMark"
              className="option-icon"
            />
            <div className="label">
              <p className="title">Credit Card</p>
            </div>
            <CheckBox
              uncheck={subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY}
              checked={subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD}
              onClick={(status) => {
                if (status)
                  setSubPaymentOption(PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD);
                else setSubPaymentOption("");
              }}
            />
          </div>
        </div>
        </div>
      {clickStatus === 'false' && (
        <span className="reserve-validation">
          <p className="reserve-validation-icon">*</p>
          <p className="reserve-validation-msg">Selection is required to confirm booking.</p>
        </span>
      )}
    </>
  );
}

function mapStateToProps({ aircraft, payment }) {
  return {
    balance: aircraft.balance,
    subPaymentOption: payment.subPaymentOption,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSubPaymentOption: option => dispatch({ type: SET_SUB_PAYMENT_TYPE, payload: option })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReserveNow)