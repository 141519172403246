import React from 'react';
import Modal from "../Modal";

import "./InformationModal.scss";

function InformationModal({ status, onChange }) {
    return(
        <div className="InformationModal">
        <Modal close={() => onChange(false)} show={status}>
            <p className="title">For Your Information</p>
            <p className="info">Possible stops due to airport restrictions:</p>
            <ul>
                <li>
                    Fuel stop
                </li>
                <li>
                    Customs
                </li>
                <li>
                    Weather
                </li>
            </ul>
        </Modal>
        </div>
    )
}

export default InformationModal;