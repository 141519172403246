import React from 'react';

function BookingDetailsHeader({id = "BN-000000", status}) {
  return (
    <div className="BookingDetailsHeader">
      <div className="details">
        <h1>{id}</h1>
        {status && <div className={`status ${status}`}>{status}</div>}
      </div>
    </div>
  );
}

export default BookingDetailsHeader;