import React, { useState, useEffect } from 'react';
import { getIsUaAuthentication } from '../../hooks/useAuthentication';
import { useHistory } from 'react-router-dom';
import Modal from "../Modal";
import { Auth } from 'aws-amplify';
import Button from "../Button";
import plane from "../../assets/icons/planeicon.png";
import vector from "../../assets/icons/Vector.png";
import email from "../../assets/icons/email.png";
import chat from "../../assets/icons/chat.png";
import phone from "../../assets/icons/phone.png";
import "./Incentive.scss"

const Popup = ({ status, type, onChange }) => {
    let history = useHistory();
    let [isAuthenticated, setIsAuthenticated] = useState(false)
    let [loggedInModal, setLoggedInModal] = useState(false)
    useEffect(() => {
        getIsUaAuthentication().then(({ status }) => {
            setIsAuthenticated(status)
        });
    }, [])
    return (
        <>
            <div className="OfferModal">
                <Modal close={() => onChange(false)} show={status && type === "offer"}>
                    <img className="icon" src={plane} alt="" />
                    <h2>Receive $3,000 in AB1 Rewards</h2>
                    <h6>Sign up and get $1,500 in First Flight Rewards and another $1,500 in Future Flight Rewards </h6>
                    <Button
                        label={"Sign Me Up"}
                        type={"white"}
                        onClick={() => {
                            onChange(false)
                            if (isAuthenticated) {
                                setLoggedInModal(true)
                            } else {
                                history.push("/signup")
                            }
                        }
                        }
                    />
                    <h6 className="terms">*First Flight’s Trip Costs must be of $20,000+ USD. See AB1 <a href="/terms-of-sale">Terms & Conditions</a></h6>

                </Modal>
            </div>
            <div className="loggedInModal">
                <Modal close={() => { setLoggedInModal(false) }} show={loggedInModal}>
                    <h5 className="heading">You are currently logged in at <a href="/">airbookone.com</a>.</h5>
                    <h5>Not your account?</h5>
                    <h5>Click Log out button below to log out.</h5>
                    <Button
                        label={"Logout"}
                        type={"yellow"}
                        onClick={() => {
                            Auth.signOut({})
                                .then((a) => window.location.href = '/')
                                .catch(console.error);
                        }}
                    />
                    <Button
                        label={"Keep Me Logged In"}
                        type={"yellow"}
                        onClick={() => { setLoggedInModal(false) }}
                    />
                </Modal>
            </div>
            <div className="peekModal">
                <Modal close={() => onChange(false)} show={status && type === "peek"}>
                    <img className="icon" src={vector} alt="" />
                    <div>
                    <h4 className="subtitile-h4 peek-title">
                        <p>We are currently in a </p>
                        <p>PEAK TRAVEL PERIOD </p>
                        <p>with limited aircraft availability.</p>
                        <p>Our Flight Concierge Team is here to help. </p>
                    </h4>
                    </div>
                    <div className="email-vector">
                        <div>
                            <img className="vector-icon" src={email} alt="" />
                        </div>
                        <div className="service-list">
                            <h4 className="subtitile-h4 service-title">
                                Email.
                       </h4>
                            <a className="body-font-p break-body" onClick={() => onChange(false)} href={"mailto:flightcon@airbookone.com"}>flightcon@airbookone.com</a>
                        </div>
                    </div>
                    <div className="chat-vector">
                        <div>
                            <img className="vector-icon" src={chat} alt="" />
                        </div>
                        <div className="service-list">
                            <h4 className="subtitile-h4 service-title">
                                Chat.
                       </h4>
                            <a className="body-font-p  break-body" onClick={() => {
                                window.LiveChatWidget.call("maximize");
                                onChange(false)
                            }} href={'#'}>24/7 Service</a>
                        </div>
                    </div>
                    <div className="phone-vector">
                        <div>
                            <img className="vector-icon phone-icon" src={phone} alt="" />
                        </div>
                        <div className="service-list">
                            <h4 className="subtitile-h4 service-title">
                                Call.
                       </h4>
                            <a className="body-font-p break-body" onClick={() => onChange(false)} href={"tel:3127615080"}>312-761-5080</a>
                        </div>
                    </div>
                    <div className="condition-div">
                        <p className="condition-title">See AB1 <a href="/terms-of-sale" onClick={() => onChange(false)} >Terms & Conditions</a></p>
                    </div>
                </Modal>
            </div>
        </>
    )
}

const NotificationBar = ({ }) => {
    let history = useHistory();
    let [offerModal, setOfferModal] = useState(false)
    let timeAfterPopup = 0; //seconds after popup

    return (
        <>
            <div className="notification" onClick={() => setOfferModal(true)}>
                <p className="text"><u>WELCOME PROMO:</u> Receive $3,000 in AB1 Rewards</p>
            </div>

            {/* <div className="OfferModal">
            <Modal close={() => setOfferModal(false)} show={offerModal}>
                <img className="icon" src={plane} alt=""/>
                <h2>Receive $3,000 in AB1 Rewards</h2>
                <h6>Sign up and get $1,500 in First Flight Rewards and another $1,500 in Future Flight Rewards </h6>
                <Button
                    label={"Sign Me Up"}
                    type={"white"}
                    onClick={() => {
                            setOfferModal(false)
                            history.push("/signup")
                        }
                    }
                />
                <h6 className="terms">*First Flights’s Trip Costs must be of $20,000+ USD. This offer is valid through August 20, 2021. See AB1 <a href="/terms-of-sale">Terms & Conditions</a></h6>

            </Modal>
        </div> */}
            <Popup status={offerModal} type="offer" onChange={setOfferModal} />
        </>
    )
}

export default { NotificationBar, Popup }