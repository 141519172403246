import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import InputBox from '../../components/InputBox';
import Modal from '../../components/Modal';
import LoadingOverlay from 'react-loading-overlay';

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState('');
  const [RMcode, setRMCode] = useState('');
  const [code, setCode] = useState('');
  const [modal, setModal] = useState(false);
  const [password, setPassword] = useState('');
  const [loader, setLoader] = useState(false);
  const [reconfirmModal, setReconfirmModal] = useState(false);

  const handleClose = () => {
    setModal(false);
  };

  const handleRMClose = () => {
    setReconfirmModal(false);
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    setCode('');
    setPassword('');
    if (email !== '') {
      setLoader(true);
      Auth.forgotPassword(email)
        .then(() => {
          setModal(true);
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          if (
            err.code === 'UserNotConfirmedException' ||
            err.code === 'InvalidParameterException'
          ) {
            setLoader(true);
            Auth.resendSignUp(email)
              .then(() => {
                setReconfirmModal(true);
                setLoader(false);
              })
              .catch((err) => {
                console.error(err);
                toast.error(err.message);
                setLoader(false);
              });
          } else {
            toast.error(err.message);
            setLoader(false);
          }
        });
    } else {
      toast.error('Please type email address.');
    }
  };

  const confirmSignUpCode = (e) => {
    e.preventDefault();
    if (RMcode !== '') {
      setLoader(true);
      Auth.confirmSignUp(email, RMcode)
        .then(() => {
          setReconfirmModal(false);
          setLoader(false);
          toast.success('Awesome! Successfully Verified.');
          Auth.forgotPassword(email)
            .then(() => {
              setModal(true);
              setLoader(false);
            })
            .catch((err) => {
              console.error(err);
              toast.error(err.message);
            });
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          toast.error(err.message);
        });
    } else {
      toast.error('Please type the code sent to your email.');
    }
  };

  const forgotPasswordCode = (e) => {
    e.preventDefault();
    if (code !== '' || password !== '') {
      setLoader(true);
      Auth.forgotPasswordSubmit(email, code, password)
        .then(() => {
          setLoader(false);
          setModal(false);
          setEmail('');
          setCode('');
          setPassword('');
          toast.success('Password reset is successful.');
          history.push('/login');
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          setLoader(false);
        });
    } else {
      toast.error('Please fill in required fields');
    }
  };

  return (
    <div className='signup-root'>
      <h1 className='title'>Forgot Password</h1>
      <form className='signup-form'>
        <InputBox
          type='email'
          title='Please enter your email address'
          placeholder='Email Address'
          className='input-grp'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          btnAction={'submit'}
          label={'Continue'}
          type={'yellow'}
          size={'small'}
          onClick={(e) => forgotPassword(e)}
        />
        <Button
          label={'New user? Click here to Register.'}
          type={'white-link'}
          onClick={() => history.push('/create-account')}
          size={'large'}
        />
      </form>
      <Modal show={modal} close={handleClose}>
        <div>
          <p className="modal-heading">A confirmation code has been sent to
           your email {email}</p>
          <form>
            <InputBox
              type='text'
              title='Please type the Code'
              placeholder='Verification Code'
              className='input-grp'
              value={code}
              onChange={(e) => setCode(e.target.value)}
              theme={'light'}
            />
            <InputBox
              type='password'
              title='Please enter new Password'
              placeholder='New Password'
              className='input-grp'
              theme={'light'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              btnAction={'submit'}
              label={'Confirm'}
              type={'yellow'}
              size={'small'}
              onClick={(e) => forgotPasswordCode(e)}
            />
          </form>
        </div>
      </Modal>
      <Modal show={reconfirmModal} close={handleRMClose}>
        <div>
          <p>
            Before resetting your password, your account needs reverification.
            Please type the verification code sent to {email}
          </p>
          <form>
            <InputBox
              theme={'light'}
              type='text'
              title='Please type the Code'
              placeholder='Verification Code'
              className='input-grp'
              value={RMcode}
              onChange={(e) => setRMCode(e.target.value)}
            />
            <Button
              btnAction={'submit'}
              label={'Confirm'}
              type={'yellow'}
              size={'small'}
              onClick={(e) => confirmSignUpCode(e)}
            />
          </form>
        </div>
      </Modal>
      <LoadingOverlay active={loader} spinner text='Loading your content...' />
    </div>
  );
};

export default ForgotPassword;
