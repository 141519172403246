// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/hp-hero-img.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PopularTripBooking {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  padding: 50px 0;\n  background-position-y: center; }\n  .PopularTripBooking .SearchFlightForm {\n    margin-top: 0; }\n", "",{"version":3,"sources":["webpack://src/containers/PopularTripBooking/PopularTripBooking.scss"],"names":[],"mappings":"AAEA;EACI,yDAAqD;EACrD,eAAe;EACf,6BAA6B,EAAA;EAHjC;IAKQ,aAAY,EAAA","sourcesContent":["@import '../../styles/variables.scss';\r\n\r\n.PopularTripBooking{\r\n    background-image: url('../../assets/hp-hero-img.png');\r\n    padding: 50px 0;\r\n    background-position-y: center;\r\n    .SearchFlightForm{\r\n        margin-top:0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
