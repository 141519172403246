import React, { useState, useEffect } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsChevronRight } from "react-icons/bs";
import AddPassengerModal from "../../components/AddPassengerModal";
import LegsModal from "../../components/LegsModal";
import EmergencyContactModal from "../../components/EmergencyContactModal";
import { AiFillMinusCircle } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import AddPassengerExistingModal from "../../components/AddPassengerExistingModal";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import { POST_AUTH } from "../../utils/HTTP.utils";
import { DELETE_AUTH } from "../../utils/HTTP.utils";
import { API_POST_ASSIGN_TRAVELLER, API_POST_ADD_EMERGENCY_CONTACT } from "../../middleware/constants/url.constants";
import { API_POST_CHANGE_TRAVELLER_LEG, API_POST_ADD_LEAD_PASSENGER } from "../../middleware/constants/url.constants";
import { API_DELETE_PASSENGER } from "../../middleware/constants/url.constants"
import { useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';


function PassengersPanel({ data, legs, getRefresh, leadPassenger, bookingId, getPassengers }) {
  let history = useHistory();
  const [emergencyContactModal, setEmergencyContactModal] = useState(false);
  const [legsModal, setLegsModal] = useState({ chosen: false, passengerID: "" });
  const [addPassengerModal, setAddPassengerModal] = useState(false);
  const [addPassengerExistingModal, setAddPassengerExistingModal] = useState(false);
  const [state, setState] = useState(data);
  const [loader, setLoader] = useState(false);

  function initCamelize(str) {
    const strOrgin = [];
    if (str) {
      const strVal = str.toLowerCase(str);
      const strArray = strVal.split(' ');
      for (let i = 0; i < strArray.length; i++) {
        if (strArray[i]) {
          strOrgin.push(strArray[i].trim());
        }
      }
      return strOrgin
        .map(a => a.trim())
        .map(a => a[0].toUpperCase() + a.substring(1))
        .join("  ")
    } else {
      return 'N/A';
    }

  }

  const deletePassanger = async (passengerID) => {
    setLegsModal({ chosen: false, passengerID: "" })
    let { token } = await getIsUaAuthentication();
    let res = await DELETE_AUTH(API_DELETE_PASSENGER + "?bookingId=" + bookingId + "&travellerId=" + passengerID, {}, token);
    setLoader(true)
    if (res === 200) {
      setLoader(false)
      getRefresh(res)
      window.location.reload();

    }

  }

  const assignTraveller = async (id) => {
    let { token } = await getIsUaAuthentication();
    let res = await POST_AUTH(
      API_POST_ASSIGN_TRAVELLER,
      { travellerId: id, bookingId },
      token
    );
    setState(res);
    getPassengers(res);
  };

  const updateTraveller = async (data) => {
    let { token } = await getIsUaAuthentication();
    await POST_AUTH(
      API_POST_CHANGE_TRAVELLER_LEG,
      data,
      token
    );
  };

  const updateLead = async (data) => {
    let { token } = await getIsUaAuthentication();
    await POST_AUTH(
      API_POST_ADD_LEAD_PASSENGER,
      data,
      token
    );
    getRefresh()
  }
  const updateEmergencyContact = async (contact) => {
    let { token } = await getIsUaAuthentication();
    await POST_AUTH(API_POST_ADD_EMERGENCY_CONTACT, { ...contact, "bookingId": bookingId }, token)
  };

  const onNewTravellerSelect = async (selected = []) => {
    await assignTraveller(selected);
  };
  const onNewTravellerAdd = async (id) => {
    let existing = state.map((a, i) => a.id);
    await assignTraveller([...existing, id]);
  };
  const onUpdateTraveller = async (details) => {
    let data = { legIds: details.selectedLegs, travellerId: details.travellerId, bookingId: bookingId }
    let leadData = { travellerId: details.travellerId, bookingId: bookingId, lead: details.leadPassenger }
    await updateTraveller(data);
    await updateLead(leadData)
  };
  const onNewEmergencyContact = async (contact) => {
    await updateEmergencyContact(contact);
  };
  return (
    <div className="PassengersPanel">
      <div className="action-container">
        <div className="action" onClick={() => setAddPassengerModal(true)}>
          <AiFillPlusCircle size={40} />
          <p className="label">Add Passenger</p>
        </div>
        <div
          className="action"
          onClick={() => setAddPassengerExistingModal(true)}
        >
          <FaUserCircle size={40} />
          <p className="label">Add from Contacts</p>
        </div>
        <div className="action" onClick={() => setEmergencyContactModal(true)}>
          <FaPlusCircle size={40} />
          <p className="label">Emergency Contact</p>
        </div>
      </div>
      <div className="traveller-container">
        {state.map((a, i) => {
          if (leadPassenger.id === a.id) {
            return (
              <>

                <div
                  key={i}
                  className={`traveller-tile`}

                >
                  <AiFillMinusCircle size={70} onClick={() => deletePassanger(a.id)} />
                  <p className="order">{i + 1}</p>
                  <div className="passenger-lead">
                    <p className="name">{initCamelize(a.name)}</p>
                    <p className="lead">Lead Passenger</p>
                  </div>
                  <p className="passenger-legs-msg">
                    <BsChevronRight size={30} onClick={() => {
                      setLegsModal({ chosen: true, passengerID: a.id });
                    }} />
                  </p>

                </div>

              </>
            )
          } else {
            return (
              <>
                <div
                  key={i}
                  className={`traveller-tile`}

                >
                  <AiFillMinusCircle size={70} onClick={() => deletePassanger(a.id)} />
                  <p className="order">{i + 1}</p>
                  <p className="name">{initCamelize(a.name)}</p>
                  <p className="passenger-legs-msg">
                    <BsChevronRight size={30} onClick={() => {
                      setLegsModal({ chosen: true, passengerID: a.id });
                    }} />
                  </p>
                </div>

              </>

            )
          }
        })}
      </div>
      {addPassengerModal && (
        <AddPassengerModal
          onNewTraveller={onNewTravellerAdd}
          legs={legs}
          status={addPassengerModal}
          onChange={setAddPassengerModal}
        />
      )}
      {addPassengerExistingModal && (
        <AddPassengerExistingModal
          status={addPassengerExistingModal}
          existing={state}
          onNewTraveller={onNewTravellerSelect}
          onChange={setAddPassengerExistingModal}
        />
      )}
      {legsModal && (
        <LegsModal
          onUpdateTraveller={onUpdateTraveller}
          legs={legs}
          status={legsModal}
          bookingId={bookingId}
          onChange={setLegsModal}
        />
      )}
      {emergencyContactModal && (
        <EmergencyContactModal
          onNewEmergencyContact={onNewEmergencyContact}
          status={emergencyContactModal}
          onChange={setEmergencyContactModal}
          bookingId={bookingId}
        />
      )}
      <LoadingOverlay active={loader} spinner text='Loading your content...' />

    </div>


  );
}

export default PassengersPanel;
