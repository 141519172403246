import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  navAuthLinks,
  navMobileAuthLinks,
  navMobileUnauthLinks,
  navUnauthLinks,
} from "../../../constants/app-constants";
import { connect } from "react-redux";

import "./Header.scss";
import { Auth } from "aws-amplify";
import { getIsUaAuthentication } from "../../../hooks/useAuthentication";
import Logo from "../../../assets/custom-theme/Vector.svg";
import operatorLogo from "../../../assets/logos/operator-logo.png";
import { FaCaretDown } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  QUOTE_MODAL_OPEN,
  QUOTE_SUCCESS,
} from "../../../middleware/constants/widget.constant";
import QuoteModal from "../QuoteModal";
import { getEleAttribute } from "../utility";
const CustomThemeHeader = ({
  theme,
  openQuoteModal,
  closeQuoteModal,
  showSuccessSummary,
  modalOpen,
  flightinfo,
  operatorId,
  quoteSuccess,
}) => {
  const width = window.innerWidth;
  let history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accountDeleteModal, setAccountDeleteModal] = useState(false);
  useEffect(() => {
    getIsUaAuthentication().then(({ status }) => setIsAuthenticated(status));
  }, []);
  const navLinks = isAuthenticated ? navAuthLinks : navUnauthLinks;
  const navMobileLinks = isAuthenticated
    ? navMobileAuthLinks
    : navMobileUnauthLinks;

  const handleOpenMenu = () => {
    document.getElementById("mobile-menulist").style.display = "block";
    document.getElementsByClassName("header-menu")[1].style.display = "none";
    document.getElementsByClassName("header-menu")[2].style.display = "block";
    document.getElementsByTagName("html")[0].style.overflowY = "hidden";
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  const handleCloseMenu = () => {
    document.getElementById("mobile-menulist").style.display = "none";
    document.getElementsByClassName("header-menu")[1].style.display = "block";
    document.getElementsByClassName("header-menu")[2].style.display = "none";
    document.getElementsByTagName("html")[0].style.overflowY = "scroll";
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  };
  history.listen((location, action) => {
    getIsUaAuthentication().then(({ status }) => setIsAuthenticated(status));
  });

  const setQuoteModal = () => {
    openQuoteModal();
  };

  const showQuoteSuccess = () => {
    showSuccessSummary();
  };

  return (
    <>
      <header className="header-border-mob"
        id="header"
        style={{
          background: getEleAttribute("customPrimaryColor", theme),
          borderBottom: `8px solid ${
            getEleAttribute("btnBackgroundColor", theme)
          }`,
        }}
      >
        {/* <ul>
            <li className="leftp"><span> MENU</span><span> 1-888-321-8204</span></li>
            <li className="middlep">
              <div className='middleCtr'>

                <img src={Logo} alt="logo" /><span className="logotext">PrivateAV</span>
              </div>
              
              </li>
            <li className="rightp">                                                                  
            </li>
          </ul> */}     
           <a
              href="https://www.flyjetaccess.com/?gad_source=1&gclid=CjwKCAjwlbu2BhA3EiwA3yXyuwAHXV15W_iCVxTRsqUTE74jVRmEnAqSW8-e-0x4ox95QrBMrGSfoxoCcMgQAvD_BwE"
              target="_blank"
            >
        <div className="logo">
          <img src={operatorLogo} alt="operator-logo" />
        </div></a>      
      </header>

      <div className="quote_modal">
        <QuoteModal
          status={modalOpen}
          closeQuoteModal={closeQuoteModal}
          theme={theme}
          flightinfo={flightinfo}
          operatorId={operatorId}
          showQuoteSuccess={showQuoteSuccess}
          quoteSuccess={quoteSuccess}
        />
      </div>
    </>
  );
};

function mapStateToProps({ widget }) {
  return {
    modalOpen: widget.modalOpen,
    flightinfo: widget.flightdata,
    theme: widget.theme,
    operatorId: widget.operatorId,
    quoteSuccess: widget.quoteSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openQuoteModal: () => dispatch({ type: QUOTE_MODAL_OPEN, modalOpen: true }),
    closeQuoteModal: () =>
      dispatch({ type: QUOTE_MODAL_OPEN, modalOpen: false }),
    showSuccessSummary: () =>
      dispatch({ type: QUOTE_SUCCESS, quoteSuccess: true, modalOpen: false }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomThemeHeader);
