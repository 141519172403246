import {combineReducers} from 'redux'
import UserReducer from "./user.reducer";
import AircraftReducer from "./aircraft.reducer";
import PaymentReducer from "./payment.reducer";
import WidgetReducer from "./widget.reducer";

const rootReducer = combineReducers({
  user: UserReducer,
  aircraft: AircraftReducer,
  payment: PaymentReducer,
  widget : WidgetReducer
});

export default rootReducer
