import React from 'react';
import {Link} from 'react-router-dom';
import FooterLogo from '../../assets/logos/ab1_logo.png';
import InstaLogo from '../../assets/logos/instagram-logo.png';
import TwitterLogo from '../../assets/logos/twitter-logo.png';
import FacebookLogo from '../../assets/logos/facebook-logo.png';
import NBAALogo from '../../assets/logos/NBAA.png';
import AirLogo from '../../assets/logos/4air.png';
import WyvernLogo from '../../assets/logos/jira.png';
import CarbonNeutralLogo from '../../assets/logos/carbon-neutral.png';
import AvinodeLogo from '../../assets/logos/avinode-blackbg.png';
import {footerSectionLinks} from '../../constants/app-constants';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer-main'>
      <div className='footer-root'>
        <footer>
          <div className='footer-sections-logo'>
            <div className='footer-img'>
              <img
                src={FooterLogo}
                alt='AB1 logo'
                className='footer-ab1-logo'
              />
            </div>
          </div>
          <div className="footer-root-main">
          {footerSectionLinks.map(({title, links}, index) => {
            return (
              <div key={index} className='footer-sections'>
                <p className='footer-section-title'>{title}</p>
                <ul className='footer-section-list'>
                  {links.map(({itemName, href, external = false}, indexVal) => {
                    return (
                      <li key={indexVal}>
                        {!external && <Link to={href}>{itemName}</Link>}
                        {external && <a href={href}>{itemName}</a>}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
          <div className="footer-sections-c">
            <div className="inner-section">
              <a href="#">
                <img
                  src={NBAALogo}
                  className='footer-c-1'
                  alt=''
                />
              </a>
              <div className="mid-section">
              <a href="#">
                <img
                  src={AirLogo}
                  className='footer-c-2'
                  alt=''
                />
              </a>
              <a href="#">
                <img
                  src={CarbonNeutralLogo}
                  className='footer-c-3'
                  alt=''
                />
              </a>
              </div> 
              <a href="#">
                <img
                  src={WyvernLogo}
                  className='footer-c-4'
                  alt=''
                />
              </a>
              <a target="_blank" href="#">
                <img
                  src={AvinodeLogo}
                  className='footer-c-5'
                  alt=''
                />
              </a>
            </div>
          </div>
          </div>
          <div className='footer-sections-si'>
            <div>
              <a target="_blank" href="https://www.instagram.com/airbook_one">
                <img
                  src={InstaLogo}
                  className='footer-si'
                  alt='Follow us on Instagram'
                />
              </a>
              <a target="_blank" href="https://twitter.com/airbookone">
                <img
                  src={TwitterLogo}
                  className='footer-si'
                  alt='Follow us on Twitter'
                />
              </a>
              <a target="_blank" href="https://www.facebook.com/Airbookone-104373114981452">
                <img
                  src={FacebookLogo}
                  className='footer-si'
                  alt='Follow us on Facebook'
                />
              </a>
            </div>
          </div>
          
        </footer>
      </div>
    </div>
  );
};

export default Footer;
