import React from 'react';
import ArrowDown from '../../assets/icons/arrow-down.png';
import Bolt from '../../assets/icons/bolt.png';
import Medal from '../../assets/icons/medal.png';
import CreditCard from '../../assets/icons/credit-card.png';
import DownloadRef from '../../components/DownloadRef';
import LearnMoreImg from '../../assets/learn-more.jpg';
import LearnMore from '../../components/LearnMore';
import Shield from '../../assets/icons/shield.png';
// import FlyPrivateCard from '../../components/FlyPrivateCard';
import ServiceCard from '../../components/ServiceCard';
import CarbonOffset from '../../assets/icons/carbon-offset.png';
import Cost from '../../assets/icons/cost.png';

import './HowItWorks.scss';

const HowItWorks = ({history}) => {
  const services = [
    {
      image: Bolt,
      title: "Auto Booking",
      html: true,
      reference:
        "<p>AIRBOOK ONE's provides Real-Time Market Pricing that can be automatically booked and 100% guaranteed at point of purchase.<br/><br/>AIRBOOK ONE uses proprietary Real Time Market Average Algorithms, showing the customer the “Daily Market Average” on the OPEN market for all requested searches.<br/><br/>AIRBOOK ONE’s Recommended Trips are based on key performance Indicators such as, the real-time Daily Market Average, Fastest Trip Time – locally sourced by departure location, Customer Reviews of Operators and other similar indicators.</p>",
    },
    {
      image: CreditCard,
      title: "Limitless Wallet",
      html: true,
      reference:
        "<p>APassengers can deposit funds into their Limitless Wallet to pay for future flights, creating an instant seamless payment process and avoiding transaction fees.<br/><br/>Wallet Funding Requirements: $25,000 minimum initial deposit, $10,000 minimum balance at all times. If you are interested, please contact us for setup.</p>",
    },
    {
      image: Cost,
      title: 'Payment Process',
      html:true,
      reference:
        `AIRBOOK ONE’s auto payment uses the following forms of payment:
         <ul>
          <li>Apple Pay</li>
          <li>Credit Cards</li>
          <li>Wire Transfer</li>
          <li>AB1 Limitless Wallet</li>
         </ul>`
    },
    {
      image: Shield,
      title: "Safety Ratings",
      html: true,
      reference:
        "<p>At AIRBOOK ONE safety is our number one priority. Every operator is closely vetted and must adhere to strict standards. All operators are Federal Aviation Authority (FAA) Part 135 certified air carriers that have been audited by an independent Safety Auditor (see below for information on each auditor).<br><br>Every trip is screened to verify that these strict standards are met or exceeded, in addition to all FAA standards. This includes the specific operator, aircraft and crew members for all trips. The operator exercises full operational control of each flight at all times.</p>",

    },
    {
      image: Medal,
      title: "Loyalty Program",
      html: true,
      reference: `
        <p>AIRBOOK ONE further enhances the private travel experience by giving back through rewards. <br/><br/>Our customers receive one (1%) percent of the total flight cost in rewards to use towards any future flights with AIRBOOK ONE within 24 months of booking.</p>
        <!-- <ul> Uncomment me when ready to describe
          <li>Test1</li>
          <li>Test 2</li>
          <li>Test 3</li>
        </ul> -->
      `,
    },
    {
      image: CarbonOffset,
      title: "Carbon Offset",
      html: true,
      reference:
        "<p>AIRBOOK ONE works with the company 4AIR. AIRBOOK ONE ensures every flight booked with us is carbon Neutral and will meet 4Air bronze level every time you fly <br/><br/> 4AIR supports projects all over the world that fund greenhouse gas reductions including renewable energy, forestry and efficiencies. Every project meets the stringent criteria through leading nonprofit standards like gold standard, verra, climate action reserve and American carbon registry.</p>",
    },
  ];
  return (
    <div>
      <div className="root-section">
        <div className="hiw-section-1">
          <h4 className="about-us">
          AIRBOOK ONE was founded in an effort to disrupt private aviation by offering the first customer-focused private air service and delivering world-class personal travel experiences to consumers.
            <br></br>
            <br></br>
            With both our booking platform and proprietary pricing-availability algorithm we give ‘Power to the Passenger’ by allowing instant access to private aircraft and real time guaranteed pricing, with no membership fees or pre-paid flight hours required. For more information, download the AIRBOOK ONE mobile app via the Apple and Google Play stores.
  </h4>
          <img className='scroll-icon pointer-cursor'
               src={ArrowDown}
               onClick={() => {
                 window.document.getElementById("works-features").scrollIntoView({behavior: "smooth"})
               }}
               alt='scroll down'/>
        </div>
        <div className='services-section' id={"works-features"}>
           {services.map((service, index) => {
             return <ServiceCard key={index} {...service} />;
           })}
        {/* <div id="works-features">
          {services.map((item, index) => (
            <FlyPrivateCard key={index} {...item} />
          ))} */}
        </div>
        <div className='spacer'/>
        <LearnMore
          onClick={() => history.push("/why_fly_private")}
          title='Learn more about flying privately.'
          img={LearnMoreImg}
          arrowDir='right'
        />
        <DownloadRef/>
      </div>
    </div>
  );
};

export default HowItWorks;
