import React from 'react';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <div className='general-section-priv-policy'>
      <>
        <p
          style={{
            textAlign: 'center',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '11.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <u>
              <strong>AirBookOne&nbsp;</strong>
            </u>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <u>
              <strong>Privacy Policy</strong>
            </u>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Last Updated:&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            February 20
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            , 2021
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '11.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            AirBookOne, LLC (“
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>AirBookOne</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”, “
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>we</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”, “
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>us</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”, or “
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>our</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”)&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            understands your concerns with regard to how information about you
            is used and shared, and we appreciate your trust that we will use
            and&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            share information about you carefully and sensibly
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            . &nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            This Privacy Policy (this “
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Policy</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”)&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            applies to information about you collected in connection with your
            use of our&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            private jet aircraft reservation and chartering services (the “
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Services</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”), as well as you
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            r access to and use of our websites, mobile applications, and online
            hosted portals that support the Services (including, without
            limitation, the website located at&nbsp;
          </span>
          <a href='http://www.airbookone.com' title='http://www.airbookone.com'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>www.airbookone.com</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ) (collectively, the “
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Site</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”)
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            . T
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            his Policy&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            describes what information we collect about you in relation to your
            use of the Site and Services, how we collect it, how we use it, with
            whom we may share it, and what choices you have regarding it.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '11.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Please read this Policy carefully.&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            By clickin
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            g “accept” or otherwise accessing and using the Site and Services,
            you agree that you have read and understand this Policy and that you
            accept and consent to the privacy practices (and any uses and
            disclosures of information about you) that are described i
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            n this Policy.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>When do we collect your information?</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We collect information relating to you and your use of the Site and
            Services in order to provide services and features that are
            responsive to your needs. We collect information from: (i) our
            supplier air
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;carriers and operators (“
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Operators</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”); (ii) consumers, entities, and/or other members of the general
            public seeking to reserve or charter private jet aircraft flights (“
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Customers</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”); and (iii) general Site visitors and users (“
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Visitors</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”). We collect persona
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            l information in the following ways:
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '12.000000000000002pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Directly from you. &nbsp;</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                We collect information from you when you register an account
                with us, make a reservation, submit content or information to
                the Site, fill out a form, complete a survey, request
                information, send us&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                an email, text message, or other communication, speak with us on
                the telephone, or otherwise interact with us or the Site or
                Services. &nbsp;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '12.000000000000002pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>From other third-party sources.</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;We may receive information about you from Operators or our
                other third-party partners a
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                nd service providers who help us to provide services to you,
                including the Site and Services.
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Automatically as you navigate the Site</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                . Information collected automatically as you use and navigate
                the Site may include usage details, email address, IP address,
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;and information collected through cookies and other
                tracking technologies.
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>What information do we collect?</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Information you provide to us.</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;We collect the information you provide directly to us when you
            register an account, make a reservation, and otherwi
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            se access and use the Site and Services. The types of information
            that we collect from you varies depending on whether you are an
            Operator, Customer, or Visitor. If you are an Operator, we will
            collect your:
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                First and last name
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Company name and&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                information
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                E-mail address
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Phone number
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Physical Address
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Flight listing and aircraft information
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Messaging content and information
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Information regarding your utilization of the Services
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Any other information that you submit to or store within the
                Site
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            you are a Customer, we will collect your:
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                First and last name
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                E-mail address
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Mobile phone number
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Physical Address
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Billing information
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Date of birth
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Gender
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Government identification number(s)
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Account information (including password and account settings and
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;preferences)
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Survey and form responses
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Content of any communications you have with us (including via
                email or telephone)
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Information regarding your health status and/or recent travel
                history
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Any other identifying information or travel information required
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;by the Transportation Security Administration (or
                applicable foreign equivalent)
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Any similar identifying information or travel information
                pertaining to each passenger flying with you
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Information regarding your utilization of the Services
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '8pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Any other inform
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                ation that you submit to or store within the Site (such as
                search history, ordered trips, etc.)
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you are a Visitor, we may use cookies and other tracking
            technologies to collect data regarding your visit(s) to the Site.
            Please see the below sections rega
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            rding information sent to us by web browsers and information
            collected by cookies and other technologies for more information.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Information sent to us by web browsers.</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;We and our third-party service providers collect information
            that is sent to us automati
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            cally by your web browser or device. This information typically
            includes your IP address, the identity of your Internet service
            provider, the name and version of your operating system, the name
            and version of your browser, the date and time of your visit (
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            and the number of your visits), the pages you visit, the length of
            your visit, average time spent, and the websites you visit before
            coming to and after leaving the Site. The information we receive may
            depend on your browser or device settings.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            The informa
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            tion we receive from your web browser and/or device is not, in and
            of itself, personally identifiable. Generally, we use this
            information in the aggregate to help us improve the Site and make it
            more compatible with the technology used by our visitors. How
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ever, we may combine it with other information in an attempt to
            identify you or we may combine it with information that does
            identify you. We may also review our server logs for security
            purposes – for example, to detect intrusions into our network – and
            w
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            e might share our server logs, which contain visitors’ IP addresses,
            with the appropriate investigative authorities who could use that
            information to trace and identify you.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>
              Information collected by cookies and other technologies.
            </strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;We use “cookies” and othe
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            r tracking technologies to collect information and support certain
            features of the Site. &nbsp;Cookies are identifiers we transfer to
            your device that allow us to recognize your device and tell us how
            and when pages and features in our Site and Services are vis
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ited and by how many people.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>How do we use your information?</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We process information in order to provide the Site and Services to
            you and any additional services you request, as well as to respond
            to communications from you. The precise purposes for which
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;your personal information is processed will be determined by
            the request, and by applicable laws, regulatory guidance, and
            professional standards.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Generally, we use the information we collect from you to:
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Provide the Site, Services, and any other&nbsp;</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>information or services you request to you.</strong>
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>
                  Support the Site, Services and our business. &nbsp;
                </strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                For example, we may use your information to respond to inquiries
                and troubleshoot any issues with the Site or Services.
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>&nbsp;</strong>
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Manage our business needs, such as operatin</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>
                  g, monitoring, analyzing, and improving the Services and the
                  Site’s performance and functionality. &nbsp;
                </strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                For example, we analyze website user behavior and conduct
                research and analysis about the way you and other users use the
                Site and Services.
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Facilitate com</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>munication between Customers and Operators.</strong>
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '18pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Manage risk and protect the Site.&nbsp;</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                We use data to better protect the Site, Services, and you by
                helping to detect and prevent fraud and abuse of the Site and
                Services.&nbsp;
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Tailor our offerings and the offerings of&nbsp;</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>
                  Operators to your preferences and apparent interests
                </strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                . &nbsp;
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>
                  Improve the Site, Services, and our business, and develop new
                  services and offerings.
                </strong>
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: '120%',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>
                  Maintain our records, including, but not limited to, your
                  opt-in and opt-out records.
                </strong>
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Market, promote, and prov</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>
                  ide other information regarding our services and offerings
                  (including the Services) and/or the flights, services, and
                  offerings of Operators.
                </strong>
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>
                  Conduct processing necessary to fulfill other contractual
                  obligations for you.
                </strong>
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>Comply with our other contractual</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>, legal, and regulatory obligations.</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;We will use your information as necessary to enforce the
                terms of the Site and our Services, to address emergency
                situations, and to comply with our other contractual obligations
                and all applicable laws and regulations.
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>
                  Carry out other purposes for which you provide your
                  consent.&nbsp;
                </strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                For example, we will use your information for other purposes
                which are disclosed to you at the time your information is
                collected or for purposes which can be inferred from or are
                obvious given
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;the circumstances of collection.
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>&nbsp;</strong>
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Please see below for information about the choices you have about
            the ways we use your information.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>
                Do we disclose any information to outside parties?
              </strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We do not sell, trade, or transfer your information to third&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            parties, except in the circumstances described below.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Third-Party Service Providers</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We may disclose your information to our vendors and other
            third-party service providers, such as website hosts, analytics
            providers, and payment processors, that help us s
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            upport features on the Site and provide our Services to you.
            Further, our online service providers may implement technologies
            that allow for the collection of personally identifiable information
            over time and across websites. We may also disclose aggregate
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            d, de-identified information, and analyses and reports derived from
            such information, to our service providers. These service providers
            are responsible for their own compliance with data protection laws.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We use Google Analytics (including LiveChat) to meas
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ure and report how users interact with the Site, and to analyze the
            traffic to the Site and for the other purposes as set forth in this
            Policy. &nbsp;For more information about Google Analytics and how it
            collects and processes data, please see “
          </span>
          <a
            href='https://www.google.com/policies/privacy/partners/'
            title='https://www.google.com/policies/privacy/partners/'
          >
            <span
              style={{
                fontFamily: '"Brandon Text"',
                fontSize: 16,
                color: '#0000ff',
              }}
            >
              <u>
                How Google Uses Data When You Use Our Partners’ Sites or Apps
              </u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            .” For more information about how Google uses and shares
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;information collected by Google Analytics through your visits
            to&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Site
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            , please see Google’s&nbsp;
          </span>
          <a
            href='http://www.google.com/policies/terms/'
            title='http://www.google.com/policies/terms/'
          >
            <span
              style={{
                fontFamily: '"Brandon Text"',
                fontSize: 16,
                color: '#0000ff',
              }}
            >
              <u>Terms of Service</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;and&nbsp;
          </span>
          <a
            href='http://www.google.com/policies/privacy/'
            title='http://www.google.com/policies/privacy/'
          >
            <span
              style={{
                fontFamily: '"Brandon Text"',
                fontSize: 16,
                color: '#0000ff',
              }}
            >
              <u>Privacy Policy</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            . If you do not wan
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            t your&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Site&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            visit data reported by the Google Analytics JavaScript, you can
            install the Google Analytics opt-out browser add-on. &nbsp;This
            add-on instructs the Google Analytics JavaScript to prohibit sending
            visit information to Google Analytics, but does not&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            prevent information from being sent to the&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Site&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            itself or in other ways to other website analytics services. Click
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>&nbsp;</strong>
          </span>
          <a
            href='https://support.google.com/analytics/answer/181881?hl=en'
            title='https://support.google.com/analytics/answer/181881?hl=en'
          >
            <span
              style={{
                fontFamily: '"Brandon Text"',
                fontSize: 16,
                color: '#0000ff',
              }}
            >
              <u>here</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;if you would like to learn more about the Google Analytics
            opt-
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            out browser add-on, and click&nbsp;
          </span>
          <a
            href='https://tools.google.com/dlpage/gaoptout'
            title='https://tools.google.com/dlpage/gaoptout'
          >
            <span
              style={{
                fontFamily: '"Brandon Text"',
                fontSize: 16,
                color: '#0000ff',
              }}
            >
              <u>here</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;to install the opt-out browser add-on.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Operators</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you are a Customer, we will disclose your information (including,
            but not limited to, your contact information a
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            nd other personal information) to the Operator of the flights that
            you have reserved or chartered so that they may coordinate those
            flights with you and send you updates, notices, and other
            communications regarding your flights.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Legal Requirements and Bus</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>iness Transfers</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We may disclose your information: (i) if we are required to do so by
            law, legal process, statute, rule, regulation, or professional
            standard, or to respond to a subpoena, search warrant, or other
            government official request; (ii) when we be
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            lieve disclosure is necessary or appropriate to prevent physical
            harm or financial loss; (iii) in connection with an investigation of
            a complaint, security threat, or suspected or actual illegal
            activity; (iv) in connection with an internal audit; or (v) i
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            n the event that AirBookOne is subject to mergers, acquisitions,
            joint ventures, sales of assets, reorganizations, divestitures,
            dissolutions, bankruptcies, liquidations, or other types of business
            transactions. In these types of transactions, your informa
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            tion (including personal information) may be shared, sold, or
            transferred, and it may be used subsequently by a third party.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>What choices do you have about your information?</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We offer certain choices about how we communicate with you and what
            information&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            we obtain about you and share with others. &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Account</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>.</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you are a Customer with an account on the Site, you can update,
            modify, or delete certain of your account information and
            preferences after you log into your account using the features
            and&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            functionalities available there
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            . You may also contact us at&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            the email address below for assistance in updating, modifying, or
            deleting any of your information.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Email preferences.</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp; You
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;may opt out from receiving future promotional or informational
            emails f
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            rom us by following the instructions for unsubscribing in the emails
            we send to you or by contacting us at the email address below.&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            However, you cannot opt out of transactional emails related to your
            account registration or other transactions with us. &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>D</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>o Not Track. &nbsp;</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            While we take all reasonable steps to protect the privacy of our
            Site visitors, we cannot promise that the current limitations of our
            online applications programming will address every browser setting
            or honor every personal browser preferenc
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            e. In particular, we have not implemented the necessary program
            changes to honor “Do Not Track” or “DNT” browser signals. As our
            online applications programming is refined, we will take all
            reasonable steps to honor such requests in the future. Please retu
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            rn to this Policy for further updates on this topic.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Cookies. &nbsp;</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Most web browsers automatically accept cookies, but you can change
            your browser’s settings to disable all or certain cookies if you
            wish. Please be aware that if you change the preferences on
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;your device to prevent or limit your device’s acceptance of
            cookies, this may prevent you from taking advantage of some of the
            features on the Site.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '8pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>Location of the Site</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '8pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            The&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Site&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            is hosted and operated in the United States. &nbsp;However, we and
            our service&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            providers may store information about individuals in the United
            States, or we may transfer it to, and store it within, other
            countries.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '8pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Visitors from jurisdictions outside the United States visit us at
            their own choice and risk.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '8pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you are not a resident of the United States, you acknowledge and
            agree that we may collect and use your personal information outside
            your home jurisdiction, and that we may store your personal
            information in the United States or elsewhere. Please note t
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            hat the level of legal protection provided in the United States from
            which you may access the&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Brandon Text"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Site&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            may not be as stringent as that under privacy standards or the
            privacy laws of other countries, possibly including your home
            jurisdiction.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>How long do we ret</strong>
            </em>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>ain your information?</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We will keep your information for the period necessary to fulfil the
            purposes described in this Policy, unless a longer retention period
            is permitted or required by law.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>Third-party links</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Occasionally, at our discretion, we may lin
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            k to third-party products or services on or through the Site or
            Services. These third parties have separate and independent privacy
            policies. We therefore have no responsibility or liability for the
            content and activities of these linked sites. We urge you
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;to read the privacy policies of other websites before
            submitting any information to those websites. &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>How do we protect your information?</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            AirBookOne has implemented reasonable physical, technical, and
            administrative security standards to protect your inf
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ormation from unauthorized loss, misuse, alteration, or destruction.
            We strive to protect your personal information against unauthorized
            access, use, or disclosure, using security technologies and
            procedures, such as encryption and limited access. Only aut
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            horized individuals access your personal information, and they
            receive training about the importance of protecting personal
            information. However, no system or procedures are entirely secure or
            failsafe, and we cannot and do not guarantee the security of yo
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ur information.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            backgroundColor: '#ffffff',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <em>
              <strong>CALIFORNIA PRIVACY NOTICE</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            backgroundColor: '#ffffff',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Pursuant to applicable California law, including the California
            Consumer Privacy Act (“
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>CCPA</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”), AirBookOne
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;makes the following disclosures regarding the personal
            information we collect:
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            backgroundColor: '#ffffff',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <div style={{overflowX:"auto"}}>
        <table
          border={0}
          cellPadding={0}
          cellSpacing={0}
          style={{
            marginLeft: '0pt',
            borderLeft: '1pt solid #000000',
            borderTop: '1pt solid #000000',
            borderRight: '1pt solid #000000',
            borderBottom: '1pt solid #000000',
            width: '100%'
          }}
        >
          <tbody>
          <tr>
            <td
              style={{
                width: '25%',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='25%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    <u>
                      <strong>Category of Personal Information</strong>
                    </u>
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '85.49999999999999pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='18.26923076923077%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    <u>
                      <strong>
                        Category of Source from which Data is Collected
                      </strong>
                    </u>
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '171.00000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='36.53846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    <u>
                      <strong>Purpose of Collection</strong>
                    </u>
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '112.25000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='24.03846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    <u>
                      <strong>
                        Category of Third Parties to whom Data is Disclosed
                      </strong>
                    </u>
                  </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '98.75000000000001pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='21.153846153846153%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Identifying information and contact&nbsp;
                  </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                    information, such as name, e-mail address, phone number,
                    mailing address
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '85.49999999999999pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='18.26923076923077%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Directly from users
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '171.00000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='36.53846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    To provide and support the Services and maintain our
                    electronic and digital programs
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '112.25000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='24.03846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Service Providers and Operators
                  </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '98.75000000000001pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='21.153846153846153%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Payment i
                  </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                    nformation such as credit card information
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '85.49999999999999pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='18.26923076923077%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Directly from users
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '171.00000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='36.53846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    To facilitate payment for the Services
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '112.25000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='24.03846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Service Providers
                  </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '98.75000000000001pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='21.153846153846153%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Date of birth, government identification number(s), and
                    demographic information
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '85.49999999999999pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='18.26923076923077%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Directly from users
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '171.00000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='36.53846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    To provide and support the Services
                  </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                    &nbsp;and maintain our electronic and digital programs
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '112.25000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='24.03846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Service Providers and Operators
                  </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '98.75000000000001pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='21.153846153846153%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Geo-location information
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '85.49999999999999pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='18.26923076923077%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Directly from users
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '171.00000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='36.53846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    To provide and support the Services and maintain our
                    electronic and digital programs
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '112.25000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='24.03846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Service Providers
                  </span>
              </p>
            </td>
          </tr>
          <tr>
            <td
              style={{
                width: '98.75000000000001pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='21.153846153846153%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Automatically collected&nbsp;
                  </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                    information on websites such as IP address, device type,
                    browser type
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '85.49999999999999pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='18.26923076923077%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Cookies and other tracking technologies
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '171.00000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='36.53846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    For data analysis, audits, security and fraud monitoring and
                    prevention; activities, events and promotions; for
                    developing new goods and&nbsp;
                  </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                    services, enhancing, improving or modifying our Services,
                    identifying usage trends, determining the effectiveness of
                    our promotional campaigns and operating and expanding our
                    business activities
                  </span>
              </p>
            </td>
            <td
              style={{
                width: '112.25000000000003pt',
                padding: '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                borderLeft: '1pt solid #000000',
                borderTop: '1pt solid #000000',
                borderRight: '1pt solid #000000',
                borderBottom: '1pt solid #000000',
              }}
              width='24.03846153846154%'
            >
              <p
                style={{
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  border: 'none',
                }}
              >
                  <span
                    style={{
                      fontFamily: '"Times New Roman"',
                      fontSize: 16,
                      color: '#000000',
                    }}
                  >
                    Service Providers
                  </span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
        </div>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            backgroundColor: '#ffffff',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              backgroundColor: '#ffffff',
              color: '#000000',
            }}
          >
            We&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            have not sold consumers’&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            personal information in the twelve (12) months preceding the
            effective date of this Policy.
          </span>
        </p>
        <p
          style={{
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you are a California resident, pursuant to the CCPA, you have the
            right to request:
          </span>
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                the categories of personal information we have collected
                about&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                you;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                the cat
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                egories of sources from which your personal information is&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                collected;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                the business or commercial purpose of collecting or selling your
                personal&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                information;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                the categories of third parties with whom we&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                shares
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;your personal information;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                the specific pieces&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                of personal information we have collected about&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                you;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                when and if applicable, the categories of personal information
                that we have sold about you and the categories of third parties
                to whom the personal information was sold, if&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                applicable;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                deletion of your p
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                ersonal information; and
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                an opt out of having your personal information disclosed or sold
                to third parties.
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            To submit a request, or designate an authorized agent to make a
            request under the CCPA on your behalf, please contact us at&nbsp;
          </span>
          <a
            href='mailto:contactus@airbookone.com'
            title='mailto:contactus@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>contactus@airbookone.com</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;or call us at 1-312-761-5080
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            . To verify your identity when you submit a request, we will match
            the identifying information you provide us to the personal
            information we have about you. &nbsp;If you have an account wi
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            th us, we will also verify your identity through our existing
            authentication practices for your account. Request will typically be
            honored within forty-five (45) days or&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            less, but
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;may take up to ninety (90) days based on the results of
            verification.
          </span>
        </p>
        <p
          style={{
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            However, we will not be required to comply with your request to
            delete your personal information if it is necessary for us to
            maintain your personal information in order to:
          </span>
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                complete the transaction for which the personal information was
                collected, provide
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;a good or service requested by you, or reasonably
                anticipated within the context of our ongoing business
                relationship with you, or otherwise perform a contract between
                you and&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                us;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                detect security incidents, protect against malicious, deceptive,
                fraudulent
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                , or illegal activity; or prosecute those responsible for
                that&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                activity;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                debug to identify and repair errors that impair existing
                intended&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                functionality;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                exercise free speech, ensure the right of another consumer to
                exercise his or her right of free speech
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                , or exercise another right provided for by&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                law;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                comply with the California Electronic Communications Privacy Act
                pursuant to Chapter 3.6 (commencing with Section 1546) of Title
                12 of Part 2 of the Penal&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                Code;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                engage in public or peer-reviewed scientific,&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                historical, or statistical research in the public interest that
                adheres to all other applicable ethics and privacy laws, when
                our deletion of the information is likely to render impossible
                or seriously impair the achievement of such research, if you
                have p
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                rovided informed&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                consent;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                to enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                us;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                comply with a legal obligation; or
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                otherwise use your personal information, internally, in a lawful
                manner that&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                is compatible with the context in which you provided the
                information.
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We will not discriminate against you in the event you exercise any
            of the aforementioned rights under CCPA, including, but not limited
            to, by:
          </span>
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                denying goods or services to&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                you;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                charging&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                different prices or rates for goods or services, including
                through the use of discounts or other benefits or imposing&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                penalties;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                providing a different level or quality of goods or services to
                you; or
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                lineHeight: 'normal',
                marginTop: '5pt',
                marginBottom: '5pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                suggesting that you will receive a different price or ra
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                te for goods or services or a different level or quality of
                goods or services.
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            This Policy is available to consumers with disabilities. To access
            this Policy in an alternative downloadable format, please
            email&nbsp;
          </span>
          <a
            href='mailto:accessibility@airbookone.com'
            title='mailto:accessibility@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>accessibility@airbookone.com</u>
            </span>
          </a>
        </p>
        <p
          style={{
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>Your Nevada Privacy Rights</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            We do&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            not currently sell your covered information as those terms are
            defined under applicable Nevada law. However, you may still submit
            an opt-out request and we will honor that request as required by
            Nevada law if we were to ever engage in such a sale in the fu
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ture. If you are a Nevada resident and would like to opt-out of
            future sales of your covered information, please submit your request
            to&nbsp;
          </span>
          <a
            href='mailto:privacy@airbookone.com'
            title='mailto:privacy@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>privacy@airbookone.com</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;,
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;or by calling us at&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            312-761-5080
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            . Your request must include your full name, street address
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            , city, state, zip code, and an email address so that we can contact
            you if needed regarding this request. You may also be required to
            take reasonable steps as we determine from time to time in order to
            verify your identity and/or the authenticity of the r
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            equest. We will respond to your request within sixty (60) days
            either confirming that your request has been processed or indicating
            that we need an additional thirty (30) days to complete the request.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>Individuals within the European Union</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Controller of P</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>ersonal Data. &nbsp;</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            The data controller of the personal data we collect through the Site
            is:
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            AirBookOne, LLC
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <a
            href='mailto:legal@airbookone.com'
            title='mailto:legal@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>legal@airbookone.com</u>
            </span>
          </a>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Legal Bases for Processing Personal Data. &nbsp;</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you are an individual residing in the European Union/European
            Economic Area&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            (“
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>EU</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ”), we collect and process personal data about you where we have a
            legal basis for doing so under applicable EU laws. This means we
            collect and process your data only when:
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                it is necessary for a legitimate interest (which is not
                overridden by your ind
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                ividual privacy interests), such as preventing fraud, improving
                the Site, and increasing the security of the Site and
                network&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                infrastructure;
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            marginLeft: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                you have consented to this collection and processing for a
                specific&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                purpose;
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                it is necessary to fulfil our contr
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                actual obligations; or
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                it is necessary to comply with a legal obligation.
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Where we rely on your consent to process your personal data, you
            have the right to withdraw or decline consent at any time.
            &nbsp;Some examples of where we rely on your consent to proce
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ss your personal data include sending you marketing emails. If you
            wish to withdraw your consent, please contact us at&nbsp;
          </span>
          <a
            href='mailto:legal@airbookone.com'
            title='mailto:legal@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>legal@airbookone.com</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Some examples of our legitimate interests for processing personal
            data include:
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <ul>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                website and network&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                security;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                &nbsp;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                customer&nbsp;
              </span>
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                support;
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                fraud prevention; or
              </span>
            </p>
          </li>
          <li style={{listStyleType: 'disc'}}>
            <p
              style={{
                marginLeft: '36pt',
                textIndent: '-18pt',
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '0pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                improving the Site.
              </span>
            </p>
          </li>
        </ul>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Where we rely on our legitimate interests to process your personal
            data, you may have the right to object. More information on
            exercising this right can be found in the “Individual Rights” secti
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            on below.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you have any questions about or need further information
            concerning the legal basis on which we collect and use your personal
            data, please contact us at&nbsp;
          </span>
          <a
            href='mailto:legal@airbookone.com'
            title='mailto:legal@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>legal@airbookone.com</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            . &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Individual Rights. &nbsp;</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you are located in the EU, you have cer
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            tain rights under EU data protection law with respect to your
            personal data, including the right to request access to, correct,
            and delete your personal data. You may also have the right to
            receive a copy of your personal data in a commonly used and machin
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            e-readable format, and to transmit such data to another controller.
            You also may object to processing of your personal&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            data, or
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;ask that we restrict the processing of your personal data in
            certain instances.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            To request deletion of, access to, or to make changes to your
            personal data, or to otherwise any of the rights in this section,
            please contact us at&nbsp;
          </span>
          <a
            href='mailto:flightcon@airbookone.com'
            title='mailto:flightcon@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>flightcon@airbookone.com</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            . Please note that not all requests can be honored.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Transfers, Storage, and Process</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>ing. &nbsp;</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            The Site is operated from and hosted on servers located in the
            United States. If you access and use the Site from a location
            outside of the United States, any personal data you provide to us or
            that is otherwise collected may be transferred to and pr
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ocessed in the United States or any other jurisdiction in our sole
            discretion. Users of the Site should be aware that the laws that
            apply to the use and protection of personal data in the United
            States or other countries or jurisdictions to which we transf
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            er, or in which we process, personal data may differ from those of
            your country of residence. Users who access or use the Site from
            jurisdictions outside of the United States do so at their own choice
            and risk and are solely responsible for compliance with
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;local law. While we take steps to safeguard your personal
            data, the United States has NOT been deemed by the European
            Commission to ensure an adequate level of protection for personal
            data. &nbsp;Accordingly, the level of protection provided in the
            United Stat
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            es or other non-EU countries and jurisdictions from which you may
            access the Site may not be as stringent as that under EU data
            protection standards or the data protection laws of some other
            countries, possibly including your home jurisdiction.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If we are&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            processing your personal data on behalf of another party, your
            personal data is transferred across borders to the United States or
            to other countries or jurisdictions in which we or our third-party
            associates may process personal data through the use of St
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            andard Contract Clauses.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <strong>Unresolved Complaints. &nbsp;</strong>
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            If you are a resident of the EU and your inquiry with us has not
            been satisfactorily addressed, or if you believe we are processing
            your personal data not in accordance with the law or this Policy,
            you may
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;file a complaint with the supervisory authority in your
            country of residence.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>Do we collect information from children?</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Due to the nature of the Site and Services, we will collect and
            maintain certain&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            identifying information and travel information about each passenger
            flying with you, which may include passengers under 16 years of age.
            We will use information collected about passengers known to be under
            16 only to provide the Site and Services, to compl
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            y with federal aviation or security regulations, and for other
            safety and security reasons.&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Notwithstanding the above, the Site and Services are not intended
            for direct use by children under 16 years of age. If you are under
            16, do not use or provide any&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            information on the Site or Services, or on or through any of their
            features, including your name or email address, without the
            supervision and consent of your parent and/or legal guardian.
            &nbsp;If we learn we have collected or received personal information
            fro
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            m a child under 16 without verification of parental or legal
            guardian consent, we will delete that information. If you believe
            any information about your child under 16 has been submitted to or
            collected by us without your consent, please contact us at&nbsp;
          </span>
          <a
            href='mailto:privacy@airbookone.com'
            title='mailto:privacy@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>privacy@airbookone.com</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;.
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;&nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>Contacting us</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            For any questions, or to request further information regarding this
            Policy, please contact us at&nbsp;
          </span>
          <a
            href='mailto:flightcon@airbookone.com'
            title='mailto:flightcon@airbookone.com'
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#0563c1',
              }}
            >
              <u>flightcon@airbookone.com</u>
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            &nbsp;.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 19,
              color: '#000000',
            }}
          >
            <em>
              <strong>Changes to this Policy</strong>
            </em>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            It is our policy to post any changes we make to this Policy on this
            pag
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            e. If we make material changes to how we treat our users'
            information, we will notify you by e-mail to the primary e-mail
            address you have provided to us and/or possibly through a notice on
            our Site’s home page. The date this Policy was last revised is ide
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            ntified at the top of the page. You are responsible for ensuring we
            have an up-to-date active and deliverable e-mail address for you,
            and for periodically visiting the Site and this Policy to check for
            any changes.
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '0pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
      </>
    </div>
  );
};

export default PrivacyPolicy;
