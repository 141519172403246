import React, { useEffect, useState } from "react";
import "./QuoteModal.scss";
import Modal from "../../../components/Modal";
import TextField from '@material-ui/core/TextField'
import InputBox from '../../../components/InputBox';
import Button from "../Button";
import { connect } from "react-redux";
import {
  QUOTE_MODAL_OPEN
} from "../../../middleware/constants/widget.constant";
import { getEleAttribute } from "../utility";
import { getSessionID } from "../../../utils/session.storage.utils";
import LoadingOverlay from "react-loading-overlay";
import { API_PATH, alphaSpaceOnly } from "../utility"
import Input from "react-phone-number-input/input";

const QuoteModal = (props) => {

  let fname = '';
  let lname = '';
  let phone = '';
  let em = '';
  const [state, setState] = useState({ firstname: "", lastname: "", phone: "", email: "" });
  const [loader, setLoader] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)

  const [quoteError, setQuoteError] = useState(false);
  const [quoteErrorMessage, setQuoteErrorMessage] = useState('')

  const [tempData, setTempData] = useState({ "firstName": fname, "lastName": lname, "phoneNumber": phone, "email": em })



  const [quoteHeader, setQuoteHeader] = useState('First-Time User')


  useEffect(() => {
    let userDet = getCookie("userDet");
    let contactSet = false;
    if (userDet && userDet !== null && userDet !== '') {
      let userDetails = JSON.parse(userDet)

      if (userDetails.hasOwnProperty('firstName')){
        setFirstName(userDetails.firstName)
        contactSet = true
      }
       

      if (userDetails.hasOwnProperty('lastName')){
        setLastName(userDetails.lastName)
        contactSet = true
      }

      if (userDetails.hasOwnProperty('phoneNumber')){
        setPhoneNumber(userDetails.phoneNumber)
        contactSet = true
      }

      if (userDetails.hasOwnProperty('email')){
        setEmail(userDetails.email)   
        contactSet = true
      }
        
      if((firstName !== '' || lastName !== '' || email !== '' || phoneNumber !== '' ) && contactSet === true) {
        setQuoteHeader('Confirm Your Information')
      }

    }



  }, [props.status]);


  const handlePhone = e => {
    setState({ ...state, phone: e });
  }


  const handleInputChange = (e) => {

    let fname = e.target.name;
    let fval = e.target.value;

    if (fname === 'firstName') {
      setFirstName(fval)
      if (fval !== '') {
        if (!alphaSpaceOnly(fval)) {
          setFirstNameError(true)
        }
        else {
          setFirstNameError(false)
        }
      }
      else {
        setFirstNameError(fval !== '' ? false : true)
      }
    }
    else if (fname === 'lastName') {
      setLastName(fval)
      if (fval !== '') {
        if (!alphaSpaceOnly(fval)) {
          setLastNameError(true)
        }
        else {
          setLastNameError(false)
        }
      }
      else {
        setLastNameError(fval !== '' ? false : true)
      }

    }
    else if (fname === 'phoneNumber') {
      if (e.nativeEvent.data !== 'e') {
        setPhoneNumber(fval)

        if (fval !== '') {
          if (!ValidatePhone(fval)) {
            setPhoneNumberError(true)
          }
          else {
            setPhoneNumberError(false)
          }
        }
        else {
          setPhoneNumberError(true)
        }
      }
    }
    else if (fname === 'email') {
      setEmail(fval)

      if (fval !== '') {
        if (!ValidateEmail(fval)) {
          setEmailError(true)
        }
        else {
          setEmailError(false)
        }
      }
      else {
        setEmailError(true)
      }

    }
    setData(fname, fval)
  }

  const setData = (fname, fval) => {
    let temp_data = tempData;
    temp_data[fname] = fval;
    setTempData(temp_data)

    setCookie("userDet", JSON.stringify(temp_data), 1)
  }

  const submitQuote = (e) => {
    //e.preventDefault();

    let status = 0;
    if (firstName === '') {
      setFirstNameError(true)
      ++status;
    }

    if (lastName === '') {
      setLastNameError(true)
      ++status;
    }
    if (phoneNumber === '') {
      setPhoneNumberError(true)
      ++status;
    }
    else {
      if (!ValidatePhone(phoneNumber)) {
        setPhoneNumberError(true)
        ++status;
      }
    }

    if (email === '') {
      setEmailError(true)
      ++status;
    }
    else {
      if (!ValidateEmail(email)) {
        setEmailError(true)
        ++status;
      }
    }


    if (status === 0) {

      let temp_data = tempData;
      temp_data= { "firstName": firstName, "lastName": lastName, "phoneNumber": phoneNumber, "email": email }
      setTempData(temp_data)
  
      setCookie("userDet", JSON.stringify(temp_data), 1)


      setLoader(true)
      let data = {
        "aboneSearchId": props.flightinfo.aboneSearchId || '',
        "operatorId": props.operatorId || '',
        "bookingId": "",
        "sessionId": getSessionID(),
        "useRewards": false,
        "addonIds": "",
        "firstName": firstName,
        "lastName": lastName,
        "phoneNumber": phoneNumber,
        "email": email
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      };

      fetch(API_PATH + "white_label/update-addons-wl", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.bookingId !== null) {
            props.showQuoteSuccess()
            setLoader(false)
          }
          else {
            setQuoteError(true);
            setQuoteErrorMessage(result.error);
            setLoader(false)
          }

        })
        .catch(error => console.log('error', error));



      //props.closeQuoteModal()
    }
  }

  function ValidateEmail(inputText) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) {
      return true;
    }
    return false
  }

  function ValidatePhone(inputtxt) {

    var phoneno = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      return true;
    }
    else {
      return false;
    }
  }
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const closeQuoteModal = () => {
    props.closeQuoteModal();
    setQuoteError(false);
    setQuoteErrorMessage('')

  }


  return (

    <Modal close={() => closeQuoteModal()} show={props.status}>
      <div className="QuoteModal">
        <div className="QuoteModalContainer">
          <p className="title">{quoteHeader}</p>

          <TextField
            name="firstName"
            fullWidth
            required
            id="standard-error"
            label="First Name"
            style={{ paddingBottom: "10px", paddingTop: "10px" }}
            onChange={handleInputChange}
            error={firstNameError}
            variant="standard"
            value={firstName}
          />

          <TextField
            name="lastName"
            fullWidth
            required
            id="outlined-required"
            label="Last Name"
            defaultValue=""
            style={{ paddingBottom: "10px", paddingTop: "10px" }}
            onChange={handleInputChange}
            error={lastNameError}
            variant="standard"
            value={lastName}
          />

          {/* <div class="MuiFormControl-root-1 MuiFormControl-fullWidth-4" variant="standard" style={{"paddingBottom": "10px", "paddingTop": "10px"}}>
            <label className="MuiFormLabel-root-10 MuiFormLabel-required-15 MuiInputLabel-root-5 MuiInputLabel-formControl-6 MuiInputLabel-animated-9" 
            data-shrink="false" >Phone Number<span class="MuiFormLabel-asterisk-16">&thinsp;*</span>
            </label>
            <div className="MuiInput-root-17 MuiInput-fullWidth-24 MuiInput-formControl-18 MuiInput-underline-21">
            <Input
            required
            defaultCountry="US"
            className="phone-input-quote MuiInput-input-25"
            placeholder="Phone Number*"
            value={state.phone ?? ""}
            onChange={handlePhone}
          />
                
            </div>
          </div> */}

          


          <TextField
            name="phoneNumber"
            fullWidth
            required
            id="outlined-required"
            label="Phone Number"
            defaultValue=""
            style={{ paddingBottom: "10px", paddingTop: "10px" }}
            onChange={handleInputChange}
            error={phoneNumberError}
            variant="standard"
            type="number"
            value={phoneNumber}
          />
          <TextField
            name="email"
            fullWidth
            required
            id="outlined-required"
            label="Email"
            defaultValue=""
            style={{ paddingBottom: "10px", paddingTop: "10px" }}
            onChange={handleInputChange}
            error={emailError}
            variant="standard"
            type="email"
            value={email}
          />
        </div>
        <Button
          label={`Send Request`}

          type="purple"

          iconPosition="left"
          onClick={(e) => submitQuote(e)}
          bgcolor={getEleAttribute('customPrimaryColor', props.theme)}
          color={getEleAttribute('customFontColor', props.theme)}
          id="send_req_quote_btn"
        />
        {quoteError &&
        <div className="error-message">{quoteErrorMessage}</div>
        }
      </div>
      <LoadingOverlay active={loader} spinner text="Submitting your quote..." fadeSpeed="1000" />

    </Modal>

  );
}

export default QuoteModal;