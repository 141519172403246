import React from 'react';
import VectorR from '../../assets/icons/VectorR.png';
import VectorLM from '../../assets/icons/VectorLM.png';
import VectorRM from '../../assets/icons/VectorRM.png';
import VectorL from '../../assets/icons/VectorL.png';
import Union from '../../assets/icons/Union.png';
import Suitcase from '../../assets/icons/suitcase-fill.png';
import './Aircraft.scss';

const Aircraft = ({image, id, desc, passengers, luggage}) => {
  return (
    <div className='aircraft-overview'>
      <div className='aircraft-img'>
        <img src={image} alt=''/>
      </div>
      <div className='aircraft-data'>
        <img className='vector-lines' src={VectorL} alt=''/>
        <p>{id}</p>
        <img className='vector-lines' src={VectorR} alt=''/>
      </div>
      <div className='aircraft-data-mobile'>
        <img className='vector-lines-mobile' src={VectorLM} alt=''/>
        <p>{id}</p>
        <img className='vector-lines-mobile' src={VectorRM} alt=''/>
      </div>
      <div className='aircraft-min-info'>
        <p>{desc}</p>
        <div>
          <span>
            <img src={Union} alt=''/> {passengers.min} - {passengers.max}
          </span>
          <span>
            <img src={Suitcase} alt=''/> {luggage.min} - {luggage.max}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Aircraft;
