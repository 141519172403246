import React, {useEffect} from 'react';
import {connect} from "react-redux";

import Logo from '../../assets/logos/search-brand-logo.png';
import Clean from '../../assets/icons/clean.png';
import Shied from '../../assets/icons/shield-fill.png';

import './Searching.scss';
import {SEARCH_AIRCRAFT} from "../../middleware/constants/aircarft.constants";

const Searching = ({searchOptions, searchAircraft, history, searchResults}) => {
  useEffect(() => {
    if (Object.keys(searchOptions).length === 0) {
      history.push("/");
    } else {
      searchAircraft(searchOptions);
    }
  }, []);
  useEffect(() => {
    if (searchResults.success) history.push("/book-flight");
    else if (Object.keys(searchResults.error).length > 0) history.push("/");
  }, [searchResults.success, searchResults.error])

  return (
    <div className="Searching">
      <div className='root-section'>
        <div className='search-main'>
          <img src={Logo} alt='searching flights'/>
          <p>Searching for your private flight...</p>
        </div>
        <div className='guidelines-section'>
          <div className="guidelines-column">
            <img src={Shied} alt='safety check' width={25}/>
            <h5>Safety Check</h5>
            <p>All aircraft meet FAA safety checks and regulations</p>
          </div>
          <div className="guidelines-column">
            <img src={Clean} alt='covid cleaned' width={25}/>
            <h5>COVID Cleaned</h5>
            <p>All aircraft are cleaned to meet CDC standards</p>
          </div>
        </div>
      </div>
    </div>
  );
};


function mapStateToProps({aircraft}) {
  return {
    searchOptions: aircraft.searchOptions,
    searchResults: aircraft.searchResults,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    searchAircraft: data => dispatch({type: SEARCH_AIRCRAFT, payload: data}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Searching)
