import React, {useEffect, useState} from 'react';
import {Auth} from "aws-amplify";

function useAuthentication() {
  const [authenticated, setAuthenticated] = useState({
    status: false,
    token: null,
  });

  const fetchData = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser()
      setAuthenticated({status: true, token: user.signInUserSession.accessToken.jwtToken});
    } catch (e) {
      setAuthenticated({
        status: false,
        token: null,
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return authenticated;
}

export default useAuthentication;


export const getIsUaAuthentication = async () => {
  try {
    let user = await Auth.currentAuthenticatedUser();
    return {status: true, token: user.signInUserSession.accessToken.jwtToken, user};
  } catch {
    return {status: false, token: null};
  }
}