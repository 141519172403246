import React,{useState} from 'react';
import SearchFlightForm from "../HomePage/SearchFlightForm";
import {useHistory} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import './PopularTripBooking.scss';

const PopularTripBooking = () => {
    const history = useHistory();
    const [loader, setLoader] = useState(true);
    return(
        <div className="PopularTripBooking">
            <SearchFlightForm history={history} loading={setLoader}/>
            <LoadingOverlay active={loader} spinner text='Loading...'/>
        </div>
    )
}

export default PopularTripBooking;