import {
  QUOTE_MODAL_OPEN,
  LOAD_OPERATOR_THEME,
  QUOTE_SUCCESS,
  QUOTE_RESET,
  QUOTE_SET
} from "../constants/widget.constant";

const initialState = {
  modalOpen: false,
  flightdata: {},
  theme: {},
  operatorId: "",
  quoteSuccess: false,
};

export default (state = initialState, action) => {
  //console.log(action)
  switch (action.type) {
    case QUOTE_MODAL_OPEN: {
      return {
        ...state,
        modalOpen: action.modalOpen,
        flightdata: action.flightdata,
      };
    }
    case LOAD_OPERATOR_THEME: {
      return { ...state, theme: action.theme, operatorId: action.operatorId };
    }
    case QUOTE_SUCCESS: {
      return { ...state, modalOpen: action.modalOpen, quoteSuccess: true };
    }
    case QUOTE_RESET: {
      return { ...state, quoteSuccess: false };
    }
    case QUOTE_SET: {
      return { ...state, quoteSuccess: true };
    }
    default: {
      return state;
    }
  }
};
