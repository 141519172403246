import React from 'react';
import PropTypes from "prop-types";
import "./FlightAddOns.scss";
import FlightAddOnsList from "./FlightAddOnsList";

function FlightAddOns({header, getRefresh, addonList, type, checkable = true, onChange}) {
  return (
    <div className="FlightAddOns">
      <div className="head">{header}</div>
      <div className="body-AddOns">
        {addonList.map((a, i) =>  <FlightAddOnsList type={type} onChange={onChange} getRefresh={getRefresh} checkable={checkable} {...a} key={i}/>)}
      </div>
    </div>
  );
}

FlightAddOns.propTypes = {
  label: PropTypes.string,
  addons: PropTypes.array,
  checkable: PropTypes.bool,
  onchange: PropTypes.func,
}

export default FlightAddOns;