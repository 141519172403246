import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {FaArrowRight} from 'react-icons/fa';
import ChangePasswordModal from '../../components/ChangePasswordModal';
import Input from "react-phone-number-input/input";
import LoadingOverlay from "react-loading-overlay";
import Button from "../../components/Button";
import {API_PROFILE} from '../../middleware/constants/url.constants'
import { GET_AUTH, POST_AUTH } from '../../utils/HTTP.utils';
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import { toast } from "react-toastify";

function PersonalInfo({ }) {
  const [state, setState] = useState({ firstname: "", lastname: "", phone: "", email: "" });
  const [amplify,setAmplify] =useState({})
  const [changeModal, setChangeModal] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getIsUaAuthentication().then(({token})=>{
      GET_AUTH(API_PROFILE,token).then((data)=>{
        setState(data)
      })
    })
    Auth.currentAuthenticatedUser().then((a) => {
      setAmplify(a.attributes)
    });
  }, []);

  const userHandleChange = key => e => {
      setState({ ...state, [key]: e.target.value.trim() });
  }

  const handlePhone = e => {
    setState({ ...state, phone: e });
  }

  const onSubmit = e => {
    setLoader(true);
    e.preventDefault();
    let {firstname,lastname,phone,email} = state
    getIsUaAuthentication().then(({token})=>{
      POST_AUTH(API_PROFILE,{
        firstname,
        lastname,
        email,
        phone
      },token);
    }).then((data)=>{
      toast.success("Changes Saved Successfully")
    })
    setLoader(false);
  }

  // const updateUserInfo = async (payload) => {
  //   const user = await Auth.currentAuthenticatedUser();
  //     let update = await Auth.updateUserAttributes(user, {
  //       ...payload,
  //       phone_number: payload.phone_number,
        
  //     });
  //   setLoader(false);
  //     toast.success("Changes were saved successfully.")
  // }
  
  return (
    <div className="PersonalInfo">
      <h2>Personal Info</h2>
      <form onSubmit={onSubmit}>
        <div className="personal-info-inputs">
          <div className="key-value">
            <p className="key">First Name</p>
            <input
              type="text"
              required
              className="full-width-container"
              onChange={userHandleChange("firstname")}
              placeholder="Enter First Name"
              value={state.firstname ?? ""}
            />
          </div>
          <div className="key-value">
            <p className="key">Last Name</p>
            <input
              type="text"
              required
              className="full-width-container"
              onChange={userHandleChange("lastname")}
              placeholder="Enter Last Name"
              value={state.lastname ?? ""}
            />
          </div>
          <div className="key-value">
            <p className="key">Email</p>
            <p className="value">{state.email ?? ""}</p>
          </div>
          <div className="key-value">
            <p className="key">Phone Number</p>
            <Input
              required
              defaultCountry="US"
              className="phone-input"
              placeholder="Enter Phone Number"
              value={state.phone ?? ""}
              onChange={handlePhone}
            />
          </div>
        </div>
        {!amplify.identities && (
          <div>
            <div
              className="link-key-value"
              onClick={() => setChangeModal(true)}
            >
              <p className="title">Change Password</p>
              <FaArrowRight />
            </div>
            <ChangePasswordModal
              status={changeModal}
              email={state.email}
              onChange={setChangeModal}
            />
          </div>
        )}
        <Button label={"Save"} type={"yellow"} btnAction={"submit"} />
      </form>
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
    </div>
  );
}


PersonalInfo.propTypes = {};

export default PersonalInfo;
