import * as UserConstants from "./user.constant";
import * as TripConstants from "./trip.constants";
import * as AircraftConstants from "./aircarft.constants";
import * as PaymentConstants from "./payment.constants";
import * as WidgetConstants from "./widget.constant";


export default {
  ...UserConstants,
  ...TripConstants,
  ...AircraftConstants,
  ...PaymentConstants,
  ...WidgetConstants
}
