import React from "react";
import {getSessionID} from "../../utils/session.storage.utils";
import moment from "moment";
import {ONE_WAY, ROUND_TRIP} from "../../middleware/constants/trip.constants";

export const getFinalAPIDATA = (state, departureAirport, arrivalAirport, route) => {
  return {
    "routeType": route,
    "travelCities": [
      {
        "origin": departureAirport.icaoCode,
        "destination": arrivalAirport.icaoCode,
        "departureTime": moment(state.departureTime).format("YYYY-MM-DD HH:mm"),
        "returnTime": moment(state.returnTime).format("YYYY-MM-DD HH:mm"),
        "order": "0"
      }
    ],
    "flyShare": false,
    "passengers": state.passengers,
    "petFriendly": state.petFriendly,
    "sessionId": getSessionID(),
    departureAirport,
    arrivalAirport,
    departureTime: state.departureTime,
    returnTime: state.returnTime,
  }
}

export const getFinalAPIDataMultiLeg = (state, multiLegData, route) => {
  let travelCities = [];
  for (let i = 0; i < multiLegData.stops.length; i++) {
    if (i === 0) {
      travelCities.push({
        "origin": multiLegData.firstStop.value.icaoCode,
        "destination": multiLegData.stops[i].value.icaoCode,
        "departureTime": moment(multiLegData.firstStop.date).format("YYYY-MM-DD HH:mm"),
        "returnTime": "",
        "order": "0"
      })
    } else {
      travelCities.push({
        "origin": multiLegData.stops[i - 1].value.icaoCode,
        "destination": multiLegData.stops[i].value.icaoCode,
        "departureTime": moment(multiLegData.stops[i - 1].date).format("YYYY-MM-DD HH:mm"),
        "returnTime": "",
        "order": i.toString()
      })
    }
  }

  travelCities.push({
    "origin": multiLegData.stops[multiLegData.stops.length - 1].value.icaoCode,
    "destination": multiLegData.lastStop.value.icaoCode,
    "departureTime": moment(multiLegData.stops[multiLegData.stops.length - 1].date).format("YYYY-MM-DD HH:mm"),
    "returnTime": "",
    "order": multiLegData.stops.length.toString()
  })
  return {
    "routeType": route,
    "flyShare": false,
    travelCities: travelCities,
    multiLegData,
    "passengers": state.passengers,
    "petFriendly": state.petFriendly,
    "sessionId": getSessionID(),
  }
}

export const validateMultiLegData = (status, multiLegData) => {
  if (status.passengers &&
    Object.keys(multiLegData.firstStop.value).length > 0 &&
    multiLegData.firstStop.date &&
    Object.keys(multiLegData.lastStop.value).length > 0
  ) {
    for (let i of multiLegData.stops) {
      if (
        Object.keys(i.value).length === 0 ||
        !i.date
      ) {
        throw "Missing information in 2nd leg."
      }
    }
    return true;
  } else {
    throw "Missing information in 1st or last leg."
  }
}

export const validateReturnOneWay = (status, state, departureAirport, arrivalAirport) => {
  if (state.passengers
  ) {
    if (Object.keys(departureAirport).length > 0 &&
      Object.keys(arrivalAirport).length > 0 &&
      state.departureTime &&
      departureAirport.icaoCode !== arrivalAirport.icaoCode) {
      if (status === ROUND_TRIP && state.returnTime && moment(state.departureTime).isBefore(moment(state.returnTime))) {
        return true
      } else if (status === ONE_WAY) {
        return true;
      } else {
        if (!moment(state.departureTime).isBefore(moment(state.returnTime))) {
          throw "Error: Return Date should be grater than Depart Date."
        } else {
          throw "Error: Dates are not matching."
        }
      }
    } else {
      if (Object.keys(departureAirport).length === 0) {
        throw "Error: Please add Departure Airport!"
      } else if (Object.keys(arrivalAirport).length === 0) {
        throw "Error: Please add Arrival Airport!"
      } else if (departureAirport.icaoCode === arrivalAirport.icaoCode) {
        throw "Error: Departure Airport cant be same as Arrival Airport!"
      } else {
        throw "Please type all information."
      }
    }
  } else {
    throw "Please Add Passenger count."
  }
}

export const getSuggestionTile = (a = {}) => {
  return <div className={"suggestion-title"}>
    <div className="code">{a.icaoCode}</div>
    <div className="info">
      <div className="title">{a.airportName}</div>
      <div className="sub-title">{a.city}{a.city!=="\t" && ", "}{a.countryCode}</div>
    </div>
  </div>
}

export function removeItemFromArray(arr, index) {
  return arr.filter((a, i) => i !== index)
}