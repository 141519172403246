import React, { useState, useEffect } from "react";
import "./EmergencyContactModal.scss";
import Modal from "../Modal";
import Button from "../Button";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import { GET_AUTH } from "../../utils/HTTP.utils";
import { API_GET_ADD_EMERGENCY_CONTACT } from "../../middleware/constants/url.constants";
import LoadingOverlay from 'react-loading-overlay';

function EmergencyContactModal({
  status,
  onChange,
  onNewEmergencyContact,
  bookingId

}) {

  const [loader,setLoader] = useState(false)
  const [state, setState] = useState({
    fname: "",
    lname: "",
    phoneNumber: ""
  });

  useEffect(() => {
    if(status){
      setLoader(true)
    } 
    getIsUaAuthentication().then(({token})=>{
      GET_AUTH(API_GET_ADD_EMERGENCY_CONTACT,token,{"bookingId":bookingId}).then((data)=>{
        if(data.fname!==null){
          setState(data)
        }
        setLoader(false)
      })
    })
  },[])

  const updateEmergencyContact = async () => {
    try {
      onNewEmergencyContact && onNewEmergencyContact(state);
      setState({
        fname: "",
        lname: "",
        phoneNumber: "",
      });
    } catch (e) {
      console.error(e);
    }
    setState({
      fname: "",
      lname: "",
      phoneNumber: "",
    });
    onChange(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await updateEmergencyContact();
  };


  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };

  return (
    <div className="EmergencyContactModal">
      <Modal close={() => onChange(false)} show={status}>
        <p className="title">Emergency Contact</p>
        <form onSubmit={onSubmit}>
          <div className="input-container">
            <input
              type="text"
              required
              value={state.fname}
              className="full-width-container"
              onChange={handleChange("fname")}
              placeholder={"First Name *"}
            />
            <input
              type="text"
              required
              value={state.lname}
              className="full-width-container"
              onChange={handleChange("lname")}
              placeholder={"Last Name *"}
            />
            <input
              type="number"
              required
              value={state.phoneNumber}
              className="full-width-container"
              onChange={handleChange("phoneNumber")}
              placeholder={"Phone Number *"}
            />
          </div>
          <Button
            label={"Save Changes"}
            type={"yellow"}
            btnAction={"submit"}
          />
        </form>
      </Modal>
      <LoadingOverlay active={loader} spinner text='Loading your content...'/>
    </div>
  );
}

export default EmergencyContactModal;
