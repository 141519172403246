import React from 'react';
import GenTempImageView from '../../components/GenTempImageView';
import SunsetPlane from '../../assets/sunset-plane.jpg';
import DownloadRef from '../../components/DownloadRef';
import SunsetPlaneMobile from '../../assets/sunset-plane-mobile.png';
import Interior1 from '../../assets/interior-1.jpg';
import Interior2 from '../../assets/interior-2.jpg';
import PlaneFront from '../../assets/plane-front.jpg';
import blueTick from '../../assets/icons/blue-checkmark.svg';

import './Marketplace.scss';

const Marketplace = () => {
    const imgViewData = {
        img1: Interior1,
        img2: PlaneFront,
        title1: 'Quality Aircraft',
        title2: 'The Marketplace',
        subtitle1:
          'AIRBOOK ONE uses Avinode’s open marketplace, and other similar type aircraft marketplaces, to source all aircraft, with approximately 5,000 aircraft being vetted at any given moment.',
        subtitle2:
          'AIRBOOK ONE takes market variables, such as manufacture dates (minimums), refurbished date (minimums), nautical trip miles, daily fuel costs, plane positioning, flight crew overnights, etc., into consideration to offer aircraft that are highly vetted in safety, with the highest quality operators and crews, delivering the best market price at the time of the search.',
      };
    const marketplacedata = [
        'All aircraft are locally sourced, thus minimizing, and in many cases, eliminating the cost of plane repositioning',
        'All aircraft, operators and crews are safety vetted by either ARG/US or Wyvern',
        'All aircraft are new or newly refurbished',
        'Empty legs are sourced on behalf of customers for any one-way requests',
        'All flight prices are 100% guaranteed at the point of purchase'
    ]
    return(
        <div className="root-container">
            <div className="Marketplace">
                <div className="Top-Section">
                    <div className="Left-Section">
                        <h1>Aircraft Marketplace</h1>
                        {marketplacedata.map(point => {
                            return(
                                <div className="search-list">
                                    <img src={blueTick}/>
                                    <p>{point}</p>
                                </div>
                            )
                        })}
                    </div>
                    <img className="right-img" src={SunsetPlane} alt=""/>
                </div>
                <div className="Top-Section-mobile">
                    <h1>Aircraft Marketplace</h1>
                    <img className="main-img" src={SunsetPlaneMobile} alt="Aircraft Marketplace"/>
                    {marketplacedata.map(point => {
                            return(
                                <div className="search-list">
                                    <img src={blueTick}/>
                                    <p>{point}</p>
                                </div>
                            )
                        })}
                </div>
                <GenTempImageView {...imgViewData} />
                <div className='gen-bottom'>
                    <div className='gen-bottom-img'>
                        <img src={Interior2} alt='Seamless Experience'/>
                    </div>
                    <div className='gen-bottom-content'>
                        <h3>Seamless Experience </h3>
                    <p>
                    AIRBOOK ONE’s proprietary technology delivers an effortless experience without unnecessary steps, so you can make the most of your valuable time and money. We eliminate the barriers to where you want to be.
                    </p>
                    {/* <button>Learn more</button> */}
                    </div>
                </div>
                <DownloadRef/>
            </div>
        </div> 
    )
}

export default Marketplace;