import React from 'react';
import {FaToiletPaper, FaWifi} from "react-icons/fa";
import {MdPets} from "react-icons/md";
import {GrRestroomWomen} from "react-icons/gr";

import "./PlanInfo.scss";

function PlanInfo({info}) {
  return (
    <div className="PlanInfo">
      <div className="icon-info-container">
        <div className="num-icon">{info.maxPassengers}</div>
        <p className="text">Capacity</p>
        <div className="num-icon ml">{info.maxLuggages}</div>
        <p className="text">Luggage</p>
      </div>
      <div className="text-container">{info.description}</div>
      {info.flightAttendant && <div className="facilities-container">
        <GrRestroomWomen size={23}/> <p>Flight Attendant</p>
      </div>}
      {info.wifi && <div className="facilities-container">
        <FaWifi size={23}/> <p>{info.wifi}</p>
      </div>}
      {info.restroom && <div className="facilities-container">
        <FaToiletPaper size={23}/> <p>{info.restroom}</p>
      </div>}
      {info.petFriendly && <div className="facilities-container">
        <MdPets size={23}/> <p>{info.petsAllowed}</p>
      </div>}
    </div>
  );
}

export default PlanInfo;