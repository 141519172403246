export default [
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Atlanta",
      "arrivalICAO": "KATL"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "West Palm Beach",
      "deptICAO": "KPBI",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Dallas",
      "deptICAO": "KDAL",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Las Vegas",
      "deptICAO": "KLAS",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Phoenix",
      "arrivalICAO": "KPHX"
    },
    {
      "deptCity": "Nashville",
      "deptICAO": "KBNA",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Miami",
      "arrivalICAO": "KMIA"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "St. Louis",
      "arrivalICAO": "KSTL"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Minneapolis",
      "arrivalICAO": "KMSP"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Dallas",
      "arrivalICAO": "KDAL"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Houston",
      "arrivalICAO": "KHOU"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Phoenix",
      "arrivalICAO": "KPHX"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Seattle",
      "arrivalICAO": "KSEA"
    },
    {
      "deptCity": "Teterboro",
      "deptICAO": "KTEB",
      "arrivalCity": "Portland",
      "arrivalICAO": "KPDX"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Miami",
      "arrivalICAO": "KMIA"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Atlanta",
      "arrivalICAO": "KATL"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "St. Louis",
      "arrivalICAO": "KSTL"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Minneapolis",
      "arrivalICAO": "KMSP"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Dallas",
      "arrivalICAO": "KDAL"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Houston",
      "arrivalICAO": "KHOU"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Las Vegas",
      "arrivalICAO": "KLAS"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Phoenix",
      "arrivalICAO": "KPHX"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Seattle",
      "arrivalICAO": "KSEA"
    },
    {
      "deptCity": "Boston ",
      "deptICAO": "KBOS",
      "arrivalCity": "Portland",
      "arrivalICAO": "KPDX"
    },
    {
      "deptCity": "Pittsburgh",
      "deptICAO": "KPIT",
      "arrivalCity": "Miami",
      "arrivalICAO": "KMIA"
    },
    {
      "deptCity": "Pittsburgh",
      "deptICAO": "KPIT",
      "arrivalCity": "Tampa",
      "arrivalICAO": "KTPA"
    },
    {
      "deptCity": "Pittsburgh",
      "deptICAO": "KPIT",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Pittsburgh",
      "deptICAO": "KPIT",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Pittsburgh",
      "deptICAO": "KPIT",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Pittsburgh",
      "deptICAO": "KPIT",
      "arrivalCity": "Atlanta",
      "arrivalICAO": "KATL"
    },
    {
      "deptCity": "Pittsburgh",
      "deptICAO": "KPIT",
      "arrivalCity": "Phoenix",
      "arrivalICAO": "KPHX"
    },
    {
      "deptCity": "Pittsburgh",
      "deptICAO": "KPIT",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "West Palm Beach",
      "deptICAO": "KPBI",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "West Palm Beach",
      "deptICAO": "KPBI",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "West Palm Beach",
      "deptICAO": "KPBI",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "West Palm Beach",
      "deptICAO": "KPBI",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Miami",
      "deptICAO": "KMIA",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Miami",
      "deptICAO": "KMIA",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Miami",
      "deptICAO": "KMIA",
      "arrivalCity": "St. Louis ",
      "arrivalICAO": "KSTL"
    },
    {
      "deptCity": "Miami",
      "deptICAO": "KMIA",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Miami",
      "deptICAO": "KMIA",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Pittsburgh",
      "arrivalICAO": "KPIT"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Phildelphia",
      "arrivalICAO": "KPHL"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Denver",
      "arrivalICAO": "KDEN"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Dallas",
      "arrivalICAO": "KDAL"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Atlanta",
      "deptICAO": "KATL",
      "arrivalCity": "Las Vegas",
      "arrivalICAO": "KLAS"
    },
    {
      "deptCity": "Cleveland",
      "deptICAO": "KCLE",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Cleveland",
      "deptICAO": "KCLE",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Cleveland",
      "deptICAO": "KCLE",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Cleveland",
      "deptICAO": "KCLE",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Cleveland",
      "deptICAO": "KCLE",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Detroit",
      "deptICAO": "KDET",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Detroit",
      "deptICAO": "KDET",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Detroit",
      "deptICAO": "KDET",
      "arrivalCity": "Las Vegas",
      "arrivalICAO": "KLAS"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Tampa ",
      "arrivalICAO": "KTPA"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Ft. Meyers",
      "arrivalICAO": "KRSW"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Atlanta",
      "arrivalICAO": "KATL"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Dallas",
      "arrivalICAO": "KDAL"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Houston",
      "arrivalICAO": "KHOU"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Las Vegas",
      "arrivalICAO": "KLAS"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Scottsdale ",
      "arrivalICAO": "KSDL"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Chicago",
      "deptICAO": "KMDW",
      "arrivalCity": "Portland",
      "arrivalICAO": "KPDX"
    },
    {
      "deptCity": "Minneapolis",
      "deptICAO": "KMSP",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Minneapolis",
      "deptICAO": "KMSP",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Minneapolis",
      "deptICAO": "KMSP",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Minneapolis",
      "deptICAO": "KMSP",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Minneapolis",
      "deptICAO": "KMSP",
      "arrivalCity": "Nashville",
      "arrivalICAO": "KBNA"
    },
    {
      "deptCity": "Minneapolis",
      "deptICAO": "KMSP",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Austin",
      "deptICAO": "KAUS",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Austin",
      "deptICAO": "KAUS",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "Austin",
      "deptICAO": "KAUS",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Austin",
      "deptICAO": "KAUS",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Austin",
      "deptICAO": "KAUS",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Austin",
      "deptICAO": "KAUS",
      "arrivalCity": "Portland",
      "arrivalICAO": "KPDX"
    },
    {
      "deptCity": "Dallas",
      "deptICAO": "KDAL",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Dallas",
      "deptICAO": "KDAL",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Dallas",
      "deptICAO": "KDAL",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "Dallas",
      "deptICAO": "KDAL",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Dallas",
      "deptICAO": "KDAL",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Dallas",
      "deptICAO": "KDAL",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Houston",
      "deptICAO": "KHOU",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Denver",
      "deptICAO": "KAPA",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Denver",
      "deptICAO": "KAPA",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Denver",
      "deptICAO": "KAPA",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "Denver",
      "deptICAO": "KAPA",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Denver",
      "deptICAO": "KAPA",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Denver",
      "deptICAO": "KAPA",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Denver",
      "deptICAO": "KAPA",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Denver",
      "deptICAO": "KAPA",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Phoenix",
      "deptICAO": "KPHX",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Phoenix",
      "deptICAO": "KPHX",
      "arrivalCity": "Las Vegas",
      "arrivalICAO": "KLAS"
    },
    {
      "deptCity": "Phoenix",
      "deptICAO": "KPHX",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Las Vegas",
      "deptICAO": "KLAS",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Las Vegas",
      "deptICAO": "KLAS",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "Las Vegas",
      "deptICAO": "KLAS",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Las Vegas",
      "deptICAO": "KLAS",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Las Vegas",
      "deptICAO": "KLAS",
      "arrivalCity": "Portland",
      "arrivalICAO": "KPDX"
    },
    {
      "deptCity": "Las Vegas",
      "deptICAO": "KLAS",
      "arrivalCity": "Phoenix",
      "arrivalICAO": "KPHX"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Atlanta",
      "arrivalICAO": "KATL"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "St. Louis",
      "arrivalICAO": "KSTL"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Minneapolis",
      "arrivalICAO": "KMSP"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Dallas",
      "arrivalICAO": "KDAL"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Houston",
      "arrivalICAO": "KHOU"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Las Vegas",
      "arrivalICAO": "KLAS"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Seattle",
      "arrivalICAO": "KSEA"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Portland",
      "arrivalICAO": "KPDX"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "Phoenix",
      "arrivalICAO": "KPHX"
    },
    {
      "deptCity": "Los Angeles",
      "deptICAO": "KVNY",
      "arrivalCity": "New Orleans",
      "arrivalICAO": "KNEW"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Atlanta",
      "arrivalICAO": "KATL"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "St. Louis",
      "arrivalICAO": "KSTL"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Minneapolis",
      "arrivalICAO": "KMSP"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Dallas",
      "arrivalICAO": "KDAL"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Houston",
      "arrivalICAO": "KHOU"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Denver",
      "arrivalICAO": "KAPA"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Las Vegas",
      "arrivalICAO": "KLAS"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Phoenix",
      "arrivalICAO": "KPHX"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Seattle",
      "arrivalICAO": "KSEA"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Portland",
      "arrivalICAO": "KPDX"
    },
    {
      "deptCity": "San Francisco",
      "deptICAO": "KSFO",
      "arrivalCity": "Phoenix",
      "arrivalICAO": "KPHX"
    },
    {
      "deptCity": "Portland",
      "deptICAO": "KPDX",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Portland",
      "deptICAO": "KPDX",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Portland",
      "deptICAO": "KPDX",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "Portland",
      "deptICAO": "KPDX",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Portland",
      "deptICAO": "KPDX",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Portland",
      "deptICAO": "KPDX",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Portland",
      "deptICAO": "KPDX",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Portland",
      "deptICAO": "KPDX",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Seattle",
      "deptICAO": "KSEA",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Seattle",
      "deptICAO": "KSEA",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Seattle",
      "deptICAO": "KSEA",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "Seattle",
      "deptICAO": "KSEA",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Seattle",
      "deptICAO": "KSEA",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Seattle",
      "deptICAO": "KSEA",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Seattle",
      "deptICAO": "KSEA",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Seattle",
      "deptICAO": "KSEA",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Nashville",
      "deptICAO": "KBNA",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Nashville",
      "deptICAO": "KBNA",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Nashville",
      "deptICAO": "KBNA",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "Nashville",
      "deptICAO": "KBNA",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Nashville",
      "deptICAO": "KBNA",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Nashville",
      "deptICAO": "KBNA",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Nashville",
      "deptICAO": "KBNA",
      "arrivalCity": "Austin",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Scottsdale",
      "deptICAO": "KSDL",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KMDW"
    },
    {
      "deptCity": "Scottsdale",
      "deptICAO": "KSDL",
      "arrivalCity": "Los Angeles",
      "arrivalICAO": "KVNY"
    },
    {
      "deptCity": "Scottsdale",
      "deptICAO": "KSDL",
      "arrivalCity": "Las Vegas",
      "arrivalICAO": "KLAS"
    },
    {
      "deptCity": "Scottsdale",
      "deptICAO": "KSDL",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Scottsdale",
      "deptICAO": "KSDL",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "San Diego ",
      "deptICAO": "KSAN",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "San Diego ",
      "deptICAO": "KSAN",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "San Diego ",
      "deptICAO": "KSAN",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "San Diego ",
      "deptICAO": "KSAN",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "San Diego ",
      "deptICAO": "KSAN",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "San Diego ",
      "deptICAO": "KSAN",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "San Diego ",
      "deptICAO": "KSAN",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    },
    {
      "deptCity": "Salt Lake City",
      "deptICAO": "KSLC",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Salt Lake City",
      "deptICAO": "KSLC",
      "arrivalCity": "Washington DC",
      "arrivalICAO": "KIAD"
    },
    {
      "deptCity": "Salt Lake City",
      "deptICAO": "KSLC",
      "arrivalCity": "Boston",
      "arrivalICAO": "KBOS"
    },
    {
      "deptCity": "Salt Lake City",
      "deptICAO": "KSLC",
      "arrivalCity": "Chicago",
      "arrivalICAO": "KAUS"
    },
    {
      "deptCity": "Salt Lake City",
      "deptICAO": "KSLC",
      "arrivalCity": "San Francisco",
      "arrivalICAO": "KSFO"
    },
    {
      "deptCity": "Salt Lake City",
      "deptICAO": "KSLC",
      "arrivalCity": "Teterboro",
      "arrivalICAO": "KTEB"
    },
    {
      "deptCity": "Salt Lake City",
      "deptICAO": "KSLC",
      "arrivalCity": "Orlando",
      "arrivalICAO": "KMCO"
    }
   ]