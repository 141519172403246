import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Popover} from 'react-tiny-popover'
import CheckBox from "../CheckBox/CheckBox";
import {useHistory} from "react-router-dom";
import LimoLinkModal from "../LimoLinkModal";

function FlightAddOnsList({selected, displayName, getRefresh, addonAction, requested, url, price, type, info, logo, onChange, addOnId, checkable}) {
  let history = useHistory();
  let [isPopoverOpen, setIsPopoverOpen] = useState(false);
  let [modal, setModal] = useState(false);
  return (
    <div className="addons">
      <CheckBox checkable={checkable} checked={selected} onClick={value => checkable && onChange(addOnId, value)}
                color={"blue"}/>
      {logo && <div className="logo">
        <img width={50} src={logo.webLogo} alt="displayName"/>
      </div>}
      <div className="details">
        <p className="title">{displayName}</p>
        {price !== 0 && <p className="price">${price?.toLocaleString()}</p>}
      </div>
      {type==="RIDE_SHARE" && history.location.pathname.includes("/booking-details")
      ?
      (addonAction==="FORM")?
        (requested)?
          <div className="link requested">
            Requested
          </div>
        :
          <div className="link" onClick={() => setModal(true)}>
            Order Now
          </div>
        :
        <a target="_blank" className="link" href={url}>
          <div className="link">
            Order Now
          </div>
        </a>
      :
        <Popover
          isOpen={isPopoverOpen}
          positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
          content={<div className="popup-info">{info}</div>}
          onClickOutside={() => setIsPopoverOpen(false)}
        >
          <div className="link" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
            Info
          </div>
        </Popover>
      }
      <LimoLinkModal
        status={modal}
        onChange={setModal}
        getRefresh={getRefresh}
      />
    </div>
  );
}

FlightAddOnsList.propTypes = {
  selected: PropTypes.bool,
  displayName: PropTypes.string,
  price: PropTypes.any,
  info: PropTypes.string,
  checkable: PropTypes.bool,
  onChange: PropTypes.func,
}

export default FlightAddOnsList;