import React from 'react';
import LearnMore from '../../components/LearnMore';
import GenTempImageView from '../../components/GenTempImageView';
import PageHeadline from '../../assets/page-headline.jpg';
import ViewMore from '../../assets/view-more.jpg';
import GenImg1 from '../../assets/general-template1.jpg';
import GenImg2 from '../../assets/general-template2.png';
import './GeneralTemplateDark.scss';

const GeneralTemplateDark = () => {
  const imgViewData = {
    img1: GenImg1,
    img2: GenImg2,
    title1: 'Headline',
    title2: 'Headline',
    subtitle1:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    subtitle2:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    link1: '#',
    link2: '#',
  };
  return (
    <div className='root-section'>
      <LearnMore title='Page headline.' img={PageHeadline} arrowDir='down'/>
      <div className='sub-content'>
        <h3>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </h3>
      </div>
      <GenTempImageView {...imgViewData} />
      <LearnMore title='Link can go here.' img={ViewMore} arrowDir='right'/>
    </div>
  );
};

export default GeneralTemplateDark;
