import React, {useEffect, useState} from 'react';
import "./EditAirport.scss";
import Button from "../../components/Button";
import {FaAngleLeft, FaCircle} from "react-icons/fa";
import {connect} from "react-redux";
import GoogleMapReact from 'google-map-react';
import {BiCircle} from "react-icons/bi";
import {RiMapPinFill} from "react-icons/ri";
import AirportMarker from "./AirportMarker";
import {GET_UN_AUTH} from "../../utils/HTTP.utils";
import {API_SEARCH_AIRPORT} from "../../middleware/constants/url.constants";
import PageContainer from "../../components/PageContainer";
import {getSearchDataFromState} from "./helper";
import {RESET_SEARCH_RESULTS, SET_SEARCH_OPTIONS} from "../../middleware/constants/aircarft.constants";
import LoadingOverlay from 'react-loading-overlay';

function EditAirport({
                       history,
                       searchOptions,
                       setFilterOptions,
                       resetSearchResults
                     }) {
  let {data, legOrder} = history?.location?.state ?? {};

  useEffect(() => {
    if (!data) history.push("/");
  }, []);
  const [state, setState] = useState({...data[legOrder], selected: "arrivalAirport"});
  const [airportsSuggestions, setAirportsSuggestions] = useState([]);
  const [loader, setLoader] = useState(true)
  const [bounds, setBounds] = useState({topLeftLatLong: "", bottomRightLatLong: ""});
  const fetchAirports = async () => {
    let res = await GET_UN_AUTH(API_SEARCH_AIRPORT, {...bounds});
    setAirportsSuggestions(res)
    setLoader(false);
  }
  useEffect(() => {
    if (bounds.bottomRightLatLong && bounds.bottomRightLatLong) fetchAirports();
  }, [bounds.topLeftLatLong, bounds.bottomRightLatLong])

  useEffect(() => {
    if (bounds.bottomRightLatLong && bounds.topLeftLatLong) fetchAirports();
  }, [state.selected])

  const onLegAirportChange = (airport, newAirport) => () => setState({...state, selected: newAirport})
  const onAirportSelectInMap = value => {
    state[state.selected] = value
    setState({...state});
  }
  const onAirportSelect = airport => () => {
    state[state.selected] = airport
    setState({...state});
  };
  const onChangeAirport = () => {
    data[legOrder] = state;
    let final = getSearchDataFromState(data, searchOptions);
    setFilterOptions(final);
    resetSearchResults({});
    history.push("/searching");
  }
  return (
    <PageContainer>
      <div className="EditAirport" style={{height: (window.innerHeight - 100)}}>
        <div className="info-container">
          <div className="leg-container">
            <h1>Leg {legOrder + 1}</h1>
            <div
              onClick={onLegAirportChange(state.arrivalAirport, "arrivalAirport")}
              className={`airport-container ${state.selected === "arrivalAirport" && "active"}`}>
              <BiCircle className="depart"/> <p className="code">{state.arrivalAirport?.icaoCode}</p>
              <FaCircle className={"dot"} size={10}/>
              <p className="city">{state.arrivalAirport?.city}, {state.arrivalAirport?.country}</p>
            </div>
            <div
              onClick={onLegAirportChange(state.departureAirport, "departureAirport")}
              className={`airport-container ${state.selected === "departureAirport" && "active"}`}>
              <RiMapPinFill className="depart"/>
              <p className="code">{state.departureAirport?.icaoCode}</p>
              <FaCircle className={"dot"} size={10}/>
              <p className="city">{state.departureAirport?.city}, {state.departureAirport?.country}</p>
            </div>
            <h1>Airports</h1>
            <div className="suggested-airport-container">
              {airportsSuggestions.map((a, i) => <div key={i} onClick={onAirportSelect(a)}
                                                      className={`suggested-airport`}>
                <p className="title">{a.airportName}</p>
                <p className="city">{a.city}, {a.countryCode}</p>
              </div>)}

            </div>
          </div>
          <div className="btn-container">
            <Button label={"Back"}
                    onClick={() => history.goBack()}
                    icon={<FaAngleLeft/>}
                    iconPosition={"left"}
                    type={"black"}
                    fullWidth={false}/>
            <Button label={"Change Airport"}
                    onClick={onChangeAirport}
                    type={"yellow"}
                    fullWidth={false}/>
          </div>
        </div>
        <div className="map-container">
          <GoogleMapReact
            onGoogleApiLoaded={({map, maps}) => {
              setBounds({
                topLeftLatLong: `${map.getBounds().getNorthEast().lat()}, ${map.getBounds().getSouthWest().lng()}`,
                bottomRightLatLong: `${map.getBounds().getSouthWest().lat()}, ${map.getBounds().getNorthEast().lng()}`,
              })
            }}
            onChange={({bounds}) => {
              setBounds({
                topLeftLatLong: `${bounds.ne.lat}, ${bounds.sw.lng}`,
                bottomRightLatLong: `${bounds.sw.lat}, ${bounds.ne.lng}`,
              })
            }}
            bootstrapURLKeys={{key: "AIzaSyAa-Osd4aYP4wEbylxYVeljnuNDovSntsk"}}
            defaultCenter={{
              lat: state[state.selected].latitude,
              lng: state[state.selected].longitude,
            }}
            center={{
              lat: state[state.selected].latitude,
              lng: state[state.selected].longitude,
            }}
            defaultZoom={10}
          >
            {airportsSuggestions.map((a, i) => <AirportMarker selected={state[state.selected]}
                                                              onSelect={onAirportSelectInMap}
                                                              key={i}
                                                              data={a}
                                                              lat={a.latitude}
                                                              lng={a.longitude}/>)}
          </GoogleMapReact>
        </div>
      </div>
      <LoadingOverlay active={loader} spinner text='Loading your content...'/>
    </PageContainer>
  );
}


function mapStateToProps({aircraft, payment}) {
  return {
    searchOptions: aircraft.searchOptions,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setFilterOptions: data => dispatch({type: SET_SEARCH_OPTIONS, payload: data}),
    resetSearchResults: data => dispatch({type: RESET_SEARCH_RESULTS, payload: data}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAirport)

