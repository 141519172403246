import React, { useEffect, useState } from "react";
import "./ConciergeModal.scss";
import Modal from "../Modal";
import {
  FaEnvelope,
  FaEnvelopeOpen,
  FaMailchimp,
  FaPhone,
} from "react-icons/fa";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import Mail from "./../../assets/icons/talkToConcierge/mail.png";
import Phone from "./../../assets/icons/talkToConcierge/phone.png";
import Chat from "./../../assets/icons/talkToConcierge/chat.png";

function ConciergeModal({ status, onChange, existing = [] }) {
  useEffect(() => {
    // fetchData();
    // existing.length > 0 && setData([...existing.map(a => a.id)])
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    onChange(false);
  };

  return (
    <div className="ConciergeModal">
      <Modal close={() => onChange(false)} show={status}>
        <div className="ConciergeModalContainer">
          <p className="title">Flight Concierge</p>
          <h4 className="sub-title">
            Help for you 24/7. AIRBOOK ONE is here to help you with any
            questions you may have.
          </h4>
          {/* <ConciergeList
            icon={<img src={Chat} style={{ height: "23px" }} />}
            content={"Live Chat Service"}
            clickAction={() => {
              window.LiveChatWidget.call("maximize");
              onChange(false);
            }}
          /> */}
          <ConciergeList
            icon={<img src={Phone} style={{ height: "23px" }} />}
            content={"312-761-5080"}
            href={"tel:3127615080"}
          />
          <ConciergeList
            icon={<img src={Mail} style={{ height: "23px" }} />}
            content={"flightcon@airbookone.com"}
            href={"mailto:flightcon@airbookone.com"}
          />
        </div>
      </Modal>
    </div>
  );
}

const ConciergeList = ({ icon, content, href = "#", clickAction = null }) => {
  return (
    <div className="ConciergeListContainer">
      <div className="itemLeft">{icon}</div>
      <a
        href={href}
        onClick={() => {
          clickAction && clickAction();
        }}
        className="itemRight"
      >
        {content}
      </a>
    </div>
  );
};

export default ConciergeModal;
