import React from 'react';
import { Image, Page, Text, View, StyleSheet, Font, Link } from '@react-pdf/renderer';
import fontRegular from "../../assets/font/FKGroteskNeue-1.1.1/otf/FKGroteskNeue-Regular.otf"
import fontMedium from "../../assets/font/FKGroteskNeue-1.1.1/otf/FKGroteskNeue-Medium.otf"
import fontBold from "../../assets/font/FKGroteskNeue-1.1.1/otf/FKGroteskNeue-Bold.otf"
import ab1Logo from '../../assets/logos/ab1_logo.png';

Font.register({
    family: 'FKGroteskNeue',
    fonts: [
        { format: "otf", src: fontRegular, fontStyle: 'normal', fontWeight: 'normal' },
        { format: "otf", src: fontMedium, fontStyle: 'normal', fontWeight: 'semibold' },
        { format: "otf", src: fontBold, fontStyle: 'normal', fontWeight: 'bold' }
    ]
}
);

const styles = StyleSheet.create({
    font: {
        fontFamily: "FKGroteskNeue",
        fontWeight: 'normal',
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF'
    },
    headerContainer: {
        backgroundColor: "#000"
    },
    tripHeader: {
        padding: "25px 40px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        // alignItems: "center"
    },
    bookingId: {
        fontSize: 20,
        color: "#FFF",
        fontWeight: 700
    },
    legsHeading: {
        fontSize: 15,
        display: "flex",
        flexDirection: "row"
    },
    legs: {
        color: "#9ca2a9"
    },
    ab1Logo: {
        width: 100,
        height: 60
    },
    basicInfo: {
        margin: "30px auto",
        maxWidth: 968,
        fontSize: 14
    },
    subheading: {
        color: "#000",
        fontSize: 12,
        fontWeight: 600,
        paddingLeft: 20,
        marginBottom: 10
    },
    infoBox: {
        padding: "20px 30px",
        marginBottom: 30,
        backgroundColor: "#d5dce7",
        borderRadius: 12,
    },
    infoBox2: {
        padding: "20px 30px",
        backgroundColor: "#d5dce7",
        borderRadius: 12,
        marginBottom: 50
    },
    row: {
        padding: "5px 0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    rowKey: {
        fontWeight: 600,
        margin: 0,
        color: "#000",
        textDecoration: "none",
        fontSize: 12
    },
    rowText: {
        margin: 0,
        color: "#000",
        textDecoration: "none",
        fontSize: 11
    }

})

function initCamelize(str) {
    const strOrgin = [];
    if (str) {
        const strVal = str.toLowerCase(str);
        const strArray = strVal.split(' ');
        for (let i = 0; i < strArray.length; i++) {
            if (strArray[i]) {
                strOrgin.push(strArray[i].trim());
            }
        }
        return strOrgin
            .map(a => a.trim())
            .map(a => a[0].toUpperCase() + a.substring(1))
            .join("  ")
    } else {
        return 'N/A';
    }

}

const FrontPage = ({ data, lastStop }) => {
    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.font}>
                <View style={styles.headerContainer}>
                    <View style={styles.tripHeader}>
                        <View>
                            <Text style={styles.bookingId}>Trip Itinerary #{data.bookingId}</Text>
                            <View style={styles.legsHeading}>
                                {data.legs?.map((leg, i) => {
                                    return (
                                        <Text key={i} style={styles.legs}>{leg.departureAirport?.icaoCode} -&nbsp;</Text>
                                    )
                                })}
                                <Text style={styles.legs}>{lastStop ?? ""}</Text>
                            </View>
                        </View>
                        <Image style={styles.ab1Logo} src={ab1Logo} />
                    </View>
                </View>
                <View style={styles.basicInfo}>
                    <Text style={styles.subheading}>AIRBOOK ONE Contact</Text>
                    <View style={styles.infoBox}>
                        <View style={styles.row}>
                            <Text style={styles.rowKey}>Phone</Text>
                            <Link style={styles.rowText} href={"tel:" + data.ab1Contact?.phone}>{data.ab1Contact?.phone}</Link>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.rowKey}>Email</Text>
                            <Link style={styles.rowText} href={"mailto:" + data?.ab1Contact?.email}>{data?.ab1Contact?.email}</Link>
                        </View>
                    </View>
                    <Text style={styles.subheading}>Customer Information</Text>
                    <View style={styles.infoBox2}>
                        <View style={styles.row}>
                            <Text style={styles.rowKey}>Name</Text>
                            <Text style={styles.rowText}>{initCamelize(data.customerContact?.name)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.rowKey}>Phone</Text>
                            <Link style={styles.rowText} href={"tel:" + data.customerContact?.phone}>{data.customerContact?.phone || "N/A"}</Link>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.rowKey}>Email</Text>
                            <Link style={styles.rowText} href={"mailto:" + data.customerContact?.email}>{data.customerContact?.email || "N/A"}</Link>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    )
}

export default FrontPage