import React from 'react';
import PlaneIcon from '../../assets/icons/planeicon.png';
import ArrowDown from '../../assets/icons/arrow-down.png';
import {useHistory} from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import moreRoutes from './data.js'
import "./PopularTrips.scss"

const PopularTrips = () => {
    const history = useHistory();
    let routes = [
        {Destination:"Los Angeles to Teterboro", deptICAO:"KVNY", arrivalICAO:"KTEB"},
        {Destination:"Portland to Los Angeles", deptICAO:"KPDX", arrivalICAO:"KVNY"},
        {Destination:"Teterboro to Las Vegas", deptICAO:"KTEB", arrivalICAO:"KLAS"},
        {Destination:"Atlanta to Chicago", deptICAO:"KATL", arrivalICAO:"KMDW"},
        {Destination:"Nashville to Chicago", deptICAO:"KBNA", arrivalICAO:"KMDW"},
        {Destination:"Teterboro to Washington DC", deptICAO:"KTEB", arrivalICAO:"KIAD"},
        {Destination:"Chicago to West Palm Beach", deptICAO:"KMDW", arrivalICAO:"KPBI"},
        {Destination:"Las Vegas to Teterboro", deptICAO:"KLAS", arrivalICAO:"KTEB"},
        {Destination:"Phoenix to Chicago", deptICAO:"KPHX", arrivalICAO:"KMDW"},
        {Destination:"West Palm Beach to Teterboro", deptICAO:"KPBI", arrivalICAO:"KTEB"},
        {Destination:"Los Angeles to Washington DC", deptICAO:"KVNY", arrivalICAO:"KIAD"},
        {Destination:"Austin to Chicago", deptICAO:"KAUS", arrivalICAO:"KMDW"}
    ]
    return(
        <div className="PopularTrips">
            <div className="PopularContainer">
                <h1 className="Title">Popular Routes</h1>
                <div className="TopSection">
                    <div className="PopularCard-1 Cloud1 TopPicture" onClick={() => {history.push("/popular-routes-book",{"route":{deptICAO:"KTEB", arrivalICAO:"KPBI"}})}}>
                        <p className="routeName">Teterboro to West Palm Beach</p>
                        <div className="CodeSection">
                            <p>KTEB</p>
                            <img src={PlaneIcon}/>
                            <p>KPBI</p>
                        </div>
                    </div>
                    <div className="PopularCardContainer">
                        <div className="PopularCard-2 Cloud2 TopPicture" onClick={() => {history.push("/popular-routes-book",{"route":{deptICAO:"KMDW", arrivalICAO:"KPHX"}})}}>
                            <p className="routeName">Chicago to Phoenix</p>
                            <div className="CodeSection">
                                <p>KMDW</p>
                                <img src={PlaneIcon}/>
                                <p>KPHX
</p>
                            </div>
                        </div>
                        <div className="PopularCard-2 Cloud3 TopPicture" onClick={() => {history.push("/popular-routes-book",{"route":{deptICAO:"KAUS", arrivalICAO:"KTEB"}})}}>
                            <p className="routeName">Austin to Teterboro</p>
                            <div className="CodeSection">
                                <p>KAUS</p>
                                <img src={PlaneIcon}/>
                                <p>KTEB</p>
                            </div>
                        </div>
                    </div>
                    <div className="PopularCardGrid">
                        {routes.map((route, i) => {
                            console.log(i,(((i+4)%6)+1))
                            return(
                            <div index={i} className={"PopularCard-3 BottomPicture Cloud"+(((i+3)%6)+1)} onClick={() => {history.push("/popular-routes-book",{"route":route})}}>
                                <div className="inner-content">
                                    <p className="routeName">{route.Destination}</p>
                                    <div className="CodeSection">
                                        <p>{route.deptICAO}</p>
                                        <img src={PlaneIcon}/>
                                        <p>{route.arrivalICAO}</p>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>

                <div className="BottomSection">
                    <h1 className="Title">More Popular Routes</h1>
                    <img className='scroller'
                        src={ArrowDown}
                        onClick={() => {
                            window.document.getElementById("MoreRoutes").scrollIntoView({behavior: "smooth"})
                        }}
                        alt='scroll down'
                    />
                    <div id="MoreRoutes">
                        {moreRoutes.map((route,i)=>{
                            return(
                                <div index={i} className="PopularCard-4" onClick={() => {history.push("/popular-routes-book",{"route":route})}}>
                                    <div className="PlaceContainer">
                                        <div className="dept">
                                            <p className="place">{route.deptCity}</p>
                                            <p className="code">{route.deptICAO}</p>
                                        </div>
                                    </div>
                                    <BsArrowRight size={30}/>
                                    <div className="PlaceContainer">
                                        <div className="arrival">
                                            <p className="place">{route.arrivalCity}</p>
                                            <p className="code">{route.arrivalICAO}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopularTrips;