import {
  JET_INFO_ERROR,
  JET_INFO_SUCCESS,
  RESET_SEARCH_RESULTS,
  SEARCH_AIRCRAFT_ERROR,
  SEARCH_AIRCRAFT_SUCCESS,
  SET_SEARCH_OPTIONS,
  SET_SELECTED_ADDONS,
  SET_SELECTED_AIRCRAFT,
  UPDATE_ADDONS_ERROR,
  UPDATE_ADDONS_SUCCESS
} from "../constants/aircarft.constants";

const initialState = {
  searchOptions: {},
  searchResults: {
    data: {},
    success: false,
    error: false,
  },
  jetInfo: {
    data: {},
    success: false,
    error: false,
  },
  selectedAircraft: {},
  selectedAddOns: [],
  balance: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_OPTIONS: {
      return {...state, searchOptions: action.payload,}
    }
    case SEARCH_AIRCRAFT_SUCCESS: {
      return {...state, searchResults: {data: action.payload, success: true, error: false,}}
    }
    case SEARCH_AIRCRAFT_ERROR: {
      return {...state, searchResults: {data: [], success: false, error: {error: true},}}
    }
    case JET_INFO_SUCCESS: {
      return {...state, jetInfo: {data: action.payload, success: true, error: false,}}
    }
    case JET_INFO_ERROR: {
      return {...state, jetInfo: {data: [], success: false, error: true,}}
    }
    case SET_SELECTED_AIRCRAFT: {
      return {...state, selectedAircraft: action.payload,}
    }
    case SET_SELECTED_ADDONS: {
      return {...state, selectedAddOns: action.payload,}
    }
    case UPDATE_ADDONS_SUCCESS: {
      return {
        ...state,
        selectedAircraft: {
          ...state.selectedAircraft,
          priceBreakup: action.payload.priceBreakup,
          bookingId: action.payload.bookingId,
        },
        balance: {
          ...state.balance,
          ...action.payload.balance,
        }
      }
    }
    case UPDATE_ADDONS_ERROR: {
      return {...state,}
    }
    case RESET_SEARCH_RESULTS: {
      return {...state, searchResults: {data: {}, success: false, error: false,},}
    }
    default: {
      return state
    }
  }
};
