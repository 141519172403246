import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import "./ApplicationTab.scss";
import { getEleAttribute } from "../utility";

function ApplicationTab({ tabs, onTabChange, activeTab, theme }) {
  const [active, setActive] = useState(activeTab);
  const [tabIndex, setTabIndex] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const tabContainerRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTabClick = (value, index) => {
    setActive(value);
    onTabChange(value);
    setTabIndex(index);
    if (isMobile) {
      const tabElement = tabContainerRef.current.children[index];
      tabElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };
  useEffect(() => {
    if (isMobile && activeTab !== null && tabContainerRef.current) {
      const defaultTabElement = tabContainerRef.current.children[2];
      defaultTabElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [isMobile, activeTab]);
  return (
    <div className="ApplicationTab">
      <div
        className="tab-container"
        ref={tabContainerRef}
        style={{ justifyContent: tabIndex == 0 || 1 ? "unset" : "center" }}
      >
        {tabs.map(({ label, value }, index) => (
          <div
            key={value}
            className={`tab ${value === active ? "selected-tab" : ""}`}
            style={{ color: getEleAttribute("customPrimaryColor", theme) }}
            onClick={() => handleTabClick(value, index)}
            draggable="false"
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
}

ApplicationTab.propTypes = {
  activeTab: PropTypes.any,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
};

export default ApplicationTab;
