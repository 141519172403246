const KEYWORD = "TEST KEYWORD"

export const HOME_PAGE_SEO = {
  title: "AIRBOOK ONE Home Page",
  description: "Airbook one description",
  // keyword: KEYWORD,
  robots: "index, follow",
  viewport: "width=device-width, initial-scale=1.0",
  charset: "UTF-8"
}

export const WHY_FLY_PRIVATE_SEO = {
  title: "Why Fly Private with AIRBOOK ONE",
  description: "FBO, Wifi, Convenience, Safety, Covid Cleaned",
  // keyword: KEYWORD,
  robots: "index, follow",
  viewport: "width=device-width, initial-scale=1.0",
  charset: "UTF-8"
}