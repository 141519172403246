import React, {useEffect, useState} from 'react';
import "./AddPassengerExistingModal.scss";
import Modal from "../Modal";
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import {GET_AUTH} from "../../utils/HTTP.utils";
import {API_GET_ALL_TRAVELLERS} from "../../middleware/constants/url.constants";
import Button from "../Button";
import CheckBox from "../CheckBox/CheckBox";

function AddPassengerExistingModal({status, onChange, onNewTraveller, existing = []}) {
  const [state, setState] = useState([]);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    let {token} = await getIsUaAuthentication();
    let data = await GET_AUTH(API_GET_ALL_TRAVELLERS, token, {});
    setState(data)
  };
  useEffect(() => {
    fetchData();
    existing.length > 0 && setData([...existing.map(a => a.id)])
  }, [])
  const onSubmit = e => {
    e.preventDefault();
    onNewTraveller(data);
    onChange(false)
  }
  const onCheckClick = id => value => {
    if (value) {
      setData([...data, id]);
    } else if (!value) {
      setData([...data.filter(a => a !== id)])
    }
  }
  return (
    <div className="AddPassengerExistingModal">
      <Modal close={() => onChange(false)} show={status}>
        <p className="title">Add from Contacts</p>
        <form onSubmit={onSubmit}>
          <div className="radio">
            {state.map((a, i) => {
              return <div className="radio-tile" key={i}>
                <CheckBox color={"blue"}
                          onClick={onCheckClick(a.id)}
                          checked={data.indexOf(a.id) > -1}/>
                <label htmlFor="male">{a.name}</label>
              </div>
            })}
          </div>
          <Button label={"Add Passenger"}
                  type={"yellow"}
                  btnAction={"submit"}/>
        </form>
      </Modal>
    </div>
  );
}

export default AddPassengerExistingModal;
