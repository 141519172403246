import React from 'react';
import "./WalletPayBreakDown.scss";

function WalletPayBreakDown({
                              balance = 0,
                              surcharge = 0,
                              total = 0,
                              rewards = 0
                            }) {
  return (
    <div className="WalletPayBreakDown">
      <div className="tile">
        <p className="key">AB1 Wallet Balance</p>
        <p className="value">${balance.toLocaleString()}</p>
      </div>
      <div className="extra-info">
        <div className="tile">
          <p className="key blue">This Flight</p>
          <p className="value blue">${total.toLocaleString()}</p>
        </div>
        {rewards && <p className="info-text">You will earn ${rewards.toLocaleString()} in cash rewards</p>}
      </div>
      <div className="tile">
        <p className="key">Remaining Balance</p>
        <p className="value">${(balance - total).toLocaleString()}</p>
      </div>
    </div>
  );
}

export default WalletPayBreakDown;