import React from 'react';
import './FlyPrivateCard.scss';

const FlyPrivateCard = ({image, title, reference, sections, html=false}) => {
  return (
    <div className='pvt-fly-card'>
      <img className='pvt-fly-card-img' src={image} alt={title}/>
      <h3 className='pvt-fly-card-title'>{title}</h3>
      <div>
        <>
          {(!html && reference?.length > 0) && (
            <div className='pvt-fly-card-content'>
              <p>{reference}</p>
            </div>
          )}
          
            {html &&  (
              <div className='pvt-fly-card-content'>
                <p dangerouslySetInnerHTML={{__html:reference}}/>
              </div>)}
          
        </>
        {sections?.length > 0 && (
          <div>
            {sections.map(({subTitle, reference}, index) => {
              return (
                <div key={index} className='pvt-fly-card-content'>
                  <h5>{subTitle}</h5>
                  <p>{reference}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FlyPrivateCard;
