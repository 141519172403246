import React, {useState} from 'react';
import './ChangePasswordModal.scss';
import {Auth} from 'aws-amplify';
import {toast} from 'react-toastify';
import Modal from '../Modal';
import Button from '../Button';
import {FaArrowRight} from 'react-icons/fa';
import InputBox from '../InputBox';
import LoadingOverlay from 'react-loading-overlay';

const TABS = {
  SEND_LINK: 'SEND_LINK',
  ENTER_VERIFICATION_CODE: 'ENTER_VERIFICATION_CODE',
};

function ChangePasswordModal({onChange, status, email}) {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [page, setPage] = useState(TABS.SEND_LINK);
  const [loader, setLoader] = useState(false);
  const onNext = () => {
    setLoader(true);
    Auth.forgotPassword(email)
      .then(() => {
        setPage(TABS.ENTER_VERIFICATION_CODE);
        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setLoader(false);
      });
  };
  const onSubmit = () => {
    if (code !== '' || password !== '') {
      setLoader(true);
      Auth.forgotPasswordSubmit(email, code, password)
        .then(() => {
          setCode('');
          setPassword('');
          setPage(TABS.SEND_LINK);
          toast.success('Password changed successfully.');
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          setLoader(false);
        });
    } else {
      toast.error('Please fill required fields.');
    }
  };
  return (
    <div className='ChangePasswordModal'>
      <Modal show={status}>
        {page === TABS.SEND_LINK && (
          <>
            <p className='title'>Change Password</p>
            <div className='input-containers'>
              <p>Send a reset link to the email below</p>
              <InputBox
                theme={'light'}
                label={'Email'}
                type={'email'}
                value={email}
                disabled
              />
            </div>
          </>
        )}
        {page === TABS.ENTER_VERIFICATION_CODE && (
          <>
            <p className='title'>Enter Verification Code</p>
            <div className='verification-containers'>
              <InputBox
                theme={'light'}
                label={'Type the code sent to your email.'}
                type={'number'}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <InputBox
                theme={'light'}
                label={'New Password'}
                type={'password'}
                eye={true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </>
        )}

        <div className='action-container'>
          <Button
            label={'Cancel'}
            type={'red'}
            onClick={() => {
              setPage(TABS.SEND_LINK);
              onChange(false);
            }}
          />
          {page === TABS.SEND_LINK && (
            <Button
              label={'Next'}
              icon={<FaArrowRight/>}
              iconPosition={'right'}
              onClick={onNext}
              type={'yellow'}
            />
          )}
          {page === TABS.ENTER_VERIFICATION_CODE && (
            <Button
              label={'Submit'}
              onClick={() => {
                onSubmit();
                onChange(false);
              }}
              type={'yellow'}
            />
          )}
        </div>
      </Modal>
      <LoadingOverlay active={loader} spinner text='Loading...'/>
    </div>
  );
}

export default ChangePasswordModal;
