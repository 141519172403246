import React from "react";
import Email from "../../assets/icons/email.png";
import Chat from "../../assets/icons/chat.png";
import Phone from "../../assets/icons/phone.png";
import ArrowDown from "../../assets/icons/arrow-down.png";
import aircraftConcierge from "../../assets/TS_Backlog_-_iljujjkin_-_1463548_-_Bombardier_Challenger_350 2.png";
import Icon1 from "../../assets/concierge/icons/1.png";
import Icon2 from "../../assets/concierge/icons/2.png";
import Icon3 from "../../assets/concierge/icons/3.png";

import "./Concierge.scss";
import ImageSideComponent from "../../components/ImageSideComponent/ImageSideComponent";

const ConciergeServiceList = ({ hidable = false, bottom = false }) => {
  return (
    <div
      className={`services-list ${hidable === true ? "hidable" : ""} ${
        bottom === true ? "bottom" : ""
      } `}
    >
      <div className="concierge-service">
        <img src={Chat} alt={"Chat."} style={{ width: "38px" }} />
        <h4 className="subtitile-h4 white">Chat.</h4>
        <p
          id="chat-btn"
          className="info"
          onClick={() => {
            window.LiveChatWidget.call("maximize");
          }}
        >
          Live Chat Service
        </p>
      </div>
      <div className="concierge-service">
        <img src={Email} alt={"Email."} style={{ width: "38px" }} />
        <h4 className="subtitile-h4 white">Email.</h4>
        <a href={"mailto:flightcon@airbookone.com"} className="info">
          flightcon@airbookone.com
        </a>
      </div>
      <div className="concierge-service">
        <img src={Phone} alt={"Call."} style={{ width: "38px" }} />
        <h4 className="subtitile-h4 white">Call.</h4>
        <a href={"tel:3127615080"} className="info">
        312-761-5080
        </a>
      </div>
    </div>
  );
};
const Concierge = () => {
  let statements = [
    {
      icon: Icon1,
      statement: "High-end team service with ‘seamless execution’",
    },
    {
      icon: Icon2,
      statement:
        "75 Years of Customer Service Experience geared toward Fortune 200 Companies and High-Net-Worth Individuals",
    },
    {
      icon: Icon3,
      statement:
        "Our Flight Concierge Team personally vets all operators, aircraft, and flight crews",
    },
  ];

  return (
    <div className="concierge-main Concierge">
      <div className="concierge-root action-space">
        <div className="concierge-title" style={{ paddingBottom: "40px" }}>
          <h3 className="headline-h3 text-align-center">
            AIRBOOK ONE's Flight Concierge Team is available 24/7 to provide a
            booking and trip management experience that exceeds your
            expectations. Our Flight Concierge Team gives Power to the
            Passenger&trade;, as your contact for all trips from start to
            finish.
          </h3>
        </div>

        <p
          className="concierge-subphrase action-space"
          style={{ paddingBottom: "40px" }}
        >
          <ConciergeServiceList />
        </p>
        <img
          className="testimonial-scroll-icon pointer-cursor action-space"
          src={ArrowDown}
          onClick={() => {
            window.document
              .querySelector(".detail-section")
              .scrollIntoView({ behavior: "smooth" });
          }}
          alt="scroll down"
        />
      </div>
      <div className="concierge-root" style={{paddingBottom: "50px"}}>
        <div className="detail-section">
          <h3 className="headline-h3">
            We deliver elite service without any of the traditional memberships
            and upfront costs.
          </h3>
          <div
            className="statement-container"
            style={{ marginLeft: "37%" }}
          >
            <div className="concierge-statements">
              {statements.map((statement, i) => {
                return (
                  <div key={i} className="Statement">
                    <img className="icon" src={statement.icon} alt="" />
                    <h4 className="text subtitile-h4">{statement.statement}</h4>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="fullservice-section">
          <h2 className="headline-h2">Full Service</h2>
        </div>
      </div>
      <ImageSideComponent />
      <div className="concierge-root">
        <ConciergeServiceList bottom={true} />
      </div>
    </div>
  );
};

export default Concierge;
