import React from 'react';
import ArrowDown from '../../assets/icons/arrow-down.png';
import Flag from '../../assets/icons/flag.png';
import Wallet from '../../assets/icons/wallet.png';
import Itinerary from '../../assets/icons/itinerary.png';
import Shield from '../../assets/icons/shield.png';
import CarbonOffset from '../../assets/icons/carbon-offset.png';
import FlyPrivateCard from '../../components/FlyPrivateCard';
import './DiversityInclusion.scss';
import DownloadRef from '../../components/DownloadRef';

const DiversityInclusion = ({}) => {
  const services = [
    {
      image: Flag,
      title: 'Auto Booking',
      reference:
        'Through the AIRBOOK ONE Mobile App and Website, Real-Time Market Pricing can be automatically booked and guaranteed.  AIRBOOK ONE also uses our own proprietary Real Time Market Average Algorithms, showing the customer the "Daily Market Average" on the OPEN market for their requested flight searches.',
    },
    {
      image: Itinerary,
      title: 'Loyalty Program',
      reference:
        'AIRBOOK ONE further enhances the private travel experience by giving back through rewards. Our customers receive one percent of the total flight cost in rewards to use towards any future flights with AIRBOOK ONE within 24 months of booking.',
    },
    {
      image: Wallet,
      title: 'Limitless Wallet',
      sections: [
        {
          reference:
            'With the AIRBOOK ONE Limitless Wallet program, passengers Can Deposit funds into their Wallet Account to pay for future flights, creating an instant seamless payment process.',
        },
        {
          reference:
            'Wallet Funding Requirements: $25,000 minimum initial deposit, $10,000 minimum balance at all times. If you are interested, please contact us for setup.'
          ,
        },
      ],
    },
    {
      image: CarbonOffset,
      title: 'Carbon Offset',
      sections: [
        {
          reference:
            'AIRBOOK ONE works with the company 4AIR.  AIRBOOK ONE ensures every flight booked with us is carbon Neutral and will meet 4Air bronze level every time you fly',
        },
        {
          reference:
            '4AIR supports projects all over the world that fund greenhouse gas reductions including renewable energy, forestry and efficiencies.  Every project meets the stringent criteria through leading nonprofit standards like gold standard, verra, climate action reserve and American carbon registry.'
          ,
        },
      ],
    },
  ];
  const servicesCont = [
    {
      image: Shield,
      title: 'Safety Ratings',
      reference:
        'AIRBOOK ONE only books trips with operators rated through ARG/US and/or Wyvren audits.',
      sections: [
        {
          subTitle: 'ARG/US Level',
          reference:
            'ARG/US is a third-party safety rating company that audits private jet operators spanning over 500 operators globally. Each ARG/US rated operator is continually monitored for safety and training. There are three levels of ARG/US ratings available: Gold, Gold Plus, and Platinum.',
        },
        {
          subTitle: 'Wyvern',
          reference:
            'Wyvern is a third-party company with a rating system of private aircraft operators measuring adherence to ICAO (International Civil Aviation Organization) standards for safety management systems, emergency response plans, and internal evaluations.',
        },
      ],
    },
  ];
  return (
    <div className={"DiversityInclusion"}>
      <div className='root-section'>
        <div className='hiw-section-1'>
          <h4 className='about-us'>
          We are a diverse team that celebrates the unique perspectives, ideas and experiences of every individual.
          From the team members we hire, to the customers we serve, inclusion and diversity is a way of life at AIRBOOK
          ONE. <br></br><br></br> We are guided by our inclusive purpose and mission to curate gateways to develop deeper connections and
          broaden horizons for our partners, customers and communities. We are dedicated to creating an inclusive
          environment for everyone, regardless of race, ethnicity, color, national origin, age, disability, sexual
          orientation, gender identity/expression or any other legally protected characteristic.
          </h4>
          {/* <img className='scroll-icon pointer-cursor'
               src={ArrowDown}
               onClick={() => {
                 window.document.getElementById("fly-with-us-advantages").scrollIntoView({behavior: "smooth"})
               }}
               alt='scroll down'/> */}
        </div>
        {/* <div id={"fly-with-us-advantages"}>
          {services.map((item, index) => (
            <FlyPrivateCard key={index} {...item} />
          ))}
        </div> */}
        {/* <LearnMore title='View our aircrafts.'
                   onClick={() => history.push("/aircrafts")}
                   img={ViewMore}
                   arrowDir='right'/>
 */}
        {/* <div>
          {servicesCont.map((item, index) => (
            <FlyPrivateCard key={index} {...item} />
          ))}
        </div>
        <div className='spacer'/>
        <DownloadRef/> */}
      </div>
    </div>
  );
};

export default DiversityInclusion;
