import React, {useState, useEffect} from 'react';
import activeDot from '../../assets/active-dot.png';
import inactiveDot from '../../assets/inactive-dot.png';
import "./Carousel.scss"

const Carousel = () => {
    let statements = [{statement: '“I have been flying privately for years, the fact that I can now fly without ANY member fees or upfront costs, while still having access to a high-end Flight Concierge Team is phenomenal.”', person:'Howard S', place: 'Atlanta, GA'},
        {statement: '“I read the Forbes article and can confirm these are some of the best prices I have seen in private air, and they are guaranteed.”', person:'Daniel H, CEO', place: 'Sarasota, FLA'},
        {statement: '“I have never flown privately before, and AIRBOOK ONE’s Flight Concierge Team were informative, upfront and we loved their attention to safety.”', person:'JM', place: 'New York, NY'}
    ];
    let [active, setActive] = useState({0:true,1:false,2:false});
    let [counter, setCounter] = useState(0);
    useEffect(() => {
        const switchSlide = () => {
            let next = (counter+1)%3;
            setActive({...active, [counter]:false, [next]:true});
            setCounter((counter+1)%3);
        }
        let timer = setTimeout(switchSlide, 5000);
        return() =>{
            clearTimeout(timer)
        }
    },[counter]);
    return(
        <div className="Carousel">
            <p className="statement">{statements[counter].statement}</p>
            <p className={"person"}>{statements[counter].person}, {statements[counter].place}</p> 
            <div className="dots">
            {statements.map((s,i) =>{
                return(
                        <img key={i} src={active[i]?activeDot:inactiveDot} alt=""/>
            )})}
            </div>
        </div>
    )
}

export default Carousel;