import React, {useState, useEffect} from 'react';
import LoadingOverlay from "react-loading-overlay";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import CheckBox from "../../components/CheckBox";
import Modal from "../Modal";
import "./PayWithCreditCard.scss";
import CreditCardDetails from "../CreditCardDetails";
import Button from "../Button";
import { toast } from "react-toastify";
import { INIT_PAYMENT } from "../../middleware/constants/payment.constants";
import {FaLock} from "react-icons/fa";
import CreditCardCharges from "../CreditCardCharges";
import {authenticationToken, validateCCDetails} from "../../containers/ReviewBooking/helper";
import {POST_AUTH} from "../../utils/HTTP.utils";
import {API_PROCESS_TOKEN} from "../../middleware/constants/url.constants";
import {getIsUaAuthentication} from "../../hooks/useAuthentication";

function PayWithCreditCard({
                             status,
                             onChange,
                             bookingId,
                             initiatePayment,
                             initPayment
                           }) {
  let history = useHistory();
  const [terms, setTerms] = useState(true);
  const [ccReset, setReset] = useState(false);
  const [ccDetails, setCcDetails] = useState({
    name: "",
    number: "",
    expiry: "",
    ccv: "",
  });
  const [loader, setLoader] = useState(false);
  const completePayment = async () => {
    let {token} = await getIsUaAuthentication();
    let {apiLoginId, clientKey} = initiatePayment?.authorizeNetData;
    let authData = await authenticationToken(clientKey, apiLoginId, ccDetails);
    let options = {
      "paymentMethod": "CREDIT_CARD",
      "dataValue": authData.dataValue,
      "dataDescriptor": authData.dataDescriptor,
      "paymentId": initiatePayment.paymentId,
      "mode": "FULL_PAYMENT"
    };
    let finalCall = await POST_AUTH(API_PROCESS_TOKEN, options, token)
    if (finalCall.status === "SUCCESS") {
      setLoader(false);
      history.push("/payment-success", {
        finalCall,
        subType: "CREDIT_CARD",
        type: "FULL_PAYMENT",
      });
    } else {
      setLoader(false);
      if (authData.resultCode === "Error") {
        setReset(true);
        initPayment({
          bookingId: bookingId,
          paymentMethod: "CREDIT_CARD",
          paymentMode: "FULL_PAYMENT",
        });
        authData.message?.map((a) => {
          toast.error(a.text);
        });
      } else {
        setReset(true);
        initPayment({
          bookingId: bookingId,
          paymentMethod: "CREDIT_CARD",
          paymentMode: "FULL_PAYMENT",
        });
        toast.error("Credit card payment failed!");
      }
    }
  }

  useEffect(() => {
    if (status) {
      initPayment({
        bookingId: bookingId,
        paymentMethod: "CREDIT_CARD",
        paymentMode: "FULL_PAYMENT",
      });
    }
  }, [status]);

  return (
    <div className={"PayWithCreditCard"}>
      <Modal close={() => onChange(false)} show={status}>
        <p className="title">Complete Payment</p>
        <p className="info">
          The full cost of this flight will be charged to your credit card,
          including of a 3% fee.
        </p>
        <CreditCardCharges {...initiatePayment?.flightData} />
        <CreditCardDetails
          reset={ccReset}
          setReset={setReset}
          data={ccDetails}
          setData={setCcDetails}
        />
        {/* <div className="term-agreement-title">
          <p>Booking Terms</p>
        </div> */}
       
        <Button
          iconPosition={"left"}
          disabled={terms}
          icon={<FaLock />}
          type={terms ? "grey" : "yellow"}
          label={"Complete Your booking"}
          onClick={async () => {
            let isValid = validateCCDetails(ccDetails);
            if (isValid) {
              setLoader(true);
              await completePayment();
            } else {
              toast.error("Credit card Details are not valid!");
            }
          }}
        />
      </Modal>
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
    </div>
  );
}

function mapStateToProps({aircraft, payment}) {
  return {
    initiatePayment: payment.initiatePayment,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    initPayment: (data) => dispatch({ type: INIT_PAYMENT, payload: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayWithCreditCard)
