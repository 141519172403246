import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import './Login.scss';
import Button from '../../components/Button';
import InputBox from '../../components/InputBox';
import {UPDATE_ADDONS} from '../../middleware/constants/aircarft.constants';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import {FaApple, FaFacebook, FaGoogle} from 'react-icons/fa';
import Modal from '../../components/Modal';

const Login = ({
                 history,
                 updateAddOns,
                 selectedAircraft = {},
                 selectedAddOns = []
               }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [wait, setWait] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [code, setCode] = useState('');
  let path = history?.location?.state?.path;
  let review = history?.location?.state?.review;

  useEffect(() => {
    let register = history?.location?.state?.success;
    if (register) {
      toast.success("Signed up. Please login to continue.")
    }
  }, []);
  useEffect(() => {
    if(wait && review && selectedAircraft.bookingId){
      history.push(`/review-booking/${selectedAircraft.bookingId}`);
    }
  }, [selectedAircraft.bookingId]);

 

  const handleLogin = (e) => {
    e.preventDefault();
    if (email !== '' || password !== '') {
      setLoader(true);
      Auth.signIn({username: email, password})
        .then((res) => {
          if (review) {
            updateAddOns({
              addonIds: selectedAddOns,
              aboneSearchId: selectedAircraft.aboneSearchId
            });
            setWait(true);
          } else if(path){
            history.push(path)
          }
          else {
            setLoader(false);
            window.location.href = '/';
          }
        })
        .catch((err) => {
          console.log(`Error signing up: ${JSON.stringify(err)}`);
          if (err.code === 'UserNotConfirmedException') {
            setLoader(true);
            Auth.resendSignUp(email)
              .then(() => {
                setModal(true);
                setLoader(false);
              })
              .catch((err) => {
                console.log(err, 'err');
                toast.error(err.message);
              });
          } else {
            toast.error(err.message);
          }
          setLoader(false);
        });
    } else {
      toast.error('Please fill in all fields.');
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  const confirmSignUpCode = (e) => {
    e.preventDefault();
    if (code !== '') {
      setLoader(true);
      Auth.confirmSignUp(email, code)
        .then(() => {
          setModal(false);
          setLoader(false);
          toast.success('Awesome! Successfully Verified.');
          history.push('/login');
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          toast.error(err.message);
        });
    } else {
      toast.error('Please type the code.');
    }
  };

  return (
    <div className='login-root'>
      <LoadingOverlay active={loader} spinner text='Loading...'>
        <h1 className='title'>Log In</h1>
        <form className='login-form'>
          <InputBox
            type='email'
            title='Please enter email'
            placeholder='Email'
            className='input-grp'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputBox
            type='password'
            title='Please enter password'
            placeholder='Password'
            className='input-grp'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            btnAction={'submit'}
            label={'Login'}
            type={'yellow'}
            size={'small'}
            onClick={handleLogin}
          />
          <Button
            label={"Don't have an account? Sign Up"}
            type={'white-link'}
            onClick={() => history.push('/create-account')}
            size={'small'}
          />
          <Button
            label={'Forgot password?'}
            type={'white-link'}
            onClick={() => history.push('/forgot-password')}
            size={'small'}
          />
          <div className='social-login-icon-links'>
            <p>Sign in with</p>
            <div className={"social-login-container"}>
              <Button
                fullWidth={false}
                onClick={() => Auth.federatedSignIn({provider: 'SignInWithApple'})}
                type={'yellow'}
                size={'small'}
                icon={<FaApple/>}
              />
              <Button
                fullWidth={false}
                onClick={() => Auth.federatedSignIn({provider: 'Facebook'})}
                type={'blue'}
                size={'small'}
                icon={<FaFacebook/>}
              />
              <Button
                fullWidth={false}
                onClick={() => Auth.federatedSignIn({provider: 'Google'})}
                type={'red'}
                size={'small'}
                icon={<FaGoogle/>}
              />
            </div>
          </div>
        </form>
        <Modal show={modal} close={handleClose}>
          <div>
            <p>A confirmation code has been sent to your email {email}</p>
            <form>
              <InputBox
                theme={'light'}
                type='text'
                title='Please type the Code'
                placeholder='Verification Code'
                className='input-grp'
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <Button
                btnAction={'submit'}
                label={'Confirm'}
                type={'yellow'}
                size={'small'}
                onClick={(e) => confirmSignUpCode(e)}
              />
            </form>
          </div>
        </Modal>
      </LoadingOverlay>
    </div>
  );
};

function mapStateToProps({aircraft}) {
  return {
    selectedAircraft: aircraft.selectedAircraft,
    selectedAddOns: aircraft.selectedAddOns
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAddOns: (data) => dispatch({type: UPDATE_ADDONS, payload: data}),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
