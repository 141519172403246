import React from 'react';
import PropTypes from 'prop-types';
import "./TextLabelTile.scss";

function TextLabelTile({title, content}) {
  return (
    <div className="TextLabelTile">
      <p className="title">{title}</p>
      <p className="content">{content}</p>
    </div>
  );
}

TextLabelTile.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default TextLabelTile;