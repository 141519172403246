import Bombardier from "../../assets/bombardier.png";
import TurboProp from "../../assets/turboprop.png";
import Large from "../../assets/large.png";
import Midsize from "../../assets/midsize.png";
import SuperMidsize from "../../assets/supermidsize.png";
import Light from "../../assets/light.png";


export default {
  "Turbo-Prop": {
    label: "Turbo-Prop Aircraft",
    info: "Turbo Props can be an ideal choice due to their high efficiency and lower costs for short to mid-range city flights. They typically carry between 6-10 passengers. This category includes such aircraft as the Avanti P180, the King Air Fleet, Pilatus PC-12, and Cessna Conquest amongst others.\n\n Luggage information: Approx. 25 – 50 cu ft / 6 – 8 medium carry on sized luggage. May be able to accommodate golf clubs in some instances.",
    // image: "",
    manufacturers: [
      {
        // image: Bombardier,
        title: 'Textron Aviation',
        list: [
          'King Air 90',
          'King Air 100',
          'King Air 200',
          'King Air 260',
          'King Air 350/360ER',
          'King Air 260',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Pilatus',
        list: [
          'PC-12',
        ],
        link: '#',
      },
    ],
    aircraftDetails: {
      image: TurboProp,
      id: '1,000 - 1,500+ NM',
      desc:
        'Ideal for 1,000–1,500+ NM trips for groups up to 10.',
      passengers: {
        min: 6,
        max: 10,
      },
      luggage: {
        min: 6,
        max: 8,
      },
    },
  },
  "Light": {
    label: "Light Aircraft",
    info: "Light jets are the ideal choice for short to mid-range city to city flights. They have a range of 800-1700 miles with club type seating up to 8 passengers. The Light Jet category typically includes the Citation CJ series, Hawker 400, Honda Jet, Phenom 100 & 300 amongst others. \n\n  Luggage information: Approx. 30 – 60 cu ft / 6 – 8 medium carry on sized luggage. Limited space generally does not allow for golf clubs.",
    manufacturers: [
      {
        // image: Bombardier,
        title: 'Beechcraft by Textron Aviation',
        list: [
          'Beechjet 400A',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Citation by Textron Aviation',
        list: [
          'Citation Bravo',
          'Citation CJ2',
          'Citation CJ3',
          'Citation CJ4',
          'Citation Encore',
          'Citation M2',
          'Citation Mustang',
          'Citation Ultra',
          'Citation Bravo',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Hawker by Textron Aviation',
        list: [
          'Hawker 400',
          'Hawker 400 XP',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Falcon Dassault',
        list: [
          'Falcon 20',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Honda',
        list: [
          'Honda Jet',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Learjet',
        list: [
          'Lear 31A/35A',
          'Lear 40/50',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Embraer',
        list: [
          'Phenom 100',
          'Phenom 300',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Pilatus',
        list: [
          'PC-24',
        ],
        link: '#',
      },
    ],
    aircraftDetails: {
      image: Light,
      id: '1,000 - 1,800+ NM',
      desc:
        'Ideal for 1,000–1,800+ NM trips for groups up to 8.',
      passengers: {
        min: 6,
        max: 8,
      },
      luggage: {
        min: 6,
        max: 10,
      },
    }
  },
  "Midsize": {
    label: "Midsize Aircraft",
    info: "Mid-Size jets are most cost effective for flights of up to 4 hours in duration and typically carry 6-8 passengers comfortably. Mid-Size jets can travel from coast to coast in most cases. The Mid-Sized jet category includes the Citation Excel and XLS, Gulfstream G-200, Lear 60, and Hawker 800/900 amongst others. \n\n  Luggage information: Approx. 50 – 75 cu ft / 6 – 10 medium carry on sized luggage. Many can fit up to 4 sets of golf clubs.",
    manufacturers: [
      {
        // image: Bombardier,
        title: 'Cessna by Textron Aviation',
        list: [
          'Citation Excel',
          'Citation III',
          'Citation Latitude',
          'Citation XLS',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Gulfstream',
        list: [
          'Gulfstream G100',
          'Gulfstream G150',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Hawker by Textron Aviation',
        list: [
          'Hawker 700',
          'Hawker 750',
          'Hawker 800/800 XP',
          'Hawker 900 XP',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Falcon Dassault',
        list: [
          'Falcon 20',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Learjet',
        list: [
          'Lear 45XR',
          'Lear 55/60/65',
          'Learjet 70/75',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Embraer',
        list: [
          'Legacy 450',
          'Praetor 500',
        ],
        link: '#',
      },
    ],
    aircraftDetails: {
      image: Midsize,
      id: '2,000 - 3,000+ NM',
      desc:
        'Ideal for 2,000–3,000 NM trips for groups up to 8.',
      passengers: {
        min: 6,
        max: 8,
      },
      luggage: {
        min: 6,
        max: 10,
      },
    }
  },
  "Super Midsize": {
    label: "Super Midsize Aircraft",
    info: "Super Mid-Size jets are similar in layout and amenities as large jets, only on a smaller scale. Super Midsize jets comfortably carry between 7-12 passengers. This category includes aircraft such as the Citation X and Sovereign, Falcon 2000, Hawker 1000, Challenger 300’s amongst others. \n\n Luggage information: Approx. 100 – 150 cu ft / 10 – 12 medium carry on sized luggage. Golf club and ski friendly.",
    manufacturers: [
      {
        // image: Bombardier,
        title: 'Cessna by Textron Aviation',
        list: [
          'Citation Longitude',
          'Citation Sovereign',
          'Citation X',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Bombardier',
        list: [
          'Challenger 300',
          'Challenger 350',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Hawker by Textron Aviation',
        list: [
          'Hawker 1000',        
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Falcon Dassault',
        list: [
          'Falcon 2000',
          'Falcon 50',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Gulfstream',
        list: [
          'Gulfstream G200',
          'Gulfstream G280',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Embraer',
        list: [
          'Legacy 500',
          'Praetor 600',
        ],
        link: '#',
      },    ],
    aircraftDetails: {
      image: SuperMidsize,
      id: '3,000 - 4,000+ NM',
      desc:
        'Ideal for 3,000–4,000+ NM trips for groups up to 12.',
      passengers: {
        min: 7,
        max: 12,
      },
      luggage: {
        min: 10,
        max: 12,
      },
    }
  },
  "Large": {
    label: "Large Aircraft",
    info: "Large/Long Range jets typically carry between 10-20 passengers. They optimally blend comfort, performance and economy for medium to long range flights. Aircraft in this category include the Gulfstream G-550, Challenger 600’s, Global Express, Falcon 6X/7X/8X amongst others. \n\n Luggage information: Approx. 175 – 225 cu ft / 15 – 20 medium carry on sized luggage. Large roller luggage, golf clubs and ski friendly.",
    manufacturers: [
      {
        // image: Bombardier,
        title: 'Bombardier',
        list: [
          'Challenger 600/601',
          'Challenger 604/605',
          'Challenger 650',
          'Challenger 850',
          'Global 5000',
          'Global 6000',
          'Global 7500',
          'Global Express',
          'Global Express XRS',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Falcon Dassault',
        list: [
          'Falcon 6X',
          'Falcon 7X',
          'Falcon 8X',
          'Falcon 900',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Hawker by Textron Aviation',
        list: [
          'Hawker 4000',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Embraer',
        list: [
          'Legacy 600',
        ],
        link: '#',
      },
      {
        // image: Bombardier,
        title: 'Gulfstream',
        list: [
          'Gulfstream G450',
          'Gulfstream G500/550',
          'Gulfstream G600/650',
          'Gulfstream III / IV / IVsp / V',
        ],
        link: '#',
      },
   ],
    aircraftDetails: {
      image: Large,
      id: '4,000 - 7,000+ NM',
      desc:
        'Ideal for 4,000–7,000+ NM trips for groups up to 20.',
      passengers: {
        min: 10,
        max: 20,
      },
      luggage: {
        min: 15,
        max: 20,
      },
    }
  },
}