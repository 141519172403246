import React from 'react';
import "./CreditCardCharges.scss";

function CreditCardCharges({
                             flight = 0,
                             surcharge = 0,
                             total = 0,
                             rewards = 0
                           }) {
  return (
    <div className="CreditCardCharges">
      <div className="tile">
        <p className="key">This flight</p>
        <p className="value">${flight.toLocaleString()}</p>
      </div>
      <div className="tile">
        <p className="key">Credit card fees</p>
        <p className="value">${surcharge.toLocaleString()}</p>
      </div>
      <div className="extra-info">
        <div className="tile">
          <p className="key blue">Total</p>
          <p className="value blue">${total.toLocaleString() || flight + surcharge}</p>
        </div>
        {rewards && <p className="info-text">You will earn ${rewards.toLocaleString()} in cash rewards</p>}
      </div>

    </div>
  );
}

export default CreditCardCharges;
