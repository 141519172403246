import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import { navAuthLinks, navMobileAuthLinks, navMobileUnauthLinks, navUnauthLinks, } from '../../constants/app-constants';

//Importing images
import DesktopLogo from '../../assets/logos/desktop-logo.png';
import MobileLogo from '../../assets/logos/mobile-logo.png';
import DesktopLogoDark from '../../assets/logos/desktop-logo-dark.png';
import MobileLogoDark from '../../assets/logos/mobile-logo-dark.png';
import QuestionMark from '../../assets/icons/questionmark-icon.png';
import OpenMenu from '../../assets/icons/open-icon.png';
import './Header.scss';
import { Auth } from 'aws-amplify';
import { getIsUaAuthentication } from '../../hooks/useAuthentication';
import { AiOutlineClose } from 'react-icons/ai';
import AccountDeleteModal from "../../components/accountDeleteModal";

const Header = ({ theme }) => {
  const width = window.innerWidth;
  let history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accountDeleteModal, setAccountDeleteModal] = useState(false);
  useEffect(() => {
    getIsUaAuthentication().then(({ status }) => setIsAuthenticated(status));
  }, []);
  const navLinks = isAuthenticated ? navAuthLinks : navUnauthLinks;
  const navMobileLinks = isAuthenticated
    ? navMobileAuthLinks
    : navMobileUnauthLinks;

  const handleOpenMenu = () => {
    document.getElementById('mobile-menulist').style.display = 'block';
    document.getElementsByClassName('header-menu')[1].style.display = 'none';
    document.getElementsByClassName('header-menu')[2].style.display = 'block';
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
  };

  const handleCloseMenu = () => {
    document.getElementById('mobile-menulist').style.display = 'none';
    document.getElementsByClassName('header-menu')[1].style.display = 'block';
    document.getElementsByClassName('header-menu')[2].style.display = 'none';
    document.getElementsByTagName('html')[0].style.overflowY = 'scroll';
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
  };
  history.listen((location, action) => {
    getIsUaAuthentication().then(({ status }) => setIsAuthenticated(status));
  });

  return (
    // <div
    //   className={`${
    //     isAuthenticated ? 'header-main-dark-auth' : 'header-main-dark-unauth'
    //   }`}
    <div className="header-main-dark">
      <nav>
        <div className='header-root'>
          <Link to={"/"}>
            {theme === 'dark' ? (
              <img
                src={width < 480 ? MobileLogo : DesktopLogo}
                alt='AB1 Logo'
                className='brand-logo'
                onClick={() => history.push('/')}
              />
            ) : (
              <img
                src={width < 480 ? MobileLogoDark : DesktopLogoDark}
                alt='AB1 Logo'
                className='brand-logo'
                onClick={() => history.push('/')}
              />
            )}
          </Link>
          <div className='header-sections nav-grp'>
            {navLinks.map(({ name, route }, index) => {
              return (
                <HashLink
                  className={`ab1-nav-links ${theme === "dark" ? "theme-light" : "theme-dark"
                    }`}
                  key={index}
                  to={route}
                >
                  {name}
                </HashLink>
              );
            })}
          </div>
          <div className='header-sections header-right'>
            {isAuthenticated ? (
              <>
                <div
                  className={`ab1-nav-links ${theme === 'dark' ? 'theme-light' : 'theme-dark'
                    }`}
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  my account
                </div>
                <div
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuButton'
                >
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={() => history.push('/my-account')}
                  >
                    ACCOUNT
                  </a>
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={() => history.push('/my-trips')}
                  >
                    MY TRIPS
                  </a>
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={() => {
                      Auth.signOut({})
                        .then((a) => window.location.href = '/login')
                        .catch(console.error);
                    }}
                  >
                    LOG OUT
                  </a>
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={() => {
                      setAccountDeleteModal(true);
                    }}
                  >
                    DELETE
                  </a>
                </div>
              </>
            ) : (
              <div className="right-menu">
                <Link
                  className={`ab1-nav-links ${theme === 'dark' ? 'theme-light' : 'theme-dark'
                    }`}
                  to="/login"
                >
                  login
                </Link>
                <Link
                  className={`ab1-nav-links ${theme === 'dark' ? 'theme-light' : 'theme-dark'
                    }`}
                  to="/create-account"
                >
                  sign up
                </Link>
              </div>
            )}
          </div>
          <div className='mobile-header-sections header-right'>
            <img onClick={() => history.push('/concierge')} src={QuestionMark} alt='Help' className='header-menu' />
            <div>
              <img
                src={OpenMenu}
                onClick={handleOpenMenu}
                alt='menuitems'
                className='header-menu'
              />
              <span
                onClick={handleCloseMenu}
                className='header-menu'
                style={{ fontSize: '25px', color: 'white', display: 'none' }}
              >
                <AiOutlineClose />
              </span>
            </div>
          </div>
        </div>
        <div id='mobile-menulist'>
          <div style={{ padding: '20px 0' }}>
            {navMobileLinks.map(({ name, route }, index) => {
              return (
                <HashLink
                  className={`ab1-nav-links grp-list ${theme === "dark" ? "theme-light" : "theme-dark"
                    }`}
                  onClick={handleCloseMenu}
                  key={index}
                  to={route}
                >
                  {name}
                </HashLink>
              );
            })}
          </div>
          {/* <Link
            className={`ab1-nav-links unq-list ${
              theme === 'dark' ? 'theme-light' : 'theme-dark'
            }`}
            onClick={handleCloseMenu}
            to='/concierge'
          >
            concierge
          </Link> */}
          {isAuthenticated ? (
            <div style={{ padding: '20px 0' }}>
              <Link
                className={`ab1-nav-links grp-list ${theme === 'dark' ? 'theme-light' : 'theme-dark'
                  }`}
                onClick={handleCloseMenu}
                to='/my-account'
              >
                my account
              </Link>
              <Link
                className={`ab1-nav-links grp-list ${theme === 'dark' ? 'theme-light' : 'theme-dark'
                  }`}
                onClick={() => {
                  Auth.signOut({})
                    .then((a) => window.location.href = '/login')
                    .catch(console.error);
                }}
                to='#'
              >
                logout
            </Link>
            </div>
          ) : (
            <Link
              className={`ab1-nav-links unq-list ${theme === 'dark' ? 'theme-light' : 'theme-dark'
                }`}
              onClick={handleCloseMenu}
              to='/login'
            >
              login
            </Link>
          )}
        </div>
      </nav>
      <div>
        {accountDeleteModal && (
          <AccountDeleteModal
            status={accountDeleteModal}
            onChange={setAccountDeleteModal}
          />
        )}
      </div>
    </div>

  );
};

export default Header;
