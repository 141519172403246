import React, {useEffect, useState} from 'react';

function useApplePay() {
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (window.ApplePaySession) {
      let merchantIdentifier = 'merchant.com.airbookone.ios';
      let promise = window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
      promise.then((canMakePayments) => {
        if (canMakePayments) {
          setIsAllowed(true);
        }
      })
    }
  }, []);

  return isAllowed;
}

export default useApplePay;

