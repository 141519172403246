import React from "react";
import "./ImageSideComponent.scss";
import Frame1 from "../../assets/concierge/1.png";
import Frame2 from "../../assets/concierge/2.png";
import Frame3 from "../../assets/concierge/3.png";
import Frame4 from "../../assets/concierge/4.png";
import Frame5 from "../../assets/concierge/5.png";

const ImageSideComponent = () => {
  return (
    <div className="image-side-section">
      <div className="img-side-left frame1">
        <img src={Frame1} alt={"Frame1"} className="image-left" />
        <div className="content">
          <h3 className="headline-h3">Customization</h3>
          <p className="w330 body-font-p">
            We can fully customize your trip/aircraft upon request.
          </p>
        </div>
      </div>
      <div className="img-side-right frame2">
        <div className="content">
          <h3 className="headline-h3">Fully Automated Itineraries</h3>
          <p className="w422 body-font-p">
            Fully Automated and Customizable Itineraries that keep up with you
            and any changes that may occur for your trip.
          </p>
        </div>
        <img src={Frame2} alt={"Frame2"} className="image-right" />
      </div>
      <div className="img-side-left frame3">
        <img src={Frame3} alt={"Frame3"} className="image-left" />
        <div className="content">
          <h3 className="headline-h3">Coordination of Catering Services</h3>
          <p className="w330 body-font-p">
            Order what you like, how you like and when you want it.
          </p>
        </div>
      </div>
      <div className="img-side-right frame4">
        <div className="content">
          <h3 className="headline-h3">Ground Transportation</h3>
          <p className="w330 body-font-p">
            Book your own directly through the AIRBOOK ONE App or let our Flight
            Concierge Team handle it for you.
          </p>
        </div>
        <img src={Frame4} alt={"Frame4"} className="image-right " />
      </div>
      <div className="img-side-left frame5">
        <img src={Frame5} alt={"Frame5"} className="image-left" />
        <div className="content">
          <h3 className="headline-h3">24/7 Flight Concierge</h3>
          <p className="body-font-p">
            Call our Flight Concierge Team at any time with ‘any and all’
            questions that you may have regarding your trip.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageSideComponent;
