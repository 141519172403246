import React from "react";
// import {Link} from 'react-router-dom';
// import FooterLogo from '../../assets/logos/ab1_logo.png';
// import InstaLogo from '../../assets/logos/instagram-logo.png';
// import TwitterLogo from '../../assets/logos/twitter-logo.png';
// import FacebookLogo from '../../assets/logos/facebook-logo.png';
// import NBAALogo from '../../assets/logos/NBAA.png';
// import AirLogo from '../../assets/logos/4air.png';
// import WyvernLogo from '../../assets/logos/jira.png';
// import CarbonNeutralLogo from '../../assets/logos/carbon-neutral.png';
// import AvinodeLogo from '../../assets/logos/avinode-blackbg.png';
// import {footerSectionLinks} from '../../constants/app-constants';
import Footer from "../../../assets/custom-theme/footer.png";
import "./Footer.scss";

const CustomThemeFooter = () => {
  return (
    <div className="footer-main">
      <div className="foo-1">SERVICE SIMPLIFIED</div>
      <div className="foo-2">Private Aviation Services Empowered</div>
      <div className="foo-3">
        Every customer experiences Private AV personally. Through interactions
        with crew, schedulers or managers, we
        <br />
        provide the expert solutions that enhance your private travel
        experience. Every team member - including the name
        <br />
        on the door - is ready to assist at every turn.
      </div>
    </div>
  );
};

export default CustomThemeFooter;
