import React, { useState, useEffect } from 'react';
import DownloadRef from '../../components/DownloadRef';
import GenTempImageView from '../../components/GenTempImageView';
import ServiceCard from '../../components/ServiceCard';
import FlightView from '../../assets/flight-wing.jpeg';
import BuildingView from '../../assets/building-view.jpg';
import Plane from '../../assets/icons/plane.png';
import InstantBook from '../../assets/icons/instant-book.png';
import Search from '../../assets/icons/search.png';
import ArrowDown from '../../assets/icons/arrow-down.png';
import Forbes from '../../assets/featureForbes.png';
import SanitizeImg from '../../assets/sanitize.jpg';
import BrandNameLogo from '../../assets/logos/logo-name-desktop.png';
import { getIsUaAuthentication } from '../../hooks/useAuthentication';
import './HomePage.scss';
import SearchFlightForm from "./SearchFlightForm";
import PageContainer from "../../components/PageContainer";
import { HOME_PAGE_SEO } from "../../constants/seo.constants";
import tick from '../../assets/icons/list-bullet.png';
import AppStore from '../../assets/logos/app-store.png';
import PlayStore from '../../assets/logos/google-play.png';
import Carousel from '../../components/Carousel';
import Incentive from '../../components/Incentive';
import { GET_UN_AUTH } from "../../utils/HTTP.utils";
import { API_GET_CONFIGURATION } from "../../middleware/constants/url.constants";



const HomePage = ({ history }) => {
  // POPUP CODE
  let t = 7; // time until popup
  let [offerModal, setOfferModal] = useState(false);
  let [peekModal, setPeekModal] = useState(false);

  useEffect(() => {
    GET_UN_AUTH(API_GET_CONFIGURATION, { "type": "PEAK_TRAVEL_MESSAGE" }).then(data => {
      if (!data.error) {
        if (data['details'] && data['details']['homeScreenMessage']) {
          setPeekModal(true);
        }
      }
    })
  }, [])

  useEffect(() => {
    getIsUaAuthentication().then(({ status }) => {
      if (!status) {
        setTimeout(() => { setOfferModal(true) }, (t * 1000));
      }
    });
  }, [])


  const imgViewData = {
    img1: BuildingView,
    img2: FlightView,
    title1: 'How AIRBOOK ONE Works',
    title2: 'Why Fly Private?',
    subtitle1:
      'AIRBOOK ONE allows consumers to simply enter their travel information and search aircraft options directly, all from the convenience of their mobile phone, tablets or desktop.  All price quotes provided are accurate and can be locked-in immediately. In addition, AIRBOOK ONE offers all users access to a 24/7 complimentary premium Flight Concierge Team via email, chat, or phone to ensure a seamless experience.',
    subtitle2:
      'Private jet travel not only provides a more efficient way to travel, but also a safer way to fly by reducing passengers\' exposure to health and security risks. In addition, travelers enjoy the privilege of using private terminals (Fixed Based Operators – FBOs) where they can relax before their flight, without the hustle and bustle of commercial airline terminals.',
    link1: './how_it_works',
    link2: './why_fly_private',
  };

  const services = [
    {
      image: Search,
      title: 'Search Aircraft',
      reference:
        'Search thousands of aircraft and routes at your fingertips, effortlessly without any barriers or limitations.',
    },
    {
      image: InstantBook,
      title: 'Instantly Book',
      reference:
        'Secure your booking and lock in your “real-time” price instantly, guaranteed.',
    },
    {
      image: Plane,
      title: 'Fly',
      reference:
        'Enjoy a safe and fun experience while traveling with friends, family or colleagues.',
    },
  ];

  return (
    <PageContainer className='root-section' {...HOME_PAGE_SEO}>

      <div className='search-flight'>
        <div className='search-img'>
          <div className="left-section">
            <img className='logo' src={BrandNameLogo} alt='Airbook one logo' />
            <h5>Power to the Passenger.</h5>
            <div className="search-list" onClick={() => history.push(`/how_it_works`)}>
              <img src={tick} alt="" />
              <p className="home-links">Guaranteed Pricing - No Member Fees</p>
            </div>
            <div className="search-list" onClick={() => history.push(`/concierge`)}>
              <img src={tick} alt="" />
              <p className="home-links">Complimentary 24/7 Flight Concierge</p>
            </div>
            <div className="search-list" onClick={() => history.push(`/safety-ratings`)}>
              <img src={tick} alt="" />
              <p className="home-links">All Aircraft are Safety Vetted</p>
            </div>
            <div className="download">
              <a className="app-img-link" href="https://apps.apple.com/us/app/airbook-one/id1553278305">
                <img className="app-img button-1" src={AppStore} alt="app store" />
              </a>
              <a className="app-img-link" href="https://play.google.com/store/apps/details?id=com.airbookone">
                <img className="app-img button-2" src={PlayStore} alt="google play store" />
              </a>
            </div>
          </div>
          <SearchFlightForm history={history} />
        </div>
        <div className="undersection">
          <img className="Forbes" src={Forbes} alt="Forbes" />
          <div className="content">
            <p id="learnmore">These Websites Want To Change How You Charter Private Jets</p>
            <a target="_blank" href="https://www.forbes.com/sites/douggollan/2021/06/02/these-websites-want-to-change-how-you-charter-private-jets/?sh=625ca6216a37">
              <img className="arrow" src={ArrowDown} alt="learn more" />
            </a>
          </div>
        </div>
      </div>
      <Carousel />
      <div className='spacer' />
      <h1 className='homepage-title'>Easily book your next private trip.</h1>
      <div className='services-section'>
        {services.map((service, index) => {
          return <ServiceCard key={index} {...service} />;
        })}
      </div>
      <div className='spacer' />
      <GenTempImageView {...imgViewData} />
      <div className='sanitize'>
        <div className='sanitize-img'>
          <img src={SanitizeImg} alt='Sanitizing flight' />
        </div>
        <div className='sanitize-content'>
          <h3>Covid &amp; Cleaning Procedures</h3>
          <p>
            The FAA and CDC recommend that air carriers and crewmembers take precautions to avoid exposure to COVID-19.
            These precautions can be found in SAFO 20003. The CDC has also provided recommendations for aircraft
            operators to clean and disinfect aircraft.{' '}
          </p>
          {/* <button>Learn more</button> */}
        </div>
      </div>
      <DownloadRef />
      <Incentive.Popup status={offerModal} type="offer" onChange={setOfferModal} />
      <Incentive.Popup status={peekModal} type="peek" onChange={setPeekModal} />
    </PageContainer>
  );
};

export default HomePage;
