import React, { useState, useEffect } from "react";
import Button from "../Button";
import { BiCircle } from "react-icons/bi";
import { RiMapPinFill } from "react-icons/ri";
import { FaCircle } from "react-icons/fa";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import "./TripCard.scss";
import { parseDate } from "./helper";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import InformationModal from "../../components/InformationModal";
import {
  SET_SELECTED_ADDONS,
  SET_SELECTED_AIRCRAFT,
  UPDATE_ADDONS
} from "../../middleware/constants/aircarft.constants";

function TripCard({
  arrivalAirport,
  departureAirport,
  updateAddOns,
  arrivalDateTime,
  departureDateTime,
  flightTime,
  departureAirportRestriction,
  arrivalAirportRestriction,
  total,
  legOrder,
  paddedFuelStops,
  edit = true,
  jetType,
  selectedAircraft,
  itineryCount
}) {
  let history = useHistory();
  let parsed = moment(parseDate(departureDateTime).toISOString());
  const [informationModal, setInformationModal] = useState(false);

  // let [nonStop, setNonStop] = useState("NON-STOP");

  // useEffect(() => {
  //   let flightTimeArr = flightTime.split(":");
  //   setNonStop("NON-STOP")
  //   if (jetType == "LIGHT_JET" && parseInt(flightTimeArr[0]) >= 4) {
  //     setNonStop("STOP(s)");
  //   }
  //   else if (jetType == "TURBO_PROP" && parseInt(flightTimeArr[0]) >= 3) {
  //     setNonStop("STOP(s)");
  //   }
  //   else if (fuelStops === 0) {
  //     setNonStop("NON-STOP");
  //   }
  //   else {
  //      setNonStop(`${fuelStops} STOPS`);
  //   }
  // }, [jetType]);

  let deptTime = moment(departureDateTime, "DD-MM-YYYY HH:mm:ss").format(
    "h:mm A"
  );
  let arrTime = moment(arrivalDateTime, "DD-MM-YYYY HH:mm:ss").format("h:mm A");

  return (
    <div className="TripCard">
      <div className="title-container">
        <div className="date-container">
          <p>{parsed.format("ddd")}</p>
          <p>{parsed.format("MMM, DD, YYYY")}</p>
        </div>
        <div className="step-container">
          <p>
            {legOrder + 1} / {total}
          </p>
        </div>
      </div>
      <div className="airport-container">
        <div className="icon-container">
          <BiCircle />
          <div className="v-line" />
          <RiMapPinFill />
        </div>
        <div className="places-container">
          <div className="airport-detail">
            <p className="a-name">{departureAirport.icaoCode}</p>
            <FaCircle className={"dot"} size={10} />
            <p className="city-name">{departureAirport.displayName}</p>
            <p className="time">{deptTime}</p>
          </div>
          {departureAirportRestriction != null && (
            <div className="restriction-container">
              <div
                onClick={() => setInformationModal(true)}
                className="restriction"
              >
                <BsFillExclamationTriangleFill color={"#4782E7"} size={15} />
                <p>Possible stop</p>
              </div>
              <p
                className="info-button"
                onClick={() => setInformationModal(true)}
              >
                Info
              </p>
            </div>
          )}
          <div className="meta-container">
            {/* <Button label={nonStop} size={"extra-small"} */}
            <Button
              label={`${
                paddedFuelStops === 0 ? "NON-STOP" : `${paddedFuelStops} STOP`
              }`}
              size={"extra-small"}
              fullWidth={false}
              type={"black"}
            />
            <div className="h-line" />
            <Button
              label={`${flightTime.split(":")[0]}H ${
                flightTime.split(":")[1]
              }M`}
              size={"extra-small"}
              fullWidth={false}
              type={"outline-grey"}
            />
          </div>
          <div className="airport-detail">
            <p className="a-name">{arrivalAirport.icaoCode}</p>
            <FaCircle className={"dot"} size={10} />
            <p className="city-name">{arrivalAirport.displayName}</p>
            <p className="time">{arrTime}</p>
          </div>
          {arrivalAirportRestriction !== null && (
            <div className="restriction-container">
              <div
                onClick={() => setInformationModal(true)}
                className="restriction"
              >
                <BsFillExclamationTriangleFill color={"#4782E7"} size={15} />
                <p>Possible stop</p>
              </div>
              <p
                className="info-button"
                onClick={() => setInformationModal(true)}
              >
                Info
              </p>
            </div>
          )}
        </div>
      </div>
      {edit && (
        <div className="actions-container">
          <Button
            label={"EDIT AIRPORT"}
            type={"outline-grey"}
            size={"small"}
            onClick={() =>
              history.push("/edit-airport", {
                data: selectedAircraft?.itinerary?.legs ?? [],
                legOrder,
              })
            }
            fullWidth={false}
          />
          <Button
            label={"EDIT DATE"}
            type={"outline-grey"}
            size={"small"}
            onClick={() => history.push("/", { prefill: true })}
            fullWidth={false}
          />
        </div>
      )}
      <InformationModal
        status={informationModal}
        onChange={setInformationModal}
      />
    </div>
  );
}

function mapStateToProps({ aircraft }) {
  return {
    selectedAircraft: aircraft.selectedAircraft,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAddOns: (data) => dispatch({ type: UPDATE_ADDONS, payload: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TripCard);
