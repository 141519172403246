import React, {useState} from 'react';
import PropTypes from 'prop-types';

import "./ApplicationTab.scss";

function ApplicationTab({tabs, onTabChange, activeTab = 1}) {
  const [active, setActive] = useState(activeTab);
  return (
    <div className="ApplicationTab">
      <div className="tab-container">
      {/* <ul class="tabs group"> */}
        {tabs.map(({label, value}) => (
          <div key={value} className={`tab ${value === active && 'selected-tab'}`}
               onClick={() => {
                 setActive(value);
                 onTabChange(value)
               }}>
            {label}
          </div>
          // <li key={value} class="active"><a href="#one">{label}</a></li> 
        ))}
        {/* </ul> */}
      </div>
    </div>
  );
}

ApplicationTab.propTypes = {
  activeTab: PropTypes.any,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(Object).isRequired,
}

export default ApplicationTab;