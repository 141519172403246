import React from 'react';
import "./Testimonials.scss"
import Forbes from '../../assets/Forbes.png'
import Takeoff from '../../assets/icons/Takeoff.png';
import Hand from '../../assets/icons/Hand.png';
import Check1 from '../../assets/icons/Check1.png';
import Check2 from '../../assets/icons/Check2.png';
import Tag from '../../assets/icons/Tag.png';
import Ribbon from '../../assets/icons/Ribbon.png';
import DownloadRef from '../../components/DownloadRef';
import ArrowDown from '../../assets/icons/arrow-down.png';

const Testimonials = () => {
    let statements=[
        {icon: Takeoff, statement: '“Simplest private air app I have ever used, its literally like ordering an Uber!”', person:'Peter S, CEO,', place: 'Chicago, IL'},
        {icon: Hand, statement: '“I was shocked to confirm all prices are 100% guaranteed, no bait and switch”', person:'Tony K.', place: 'Palm Springs, CA'},
        {icon: Check1, statement: '“The AB1 loyalty program is great, and makes the pricing even better than stated”', person:'James S, CEO', place: 'Palm Beach, FLA'},
        {icon: Check2, statement: '“I read the Forbes article and can confirm these are some of the best prices I have seen in private air, and they are guaranteed.”', person:'Daniel H, CEO,', place: 'Sarasota, FLA'},
        {icon: Tag, statement: '“I have never flown privately before, and AIRBOOK ONE’s Flight Concierge Team were informative, upfront and we loved their attention to safety.”', person:'JM', place: 'New York, NY'},
        {icon: Ribbon, statement: '“I have been flying privately for years, the fact that I can now fly without ANY member fees or upfront costs, while still having access to a high-end Flight Concierge Team is phenomenal.”', person:'Howard S', place: 'Atlanta, GA'}
    ]
    return(
        <div className="Testimonials">
                <div className='head-section-1'>
                    <h1 className='head testimonial-heading'>
                    “AirBook One promises guaranteed charter<br/>pricing you can book in seconds.”
                    </h1>
                    <img className="Forbes" width="282" height="110" src={Forbes} alt="Forbes"/>
                    <img className='testimonial-scroll-icon pointer-cursor'
                        src={ArrowDown}
                        onClick={() => {
                            window.document.querySelector(".Statements").scrollIntoView({behavior: "smooth"})
                        }}
                        alt='scroll down'/>
                </div>
                <div className="Statements">
                    {statements.map((statement,i) =>{
                        return(
                            <div key={i} className="Statement">
                                <img className="icon" src={statement.icon} alt=""/>
                                <h4 className="text">{statement.statement}</h4>
                                <p className="person">{statement.person}</p>
                                <p className="place">{statement.place}</p>
                            </div>
                        )
                    })}
                </div>
                <DownloadRef/>
            {/* </div> */}
        </div>
    )
}

export default Testimonials;