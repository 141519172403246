import {v4 as uuidv4} from 'uuid';

const SESSION_ID = "air-book-session-id";

export const setSessionID = () => {
  let existing = getSessionID();
  if (!existing) {
    window.sessionStorage.setItem(SESSION_ID, uuidv4());
  }
}

export const getSessionID = () => window.sessionStorage.getItem(SESSION_ID);

