export const updateAddOnsOnMaster = (data = {}, addOns = []) => {
  let updated = data.addonResponse.map(a => {
    return {
      ...a,
      addonList: a.addonList.map(b => {
        if (addOns.includes(b.addOnId)) {
          return {
            ...b,
            selected: true,
          }
        } else {
          return {...b, selected: false}
        }
      })
    }
  })
  return {
    ...data,
    addonResponse: updated,
  }
}