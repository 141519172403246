import React from 'react';

function MyTripsHeader({}) {
  return (
    <div className={"MyTripsHeader"}>
      <h1>My Trips</h1>
    </div>
  );
}

export default MyTripsHeader;