import React from 'react';
import "./FAQPage.scss";

const FAQPage = ({}) => {
  return (
    <div className='general-section FAQPage'>
      <h1>AIRBOOK ONE Frequently Asked Questions</h1>
      <h2>How does AIRBOOK ONE work?</h2>
      <p>AIRBOOK ONE gives 'Power to the Passenger' by allowing consumers to simply enter their travel information and
        search aircraft options directly, all from the convenience of their mobile phone, tablets or desktops. All price
        quotes provided are accurate and can be locked in immediately, upon booking. In addition, AIRBOOK ONE offers all
        users access to a 24/7 complimentary Flight Concierge service via email, chat, or phone to ensure a seamless
        experience.</p>
      <h2>How is AIRBOOK ONE different from its competitors?</h2>
      <p>AIRBOOK ONE delivers easy accessibility through an automated booking process with no upfront costs, such as
        member fees or pre-paid flight hours.</p>
      <p>AIRBOOK ONE also offers a rewards program of 1% percent back on all flight costs that can be used towards the
        purchase of future travel.</p>
      <h2>What happens after I book my flight?</h2>
      <p>Following flight booking, your itinerary will be delivered via email and available on both the AIRBOOK ONE App
        and website. The itinerary will confirm your reservation along with the following information: departure dates
        and times, aircraft type, and other requests specified by you when booking.</p>
      <h2>What documentation will I need for my flight?</h2>
      <p>In the U.S. all passengers will need to provide current and valid government-issued IDs to the crew before
        boarding so they can be matched to the manifest. In airports outside the U.S., there may be additional
        documentation and screening required.</p>
      <h2>How is pricing determined for a given trip?</h2>
      <p>Real-time market pricing is based on multiple factors, including aircraft size, category, length of the trip
        and availability. </p>
      <h2>How are the aircraft and crews vetted safe?</h2>
      <p>AIRBOOK ONE only sources its' aircraft from both safety rating systems Argus and Wyvern, to ensure the highest
        standard in aviation safety.</p>
      <h2>Does AIRBOOK ONE fly internationally? Where can I go outside of the country?</h2>
      <p>AIRBOOK ONE users can auto-book flights to Canada, Mexico and the Caribbean directly through the app or
        website. Customers looking to travel to other international destinations can do so by contacting the Flight
        Concierge.</p>
      <h2>What type of aircraft will I have access to?</h2>
      <p>AIRBOOK ONE offers access to over 5000 aircraft ranging from turbo-prop, light, mid, super mid and large jets.
        If you have any questions or specific requests, you may contact Flight Concierge at any time.</p>
      <h2>How many passengers can I bring?</h2>
      <p>Passenger limitation depends on the aircraft size and category.</p>
      <h2>How much luggage can I bring?</h2>
      <p>Each aircraft has different baggage compartment sizes and weight limitations. Your baggage will be stored in
        the aft of the aircraft or the nose for your safety and convenience. It is essential to let us know what your
        expected baggage weight and size will be so we can accommodate you and your guests. It is always a best practice
        to travel with soft-sided baggage if you are looking to maximize the number of items you will be bringing. If
        you have concerns about your fragile items, oversized luggage or golf clubs, contact AIRBOOK ONE's Flight
        Concierge to discuss. </p>
      <h2>Can I bring my kids along?</h2>
      <p>Children of all ages are welcome, but AIRBOOK ONE recommends reviewing The Federal Aviation Administration
        (FAA) requirements based on age.</p>
      <h2>Can I bring my pet(s) along?</h2>
      <p>Ease of travel with pets is one of the benefits of flying privately. While not all aircraft accept pets,
        AIRBOOK ONE's Flight Concierge works personally with all passengers to ensure we match them and their pets with
        the right plane. Please contact us with all of your private jet pet travel questions. </p>

      <h2>Oxygen requirements for people that need it?</h2>
      <p>If oxygen is required by a passenger, Flight Concierge MUST be notified ahead of time in order to coordinate
        with the operator and ensure the equipment is permitted and accommodated appropriately.</p>

      <h2>How far in advance do I need to book?</h2>
      <p>AIRBOOK ONE makes it easy for travelers to book online or via the app with as few as 24 hours' notice. Need
        something sooner? Contact our AIRBOOK ONE Flight Concierge to accommodate any trip with less than 24 hours'
        notice. </p>

      <h2>How do I pay for my flight?</h2>
      <p>AIRBOOK ONE makes paying for private flights more accessible than ever with the optionto pay via wire transfer,
        Apple Pay, credit card or AIRBOOK ONE’s Limitless Wallet, directly through ether the Mobile APP or Website.</p>

      <h2>Can I cancel my flight? What is the cost?</h2>
      <p>Yes, most flights can be cancelled, with the appropriate amount of notice. Please see AIRBOOK ONE's
        cancellation policy at www.airbookone.com/cancellationpolicy.</p>

      <h2>What happens if I am running late for a flight?</h2>
      <p>We understand that life happens. For any delays, contact the Flight Concierge as soon asyou know. We will work
        with both you and the Operator for a solution.</p>

      <h2>What is de-icing and how is it handled?</h2>
      <p>Inclement weather, such as temperatures below freezing, and snow/sleet storms could potentially affect your
        flight and the total cost of your trip if de-icing is deemed necessary at time of departure.</p>
      <p>De-icing solution is only effective for a pre-calculated amount of time.Any delays after the solution is
        applied may result in the need for additional applications.The process cantake approximately 10-30 minutes each
        time.</p>
      <p>De-icing is billed at cost as an additional charge to your flight after the trip has been completed. Pricing
        varies based on city/state, size of aircraft, weather conditions andtype/amount of de-icing fluid required.</p>

      <h2>Do you offer any incentive programs?</h2>
      <p>AIRBOOK ONE’s Loyalty program offers 1 percent back on all flights that can be used toward the purchase of
        future travel.</p>

      <h2>AIRBOOK ONE is the first completely carbon-neutral way to fly privately. What does that mean?</h2>
      <p>AIRBOOK ONE works with the company 4AIR.AERO. AIRBOOK ONE ensures every flight booked with us is carbon Neutral
        and will meet 4Air bronze level every time you fly.</p>
      <p>4AIR supports projects all over the world that fund greenhouse gas reductions including renewable energy,
        forestry and efficiencies. Every project meets the stringent criteria through leading nonprofit standards like
        gold standard, verra, climate action reserve and American carbon registry.</p>

      <h2>Will there be Wi-Fi, High-Speed Internet, Flight Phones and Entertainment on private jets?</h2>
      <p>By request and upon availability, AIRBOOK ONE’s Flight Concierge can arrange your next private flight to
        include any of the below amenities. Additional charges may apply. Please see the "Terms of Service" and "Terms of
        Sale".</p>
      <ul>
        <li>Wi-Fi Internet</li>
        <li>Satellite Flight Phones</li>
        <li>Cabin Entertainment systems</li>
      </ul>

      <h2>Will there be a flight attendant onboard?</h2>
      <p>Some aircraft require a flight attendant on board, based on aircraft size. If your flight does not require an
        attendant, but you prefer to have one, flight attendants may be available upon request.</p>

      <h2>Do the aircraft have bathrooms?</h2>
      <p>Generally speaking, the smaller the aircraft, the fewer amenities can fit on the plane. Depending on the size
        aircraft selected, there may or may not be an enclosed restroom on board. </p>

      <h2>Where do I meet my aircraft at the airport?</h2>
      <p>When passengers arrive to the FBO, they should check in with the reception desk and letthe staff know they are
        looking for their pilots. Unlike airline flight numbers or passenger record locators, the identifier will be the
        aircraft’s tail number (located on the AIRBOOK ONE Itinerary). AIRBOOK ONE will provide the tail number no later
        than 24 hours prior to departure. </p>
    </div>
  );
};

export default FAQPage;
