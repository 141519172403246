import React from 'react';
import ArrowDown from '../../assets/icons/arrow-down.png';
import Check from '../../assets/icons/check.png';
import Crew from '../../assets/icons/crew.png';
import Flag from '../../assets/icons/flag.png';
import IdCard from '../../assets/icons/id-card.png';
import Itinerary from '../../assets/icons/itinerary.png';
import Plus from '../../assets/icons/plus.png';
import Shield from '../../assets/icons/shield.png';
import Suitcase from '../../assets/icons/suitcase.png';
import Wifi from '../../assets/icons/wifi.png';
import Snowflake from '../../assets/icons/snowflake.png';
import FlyPrivateCard from '../../components/FlyPrivateCard';
import LearnMore from '../../components/LearnMore';
// import ViewMore from '../../assets/view-more.jpg';
import './WhyFlyPrivate.scss';
import DownloadRef from '../../components/DownloadRef';
import PageContainer from "../../components/PageContainer";
import {WHY_FLY_PRIVATE_SEO} from '../../constants/seo.constants';

const WhyFlyPrivate = ({history}) => {
  const services = [
    {
      image: Flag,
      title: 'Fixed Base Operator (FBO)',
      reference:
        'An organization granted the right by an airport to operate at the airport and offer a lounge for passengers departing and arriving on private aviation flights at that airport.',
    },
    {
      image: Itinerary,
      title: 'Itinerary Information Required',
      sections:[
        {
          reference:
          'All passengers are required to submit their full name and date of birth for each flight. To avoid any delays, all passenger info must be submitted at least 24 hours in advance. If any passengers require wheelchairs or any other special assistance, advance notice is appreciated to help with boarding and deplaning.'
        },
        {
          reference:
          'If oxygen is required by a passenger, firearms will be on board or a pet will be travelling, our Flight Concierge Team MUST be notified ahead of time in order to coordinate with the operator and ensure all are permitted and accommodated appropriately.',
        }
      ]    
    },
    {
      image: Check,
      title: 'Check-In Procedures',
      reference:
        'When passengers arrive at the FBO, they should check in with the reception desk and let the staff know they are looking for their pilots. Unlike airline flight numbers or passenger record locators, the identifier will be the aircraft’s tail number (located on the AIRBOOK ONE Itinerary). AIRBOOK ONE will provide the tail number no later than 24 hours prior to departure.',
    },
    {
      image: IdCard,
      title: 'Security',
      reference:
        'In the U.S., all passengers will need to provide current and valid government-issued IDs to the crew before boarding so they can be matched to the manifest. In airports outside the U.S., there may be additional documentation and screening required.',
    },
    

  ];
  const servicesCont = [
    {
      image: Crew,
      title: "Flight Crews",
      sections: [
        {
          subTitle: "Pilots",
          reference:
            "Two pilots will be required for all flights and are assigned by the operator. The Captain is the final decision maker for all flights and any decisions by them are final. The Captain is assisted by the First Officer. Both pilots are pre-screened to be qualified for each flight. Pilots are sometimes allowed to give passengers their contact info in case of last-minute delays or questions.",
        },
        {
          subTitle: "Flight Attendants",
          reference:
            "A Flight Attendant is assigned primarily for passenger safety on larger aircraft. All instructions given by the Flight Attendant are mandatory and must be adhered to. Flight Attendants will also provide passengers with cabin service including beverages, snacks and full meals (if added to the leg). In most cases, Passengers can request a Flight Attendant be added if one is not included in booking.",
        },
      ],
    },
    {
      image: Suitcase,
      title: "Luggage",
      html: true,
      reference: `<p>Each aircraft has different baggage capacities. See the aircraft categories on our <a  target="_blank" href="/jet-info">aircraft learning page</a> for average luggage size and weight requirements. Your luggage will be stored in designated areas and likely will not be accessible during flight. In order to make the most of the available space, it is best to travel with soft-sided luggage when possible. For any fragile or over-sized items please contact our Flight Concierge Team so we can work with you on best options. The flight crew and/or FBO staff will handle loading and unloading luggage from the aircraft.
            <br/><br/>Any time you are traveling with golf clubs, skis, snowboards or other large items; please contact our Flight Concierge Team to confirm the aircraft category can accommodate them.
            <br/><br/>All transportation of firearms and ammunition must be reported to our Flight Concierge Team in advance.</p>`,
    },
    {
      image: Snowflake,
      title: "De-Icing",
      sections: [
        {
          reference:
            "As the seasons change and colder weather is encountered, de-icing becomes a necessary consideration.",
        },
        {
          reference:
            "Our select operators are some of the top private aviation companies in the world. They will adhere to FAA rules and regulations to include de-icing of an aircraft for safety. Inclement weather, such as temperatures below freezing and snow/sleet storms could potentially affect your flight and the total cost of your trip if de-icing is deemed necessary at time of departure.",
        },
        {
          reference:
            "De-icing is billed at cost as an additional charge to your flight after the trip has been completed. Pricing varies based on city/state, size of aircraft, weather conditions and type/amount of de-icing fluid required.",
        },
        {
          reference:
            "De-icing solution is only effective for a pre-calculated amount of time. Any delays after the solution is applied may result in the need for additional applications. The process can take approximately 10-30 minutes each time.",
        },
        {
          reference:
            "When adverse winter weather is forecast, we do suggest planning to arrive at the airport at least 30 minutes prior to your scheduled departure so this does not create any additional delays for your trip.",
        },
      ],
    },
    {
      image: Shield,
      title: "Safety Ratings",
      reference:
        "AIRBOOK ONE only books trips with operators rated through ARG/US and/or Wyvern audits.",
      sections: [
        {
          subTitle: "ARG/US",
          reference:
            "ARG/US is a third-party safety rating company that audits private jet operators spanning over 500 operators globally. Each ARG/US rated operator is continually monitored for safety and training. There are three levels of ARG/US ratings available: Gold, Gold Plus, and Platinum.",
        },
        {
          subTitle: "Wyvern",
          reference:
            "Wyvern is a third-party company with a rating system of private aircraft operators measuring adherence to ICAO (International Civil Aviation Organization) standards for safety management systems, emergency response plans, and internal evaluations.",
        },
      ],
    },
    {
      image: Plus,
      title: "Empty Leg Flights",
      html: true,
      reference:
        '<p>Empty legs are created when an aircraft is scheduled to fly from Point A to Point B with no passengers. Operators offer up these “positional legs” at discounted rates. <br/><br/> AIRBOOK ONE includes all "Listed" Empty Legs options in real-time on all aircraft search results.</p>',
    },
    {
      image: Wifi,
      title: "Wi-Fi",
      html: true,
      reference:
        '<p>By request and upon availability, AIRBOOK ONE’s Flight Concierge Team can arrange your next private flight to include amenities such as, Wi-Fi, Satellite Flight Phones and Entertainment Systems . Additional charges may apply. Please see the "<a  target="_blank" href="/terms-of-service">Terms of Service</a> and <a  target="_blank" href="/terms-of-sale">Terms of Sale</a>".</p>',
    },
  ];
  return (
    <PageContainer className='root-section' {...WHY_FLY_PRIVATE_SEO}>
    <div>
      <div className="root-section">
        <div className="hiw-section-1">
          <h1 className="hero">
            Convenience, safety, time management, access smaller airports/cities
            and multi-city stops in one day are just few reasons to fly private.<br/><br/>
            Learn More
          </h1>
          <img
            className="scroll-icon pointer-cursor"
            src={ArrowDown}
            onClick={() =>
              window.document
                .getElementById("fly-with-us-advantages")
                .scrollIntoView({ behavior: "smooth" })
            }
            alt="scroll down"
          />
        </div>
        <div id={"fly-with-us-advantages"}>
          {services.map((item, index) => (
            <FlyPrivateCard key={index} {...item} />
          ))}
        </div>
        <LearnMore
          title="View Aircraft"
          onClick={() => history.push("/jet-info")}
          //  img={ViewMore}
          arrowDir="right"
        />
        <div>
          {servicesCont.map((item, index) => (
            <FlyPrivateCard key={index} {...item} />
          ))}
        </div>
        <div className="spacer" />
        <DownloadRef />
      </div>
    </div>
    </PageContainer>
  );
};

export default WhyFlyPrivate;
