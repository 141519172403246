import React, { useState, useEffect } from "react";
import "./LegsModal.scss";
import Modal from "../Modal";
import Button from "../Button";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import { GET_AUTH } from "../../utils/HTTP.utils";
import { API_GET_TRAVELLER_BOOKING_DETAILS } from "../../middleware/constants/url.constants";
import LoadingOverlay from 'react-loading-overlay';

function LegsModal({ status, legs, onChange, onUpdateTraveller, bookingId}) {
  const [check,setCheck] = useState({'all':false})
  const [loader, setLoader] = useState(false)
  const [state, setState] = useState({
    selectedLegs: [],
    leadPassenger: false
  });
  const updateTraveller = async (payload) => {
    try {
      onUpdateTraveller && onUpdateTraveller({...state,travellerId: status.passengerID});
      setState({
        selectedLegs: [],
        leadPassenger: false,
      });
    } catch (e) {
      console.error(e);
    }
    setState({
      selectedLegs: [],
      leadPassenger: false,
    });
    onChange(false);
  };
  const onSubmit = async (e) => {
    var LegisValid = document.querySelector("#sectionForm").reportValidity();
    e.preventDefault();
    const { selectedLegs, leadPassenger } = state;
    if (LegisValid) {
      await updateTraveller({
        selectedLegs,
        leadPassenger,
      });
    }
  };

  useEffect(() => {
    legs.map((a,i)=>{
      check[i+1]=false
    })
  },[])

  useEffect(() => {
    const form = document.querySelector("#sectionForm");
    const dummy = document.querySelector("#error");
    const checkboxes = form.querySelectorAll(".leg");
    const checkboxLength = checkboxes.length;
    const firstCheckbox = checkboxLength > 0 ? checkboxes[0] : null;

    function init() {
      if (firstCheckbox) {
        for (let i = 0; i < checkboxLength; i++) {
          checkboxes[i].addEventListener("change", checkValidity);
        }

        checkValidity();
      }
    }
    function isChecked() {
      for (let i = 0; i < checkboxLength; i++) {
        if (checkboxes[i].checked) {
          return true;
        }
      }
      return false;
    }

    function checkValidity() {
      const errorMessage = !isChecked()
        ? "At least One Leg Must Be Selected."
        : "";
      dummy.setCustomValidity(errorMessage);
    }

    init();

    
    
  }, [state]);
  
  useEffect(() => {
    if(status.chosen){
    setLoader(true)
    const form = document.querySelector("#sectionForm");
    const leadCheckbox = form.querySelector(".lead-checkbox");
    getIsUaAuthentication().then(({token})=>{
      GET_AUTH(API_GET_TRAVELLER_BOOKING_DETAILS,token,{"bookingId":bookingId,"travellerId":status.passengerID}).then((data)=>{
        setState(data)
        if(data.selectedLegs.length===legs.length){
          legs.map((a,i)=>{
            check[i+1]=true
          })
          setCheck({...check,'all':true})
        }else{
          data.selectedLegs.forEach((leg)=>{
            check[leg]=true
          })
        }
        if(data.leadPassenger){
          leadCheckbox.checked = true;
        }
        setLoader(false)
      })
    })
  }
  },[status])

  const handleChange = (e) => {
    setState({ ...state, leadPassenger: e.target.checked });
  };

  const handleAllLegs = async(e) => {
    legs.map((a,i)=>{
      check[i+1]=e.target.checked
    })
    setCheck({...check,'all':e.target.checked})
    if (e.target.checked) {
      setState({ ...state, selectedLegs: legs.map((a, i) => i + 1) });
    } else {
      setState({ ...state, selectedLegs: [] });
    }
  }

  const handleLegChange = (e,i) => {
    let legsArr = state.selectedLegs;  
      if (e.target.checked) {
        legsArr.splice(i.leg, 0, i.leg + 1);
        (legsArr.length===legs.length)?setCheck({...check,'all':true, [(i.leg+1)]: true })
        :setCheck({...check, [(i.leg+1)]: true })
      } else {
        legsArr.splice(legsArr.indexOf(i.leg + 1), 1);
        setCheck({...check, [(i.leg+1)]: false, 'all':false })
      }
      setState({ ...state, selectedLegs: legsArr });
  }

  return (
    <div className="LegsModal">
      <Modal close={() => onChange(false)} show={status.chosen}>
        <p className="title">Legs On Board</p>
        <form onSubmit={onSubmit} id="sectionForm">
          <div className="input-container">
            <div className="leg-container">
                <input id="error" className="Dummy" />
                <label className="container all-legs">
                  All Legs :
                  <input
                    onChange={(e) => {handleAllLegs(e)}}
                    className="all-checkbox checkbox leg"
                    type="checkbox"
                    checked={check['all']}
                  />
                  <span className="checkmark"></span>
                </label>
                {legs.map((leg, i) => {
                  return (
                    <label key={i} className="container">
                      Leg {i + 1} : {leg.departureAirport?.icaoCode} -{" "}
                      {leg.arrivalAirport?.icaoCode}
                      <input
                        checked={check[i+1]||(check['all'])}
                        className="leg-checkbox leg"
                        onChange={(e) => handleLegChange(e,{ leg: i })} 
                        type="checkbox"
                      />
                      <span className="checkmark"></span>
                    </label>
                  );
                })}
            </div>
          </div>
          <div className="lead-pax-container">
            <p className="title">Lead Passenger</p>
            <p className="lead-subtext">
              Each Trip Must have a designated lead passenger
            </p>
            <label className="container lead-pax">
              Lead Passenger :
              <input
                onChange={(e) => handleChange(e)}
                className="lead-checkbox checkbox"
                type="checkbox"
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <Button
            label={"Save Changes"}
            type={"yellow"}
            btnAction={"submit"}
          />
        </form>
      </Modal>
      <LoadingOverlay active={loader} spinner text='Loading your content...'/>
    </div>
  );
}

export default LegsModal;
