import React, {useEffect, useState} from 'react';
import Button from "../../components/Button";
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import {GET_AUTH} from "../../utils/HTTP.utils";
import {API_GET_WALLET_SUMMERY} from "../../middleware/constants/url.constants";
import moment from "moment";
import "./MyAccount.scss"
import LoadingOverlay from "react-loading-overlay";
import EmptyIcon from "../../assets/icons/empty_icon.png"
import WiringInfoModal from "../../components/WiringInfoModal";

function Wallet({ }) {
  const [loader,setLoader] = useState(true)
  const [state, setState] = useState({
    walletBalance:"0",
    transactions:[]
  })
  const [model, setModel] = useState(false);
  const fetchData = async () => {
    let {token} = await getIsUaAuthentication();
    let data = await GET_AUTH(API_GET_WALLET_SUMMERY, token, {});
    if (data.error) {
      /*
      Description: Use this snippet when you need to navigate user to other page on API error.
       */
      // let history = useHistory();
      // history.push("/");
    }
    setState(data)
    setLoader(false)
  };
  useEffect(() => {
    fetchData()
  }, [])
  if (!loader) {
    return (
      <div className="Wallet">
        <h2>Wallet</h2>
        <div className="available-balance">
          <div className="info">
            <p className="title">Available Funds</p>
            <p className="balance">${state.walletBalance?.toLocaleString()}</p>
            <p className="sub-title">
              You can add funds by logging into your AB1 account on a web
              browser.
            </p>
          </div>
          <Button
            label={"Add Funds"}
            onClick={() => setModel(true)}
            fullWidth={false}
            type={"black"}
          />
        </div>
        {state?.transactions?.map((a, i) => (
          <div key={i} className="key-value">
            <div className="info">
              <p className="key">
                {moment(a.lastUpdatedDate).format("MMM DD YYYY")}
              </p>
              <p className="value">{a.display}</p>
            </div>
            <div
              className={`funds-tans ${a.status === "ADDED" ? "add" : "sub"}`}
            >
              {a.status === "ADDED" ? "+" : "-"} $
              {Math.abs(a.amount)?.toLocaleString()}
            </div>
          </div>
        ))}
        {state.transactions.length === 0 && (
          <div className="empty-container">
            <div className="Empty">
              <img src={EmptyIcon} />
              <h3>No rewards to display</h3>
            </div>
          </div>
        )}
        <WiringInfoModal status={model} onChange={setModel} />
      </div>
    );
  }
  else {
    return (
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
    );
  }
}

Wallet.propTypes = {};

export default Wallet;