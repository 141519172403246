import React, {useState} from 'react';
import {Auth} from 'aws-amplify';
import {toast} from 'react-toastify';
import './Signup.scss';
import Button from '../../components/Button';
import InputBox from '../../components/InputBox';
import Modal from '../../components/Modal';
import LoadingOverlay from 'react-loading-overlay';

const Signup = ({history}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telCode, setTelCode] = useState('+1');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const signUp = (e) => {
    e.preventDefault();

    if (
      firstName !== '' ||
      lastName !== '' ||
      telCode !== '' ||
      email !== '' ||
      password !== '' ||
      phone !== ''
    ) {
      setLoader(true);
      Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          phone_number: `${telCode}${phone}`,
        },
      })
        .then((response) => {
          console.log('Signup response ', response);
          setModal(true);
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          toast.error(err.message);
        });
    } else {
      toast.error('Please fill in all the details.');
    }
  };

  const confirmSignUpCode = (e) => {
    e.preventDefault();
    if (code !== '') {
      setLoader(true);
      Auth.confirmSignUp(email, code)
        .then((response) => {
          console.log('confirm code', response);
          setModal(false);
          setLoader(false);
          history.push('/login',{success:true});
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          toast.error(err.message);
        });
    } else {
      toast.error('Please type the code.');
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <div className='signup-root'>
      <LoadingOverlay active={loader} spinner text='Loading...'>
        <h1 className='title'>Sign Up</h1>
        <form className='signup-form'>
          <InputBox
            type='text'
            title='Please enter first name'
            placeholder='First Name'
            className='input-grp'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <InputBox
            type='text'
            title='Please enter last name'
            placeholder='Last Name'
            className='input-grp'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          {/* <select
            id='telCode'
            className='input-grp'
            name='country-code'
            value={telCode}
            placeholder='Country Code'
            onChange={(e) => setTelCode(e.target.value)}
          >
            <option disabled selected value=''>
              Country code
            </option>
            {Object.keys(codes).map((country, index) => {
              return (
                <option key={index} value={codes[country]}>
                  {country}
                </option>
              );
            })}
          </select> */}
          <div className='cust-no'>
            <input
              type='string'
              title='Please enter country code'
              className='input-grp tel-number'
              value={telCode}
              onChange={(e) => setTelCode(e.target.value)}
            />
            <input
              type='number'
              title='Please enter phone number'
              placeholder='Phone Number'
              className='input-grp ph-num'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <InputBox
            type='email'
            title='Please enter email'
            placeholder='Email'
            className='input-grp'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputBox
            type='password'
            title='Please enter password'
            placeholder='Password'
            className='input-grp'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            btnAction={'submit'}
            label={'Sign Up'}
            type={'yellow'}
            size={'small'}
            onClick={(e) => signUp(e)}
          />
          <Button
            label={'Already have an account? Login'}
            type={'white-link'}
            onClick={() => history.push('/login')}
            size={'small'}
          />
        </form>
          <Modal show={modal} close={handleClose}>
            <div>
              <p>A confirmation code has been sent to your email {email}</p>
              <form>
                <InputBox
                  theme={"light"}
                  type='text'
                  title='Please type the Code'
                  placeholder='Verification Code'
                  className='input-grp'
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button
                  btnAction={'submit'}
                  label={'Confirm'}
                  type={'yellow'}
                  size={'small'}
                  onClick={(e) => confirmSignUpCode(e)}
                />
              </form>
            </div>
          </Modal>
      </LoadingOverlay>
    </div>
  );
};

export default Signup;
