import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import useAuthentication from "../../hooks/useAuthentication";

function UnAuthRoute({Component, ...rest}) {
  let {status} = useAuthentication();
  return <Route {...rest} render={props => status ? <Redirect to="/my-account"/> : <Component {...props} />}/>
}

export default UnAuthRoute;
