import React from 'react';
import "./CompletePayment.scss";
import PaymentOptions from "./PaymentOptions";
import PayNow from "./PayNow";
import ReserveNow from "./ReserveNow";
import {connect} from "react-redux";
import {PAYMENT_TYPE_PAY_BY_WIRE, PAYMENT_TYPE_PAY_NOW, PAYMENT_TYPE_DESELECT} from "../../middleware/constants/payment.constants";

function CompletePayment({ selected, status }) {
  return (
    <div className="CompletePayment">
      {selected === PAYMENT_TYPE_DESELECT && <PaymentOptions clickStatus={'false'}/>} 
      {selected !== PAYMENT_TYPE_DESELECT && <PaymentOptions clickStatus={'true'}/>} 
      {selected === PAYMENT_TYPE_PAY_NOW && <PayNow clickStatus={status}/>}
      {selected === PAYMENT_TYPE_DESELECT && <PayNow clickStatus={status}/>}
      {selected === PAYMENT_TYPE_PAY_BY_WIRE && <ReserveNow clickStatus={status} />}
    </div>
  );
}


function mapStateToProps({payment}) {
  return {
    selected: payment.paymentOption,
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletePayment)
