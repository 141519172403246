import React, { useEffect, useState } from "react";
import "./TripSummary.scss";
import Button from "../Button";
import ConciergeModal from "../ConciergeModal";
import Headset from "./../../../assets/icons/talkToConcierge/headset.png";
import tick from "./../../../assets/custom-theme/tick.svg";
import trip_summary_down_arrow from "./../../../assets/trip_summary_down_arrow.png";
import trip_summary_up_arrow from "./../../../assets/trip_summary_up_arrow.png";
import { API_PATH } from "../utility";
import { themecolors } from "../themevariable";
import { getEleAttribute, formatCurrency } from "../utility";
import { getSessionID } from "../../../utils/session.storage.utils";
import { toast } from "react-toastify";
import { IoIosArrowUp } from "react-icons/io";

function TripSummary(props) {
  const { isDownArrow, setIsDownArrow, conciergeModal, setConciergeModal } =
    props;
  // Toggle between the arrows when clicked
  const toggleArrow = () => {
    setIsDownArrow((prevState) => !prevState);
    if (conciergeModal) {
      setConciergeModal();
    }
  };
  let flightData = props.flightData;

  let priceBreakups = flightData?.hasOwnProperty("priceBreakup")
    ? flightData.priceBreakup
    : {};

  const reserveFlight = (params) => {
    let mailId = localStorage.getItem("searchCriteria");
    if (mailId) {
      mailId = JSON.parse(mailId);
    }
    let data = {
      aboneSearchId: params.aboneSearchId || "",
      operatorId: params.operatorId || "",
      bookingId: "",
      sessionId: getSessionID(),
      useRewards: false,
      addonIds: "",
      email: mailId.email,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(API_PATH + "white_label/update-addons-wl", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.bookingId !== null) {
          props.setTripSummary();
          //quoteSaved = true;
          // setQuoteSaved(true);
          // props.showQuoteSuccess()
          // setLoader(false)
        } else {
          toast.error("Sorry.This aircraft has already been booked!");
          //props.setTripSummary();
          //setQuoteError(true);
          //setQuoteErrorMessage(result.error);
          //setLoader(false)
        }
      })
      .catch((error) => console.log("error", error));

    //props.closeQuoteModal()
  };

  return (
    <div
      className={
        isDownArrow
          ? "TripSummary trip-summary-box-shadow-hidden"
          : "TripSummary"
      }
    >
      <div className="summery">
        <div className={props.disabled ? "header-disabled header" : "header"}>
          {/* <h6 className="top-title">{flightData.aircraftType}</h6> */}
          <div className="total-container">
            <h4 className="subtitile-h4 trip-estimate-title">Trip Estimate</h4>
            <h4 className="value subtitile-h4">
              $
              {priceBreakups.hasOwnProperty("totalPrice")
                ? formatCurrency(priceBreakups.totalPrice)
                : "0.00"}
            </h4>
            <div onClick={toggleArrow}>
              {isDownArrow ? (
                <img
                  src={trip_summary_down_arrow}
                  alt="down"
                  className="mobile-only-down-arrow"
                />
              ) : (
                <img
                  src={trip_summary_up_arrow}
                  alt="up"
                  className="mobile-only-down-arrow"
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={`trip-body ${
            props.quoteSaved ? "body-center trip-summary-body-padding" : ""
          } ${isDownArrow ? "body-hidden" : "body-visible"}`} // Dynamically control visibility
        >
          {!props.quoteSaved && (
            <>
              <div className="detail-container TripPriceContainer">
                <div className="trip-price-sub-div">
                  <p className="title">Your Trip</p>
                  <p className="value">
                    {" "}
                    $
                    {priceBreakups.hasOwnProperty("aircraftPrice")
                      ? formatCurrency(priceBreakups.aircraftPrice)
                      : "0.00"}
                  </p>
                </div>
                <div className="trip-price-sub-div">
                  <p className="title">Miscellaneous</p>
                  <p className="value">$0.00</p>
                  {/* <p className="value">${flightData.miscellaneous ? flightData.miscellaneous : 0}</p> */}
                </div>
                <div className="trip-price-sub-div">
                  <p className="title">Taxes & Fees</p>
                  <p className="value">
                    $
                    {priceBreakups.hasOwnProperty("taxes")
                      ? formatCurrency(priceBreakups.taxes)
                      : "0.00"}
                  </p>
                </div>
                <div className="request-trip-btn-padding">
                  <Button
                    type="purple"
                    style={{
                      paddingBottom: "20px",
                      marginTop: "16px",
                    }}
                    label={"Request Trip"}
                    bgcolor={
                      !priceBreakups.hasOwnProperty("totalPrice")
                        ? "gray"
                        : getEleAttribute("btnBackgroundColor", props.theme)
                    }
                    color={getEleAttribute("customFontColor", props.theme)}
                    disabled={!priceBreakups.hasOwnProperty("totalPrice")}
                    // onClick={() => props.setQuoteModalStatus(flightData)}
                    onClick={() => reserveFlight(flightData)}
                    id="req_quote_btn"
                  />
                </div>
              </div>

              <p className="help-title-font-style">Possible Empty Leg Match</p>
              <p className="help-title-sub-font-style">
                Please Request Trip and will reach out to confirm shortly
              </p>
            </>
          )}
          {props.quoteSaved && (
            <>
              <div className="quote-succ">
                <div
                  className="quote-succ-tick"
                  style={{
                    backgroundColor: getEleAttribute(
                      "btnBackgroundColor",
                      props.theme
                    ),
                  }}
                >
                  <img src={tick} className="qoute-succ-img" />
                </div>
                <h3 className="quote-succ-msg">Your Requested Trip</h3>
              </div>

              <hr
                className="ruler-mid ruler-mid-width"
                style={{ marginLeft: "0%" }}
              />

              <p className="help-title" style={{ marginTop: "0px" }}>
                We will reach out you within 24 <br /> hours to confirm your
                requested trip.
              </p>
            </>
          )}
          <div className="request-trip-btn-padding">
            <Button
              label={`Talk  to  Concierge`}
              onClick={() => {
                setConciergeModal(true);
              }}
              type={"golden"}
              icon={
                <img src={Headset} className="talk-to-concierge-button-style" />
              }
              iconPosition="left"
              bgcolor={getEleAttribute(
                "conciergeButtonBackgroundColor",
                props.theme
              )}
              color={getEleAttribute("customFontColor", props.theme)}
            />
          </div>
          {conciergeModal && (
            <ConciergeModal
              status={conciergeModal}
              // existing={state}
              // onNewTraveller={onNewTravellerSelect}
              onChange={setConciergeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}
<div className="detail-container"></div>;

export default TripSummary;
