import {all, fork} from 'redux-saga/effects'
import {loginUserWatch} from "./user.sagas";
import {getJetIfoWatch, searchAircraftWatch, updateAddonsUnAuthWatch} from "./aircraft.saga";
import {initPaymentWatch} from "./payment.saga";

export default function* rootSaga() {
  yield all([
    fork(loginUserWatch),
    fork(searchAircraftWatch),
    fork(getJetIfoWatch),
    fork(updateAddonsUnAuthWatch),
    fork(initPaymentWatch),
  ])
};
