import React from 'react';
import CheckBox from "../CheckBox/CheckBox";
import { connect } from "react-redux";
import {
  PAYMENT_TYPE_PAY_BY_WIRE,
  PAYMENT_TYPE_PAY_NOW,
  PAYMENT_TYPE_DESELECT,
  SET_PAYMENT_TYPE
} from "../../middleware/constants/payment.constants";


function PaymentOptions({ selected, changePaymentOption, clickStatus }) {
  const onPaymentTypeChange = value => () => {
    if (selected !== value) changePaymentOption(value);
    if (selected === value) changePaymentOption(PAYMENT_TYPE_DESELECT);

  }

  // selected pay -------
  selected = PAYMENT_TYPE_PAY_NOW;
 
  return (
   
    ''
  );
}


function mapStateToProps({ aircraft, payment }) {
  return {
    selected: payment.paymentOption,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changePaymentOption: data => dispatch({ type: SET_PAYMENT_TYPE, payload: data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptions)
