import React from 'react';
import CheckBox from "../CheckBox/CheckBox";
import AppleIconMark from "../../assets/icons/Apple_Pay_Mark.svg"
import ApplePayMark from "../../assets/icons/ApplePayMark.svg";
import CreditCardMark from "../../assets/icons/CreditCardMark.svg"
import Reserve24Hrs from "../../assets/icons/Reserve24Hrs.svg"
import { connect } from "react-redux";
import {
  PAYMENT_TYPE_PAY_NOW_CREDIT_CARD,
  PAYMENT_TYPE_PAY_NOW_WALLET_PAY,
  PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD,
  PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY,
  SET_SUB_PAYMENT_TYPE
} from "../../middleware/constants/payment.constants";
import useApplePay from "../../hooks/useApplePay";

function PayNow({
  balance,
  subPaymentOption,
  setSubPaymentOption,
  clickStatus
}) {
  let isApplePayAllowed = useApplePay();
  return (
    <>
      <div className={`peak-div${clickStatus === 'false' ? "-validation" : ""
        }`}>
        <div className={`PayNow${clickStatus === 'false' ? "-validation PayNow" : ""
          }`}>
          <div className="header">
            <p className="title">Pay now for this flight or reserve for 24 hours</p>
          </div>
          <div className="options-select">
            <img
              src={Reserve24Hrs}
              alt="Reserve24Hrs"
              className="option-icon"
            />
            <div className="label">
              <p className="title">Reserve for 24 hrs</p>
              <p className="sub-title">Complete Payment by either ACH or wire transfer</p>
            </div>
            <CheckBox
              uncheck={subPaymentOption === PAYMENT_TYPE_PAY_NOW_WALLET_PAY || subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD || subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY}
              checked={subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD}
              onClick={(status) => {
                if (status){
                  setSubPaymentOption(PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD);
                 }
                else setSubPaymentOption("");
              }}
            />
          </div>
          
          {balance?.walletPayApplicable && (
            <div className="options-select">
              <img
                src={AppleIconMark}
                alt="AppleIconMark"
                className="option-icon"
              />
              <div className="label">
                <p className="title">AB1 Wallet</p>
                <p className="sub-title">
                  Balance ${balance?.walletBalance?.toLocaleString()}
                </p>
              </div>
              <CheckBox
                checked={subPaymentOption === PAYMENT_TYPE_PAY_NOW_WALLET_PAY}
                onClick={(status) => {
                  if (status) setSubPaymentOption(PAYMENT_TYPE_PAY_NOW_WALLET_PAY)
                  else setSubPaymentOption("");
                }}
                uncheck={subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD || subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD || subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY}
              />
            </div>
          )}
          {isApplePayAllowed && (
            <div className="options-select">
              <img src={ApplePayMark} alt="AppleIconMark" className="option-icon" />
              <div className="label">
                <p className="title">Apple Pay</p>
              </div>
              <CheckBox
                uncheck={subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD || subPaymentOption === PAYMENT_TYPE_PAY_NOW_WALLET_PAY || subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD }
                checked={subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY}
                onClick={(status) => {
                  if (status)
                    setSubPaymentOption(PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY);
                  else setSubPaymentOption("");
                }}
              />
            </div>
          )}
          <div className="options-select">
            <img
              src={CreditCardMark}
              alt="CreditCardMark"
              className="option-icon"
            />
            <div className="label">
              <p className="title">Credit Card</p>
              <p className="sub-title">3% fees will be added</p>
            </div>
            <CheckBox
              checked={subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD}
              onClick={(status) => {
                if (status) setSubPaymentOption(PAYMENT_TYPE_PAY_NOW_CREDIT_CARD);
                else setSubPaymentOption("");
              }}
              uncheck={subPaymentOption === PAYMENT_TYPE_PAY_NOW_WALLET_PAY || subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD || subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY}
            />
          </div>
        </div>
      </div>   
        {clickStatus === 'false' && (
        <span className="reserve-validation">
          <p className="reserve-validation-icon">*</p>
          <p className="reserve-validation-msg">Selection is required to confirm booking.</p>
        </span>
      )}
    </>
  );
}

PayNow.propTypes = {};

function mapStateToProps({ aircraft, payment }) {
  return {
    balance: aircraft.balance,
    subPaymentOption: payment.subPaymentOption,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSubPaymentOption: option => dispatch({ type: SET_SUB_PAYMENT_TYPE, payload: option })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayNow)
